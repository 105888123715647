import { Typography, Box, Button, Stack, Modal, Divider, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import EmailEditor from 'react-email-editor';
import { toPng } from 'html-to-image';
import html2canvas from 'html2canvas';
import CustomButton from '../../components/CustomButton';
import sample from './sample/sample.json';
import '../crm/component/campaing/style/customStyle.css'; // Fichier de styles personnalisés
import { db } from '../../firebase.config';

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'white',
    border: '1px solid white',
    borderRadius: '7px',
    boxShadow: 0,
};


const SiteBuilder = () => {
    const emailEditorRef = useRef(null);

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml(data => {
            const { design, html } = data;
            // console.log('exported html:', html);
            // Sauvegarder dans Firestore la version HTML et la structure design
        });
        console.log('salut')
        console.log(emailEditorRef.current?.editor)
    };

    const sendTestEmail = async () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { html } = data;
            // sendEmail('test@example.com', html, 'Test de campagne');
        });
    };

    const sendPersonalizedEmail = (contact, htmlTemplate) => {
        const personalizedHtml = htmlTemplate
            .replace('{{nom}}', contact.nom)
            .replace('{{eventName}}', contact.eventName);

        // sendEmail(contact.email, personalizedHtml);
    };

    const saveCampaign = (design, html) => {
        const campaign = {
            design,
            html,
            createdAt: new Date(),
            status: 'draft',
        };
        // Sauvegarde dans Firestore
        // firestore.collection('campagnes').add(campaign);
    };

    const loadSavedCampaign = async (campaignId) => {
        // const campaign = await firestore.collection('campagnes').doc(campaignId).get();
        // emailEditorRef.current.editor.loadDesign(campaign.data().design);
    };

    // chargement d'un template dans l'editeur
    const loadTemplateFromDatabase = async (templateId) => {
        // const template = await fetchTemplateFromDatabase(templateId);
        // emailEditorRef.current.editor.loadDesign(template.design);
    };

    const handleImageUpload = async (file) => {
        // const storage = getStorage();
        // const storageRef = ref(storage, `uploads/${file.name}`);
        // await uploadBytes(storageRef, file);
        // const url = await getDownloadURL(storageRef);

        // Ajouter l'image dans l'éditeur d'email
        // emailEditorRef.current.editor.addImage(url);
    };

    const removeUndefinedFields = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    };

    const saveTemplateToFirebase = async () => {
        emailEditorRef.current.editor.exportHtml(async (data) => {
            const { design, html } = data;

            const ddesign = removeUndefinedFields(design);

            // Assurez-vous d'avoir le bon conteneur ici
            const editorContainer = document.getElementById('prewiewcontainer1');

            try {
                const previewImage = await toPng(editorContainer); // Capture du conteneur contenant l'éditeur
                console.log(previewImage);
                const storage = getStorage();
                const storageRef = ref(storage, `templatePreviews/${new Date().getTime()}.png`);
                const snapshot = await uploadString(storageRef, previewImage, 'data_url');

                // Obtenir l'URL de téléchargement de l'image
                const imageUrl = await getDownloadURL(snapshot.ref);

                if (ddesign && html) {
                    try {
                        // Sauvegarde dans Firestore
                        const docRef = await addDoc(collection(db, 'publicTemplates'), {
                            ddesign,
                            html,
                            templateType,
                            imageUrl,
                            title: nom,
                            createdAt: new Date(),
                        });
                        console.log('Template enregistré avec succès, ID:', docRef.id);
                        handleCloseRecetteModal()
                    } catch (e) {
                        console.error('Erreur lors de la sauvegarde du template:', e);
                    }
                } else {
                    console.error('Design ou HTML est undefined, vérifiez votre template.');
                }
            } catch (error) {
                console.error('Erreur lors de la capture du preview image:', error);
            }
        });
        
    };



    const onLoad = () => {
        /* const editor = emailEditorRef.current.editor;
        
        // Charger les paramètres personnalisés dans l'éditeur
        editor.loadDesign({
          body: {
            rows: [],
          },
        });
    
        // Ajouter le slider pour arrondir les bords des images
        const imageSettings = editor.getToolboxConfig('image');
    
        if (imageSettings) {
          imageSettings.properties.push({
            label: 'Arrondir les bords',
            name: 'borderRadius',
            type: 'slider',
            default: 0,
            min: 0,
            max: 50,
          });
    
          editor.setToolboxConfig('image', imageSettings);
        }
      */

        emailEditorRef.current.editor.loadDesign(sample);
        console.log('Email Editor Loaded');

    };

    useEffect(() => {
        if (emailEditorRef.current) {
            const iframe = document.querySelector('iframe');
            console.log(iframe)
            console.log('L\'élément emailEditorRef est maintenant monté dans le DOM.');
        }
    }, [emailEditorRef.current]);


    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const [htmlpreview, setHtmlPreview] = useState('<h1>alfred</h1>')
    const testImageCapture = async () => {
        // await delay(1000);
        emailEditorRef.current.editor.exportHtml(async (data) => {
            const { design, html } = data;
            try {

                // const handlehml = html.replace(/<img[^>]*src=["']https:\/\/cdn\.tools\.unlayer\.com[^>]*>/g, '')
                // Ajouter l'image capturée au document pour vérification
                setHtmlPreview(html)
                // document.body.appendChild(html);

                console.log('Image capturée avec succès !');
            } catch (error) {
                console.error('Erreur lors de la capture de l\'image :', error);
            }
        })
    };

    const handleCaptureClick = async () => {
        console.log('ok')
        if (emailEditorRef.current) {
            console.log('ok1')
            try {
                console.log('ok2')
                // Attendre un délai pour s'assurer que l'élément est bien rendu
                await new Promise((resolve) => setTimeout(resolve, 500));
                console.log('ok3')
                // Vérifier si l'élément est toujours dans le DOM et est un noeud valide

                // Capturer l'image de l'élément
                console.log('ok')
                const canvas = await html2canvas(emailEditorRef.current);
                console.log(canvas)
                const dataUrl = canvas.toDataURL('image/png');
                console.log(dataUrl);


            } catch (error) {
                console.error('Erreur lors de la capture de l\'image :', error);
            }
        } else {
            console.error('emailEditorRef.current est nul ou non défini.');
        }
    };

    const [openRecetteModal, setOpenRecetteModal] = React.useState(false);
    const [nom, setNom] = React.useState();
    const [templateType, setTemplateType] = React.useState(0);
    const handleOpenRecetteModal = async () => {
        emailEditorRef.current.editor.exportHtml(async (data) => {
            const { design, html } = data;
            try {

                // const handlehml = html.replace(/<img[^>]*src=["']https:\/\/cdn\.tools\.unlayer\.com[^>]*>/g, '')
                // Ajouter l'image capturée au document pour vérification
                setHtmlPreview(html)
                // document.body.appendChild(html);

                console.log('Image capturée avec succès !');
                setOpenRecetteModal(true);
            } catch (error) {
                console.error('Erreur lors de la capture de l\'image :', error);
            }
        })

    };
    const handleCloseRecetteModal = () => {
        setHtmlPreview('')
        setOpenRecetteModal(false);
    }
       


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Stack direction='row' justifyContent='space-between' sx={{ flex: '0 0 auto', padding: 2, backgroundColor: '#f5f5f5' }}>
                <Typography variant='body2'><strong>Design</strong></Typography>
                <Stack direction='row' spacing={1}>
                    <CustomButton type='secondaire' buttonText='Envoyer un tester' onClick={testImageCapture} />
                    <CustomButton type='primaire' buttonText='Enregistrer' onClick={handleOpenRecetteModal} />
                    <Modal
                        open={openRecetteModal}
                        onClose={handleCloseRecetteModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ borderRadius: '7px' }}
                    >
                        <Box sx={styleModal}>
                            <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography variant='subtitle1'>
                                    Nouveau template
                                </Typography>
                            </Box>

                            <Divider />
                            <Stack spacing={3} padding={3} >
                                
                                <Stack direction='row' spacing={2}>
                                    <Box onClick={() => setTemplateType(0)} sx={templateType === 0 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                        :
                                        { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                                        <Typography variant='body2'>E-mail Template</Typography>
                                    </Box>
                                    <Box onClick={() => setTemplateType(1)} sx={templateType === 1 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                        :
                                        { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                                        <Typography variant='body2'>Site Template</Typography>
                                    </Box>
                                </Stack>
                                <TextField value={nom} onChange={(e) => setNom(e.target.value)} fullWidth placeholder='Nom du template' />
                            </Stack>

                            <Divider />
                            <Stack direction='row' spacing={2} padding={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                                <CustomButton type='secondaire' buttonText='Annuler' onClick={handleCloseRecetteModal} />
                                <CustomButton type='primaire' buttonText='Enregistrer' onClick={saveTemplateToFirebase} />
                            </Stack>
                        </Box>
                    </Modal>
                </Stack>
            </Stack>

            <div id="email-editor-container" style={{ flex: 1 }}>
                <EmailEditor
                    editorId='editor-container'
                    ref={emailEditorRef}
                    options={{
                        tools: {
                            custom_tool: {
                                properties: {
                                    color: {
                                        label: 'Color',
                                        widget: 'color_picker',
                                        defaultValue: '#FF0000'
                                    }
                                }
                            }
                        },
                        appearance: {
                            theme: 'modern_dark',
                            panels: {
                                tools: {
                                    dock: 'left',
                                },
                            },
                        }
                    }}
                    onLoad={onLoad} style={{ height: '89.5vh' }} />
            </div>
            <div id="prewiewcontainer1" style={{
                position: 'absolute',  // Permet de placer la div à un endroit spécifique
                top: 0,                // Placer la div au haut de la page
                left: 0,               // Placer la div à gauche
                width: '100%',         // S'assurer que la div occupe toute la largeur
                height: '100%',        // S'assurer que la div occupe toute la hauteur
                zIndex: -1,            // Place la div derrière les autres éléments
            }} dangerouslySetInnerHTML={{ __html: htmlpreview }} />
        </Box>
    );
};

export default SiteBuilder;
