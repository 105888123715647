/* eslint-disable import/order */
import React, { useState } from 'react';

import { Anchor, Drawer } from 'antd';

import { Grid, Button, Box, Typography, Stack } from '@mui/material';
import Iconify from '../Iconify';
import { ReactComponent as Jotaro } from '../../assets/logo/choptonticket-3.svg';
import logo from '../../assets/logo/logo2.png';
import { NavLink } from 'react-router-dom';
import CustomButton from '../CustomButton';

const { Link } = Anchor;

function AppHeader() {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="container-fluid">
      <div className="header">
        <div className="logo">
          <Box sx={{ width: 55, height: 55 }}>
            <a href="/">
              <img src={logo} alt="Logo" />
            </a>
          </Box>
        </div>

        <div className="mobileHidden">
          <Anchor targetOffset="65">
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <Link href="#works" ><Typography color='white'>Comment ca marche</Typography> </Link>
              </Grid>
              <Grid item>
                <Link href="#feature" > <Typography color='white'>Services</Typography> </Link>
              </Grid>
              <Grid item>
                <Link href="#pricing" ><Typography color='white'>Tarifs</Typography> </Link>
              </Grid>
              <Grid item>
                <Link href="#faq"> <Typography color='white'>FAQ</Typography> </Link>
              </Grid>
              <Grid item>
                <CustomButton type='primaire' buttonText='Se connecter' size="large" href="/bo" />
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#141414',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#141414',
                      borderColor: '#141414',
                    },
                  }}
                  variant="contained"
                  href="/bo/new_event"
                >
                  Creer un evenement
                </Button>
              </Grid>
            </Grid>
          </Anchor>
        </div>
        <div className="mobileVisible">
          <Stack direction="row">
            <CustomButton type='primaire' buttonText='Se connecter' size="large" href="/bo" />
            <Box paddingX={1}/>
            <Button
              size="large"
              sx={{
                textTransform: 'initial',
                border: '2px solid transparent',
                color: 'white',
                backgroundColor: '#141414',
                borderRadius: '7px',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#141414',
                  borderColor: '#141414',
                },
              }}
              variant="contained"
              href="/bo/new_event"
            >
              Creer un evenement
            </Button>
          </Stack>

        </div>
      </div>
    </div>
  );
}

export default AppHeader;
