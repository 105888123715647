/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Container,
  Typography,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Card,
  TextField,
  CircularProgress,
  Backdrop,
  Stack,
  Modal,
  Box,
  Link,
  CardHeader,
  Skeleton,
  Tooltip,
  IconButton,
  Alert,
  AlertTitle,
  alpha,
  Menu,
  styled,
  MenuItem,
  LinearProgress,
  Snackbar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from '@mui/material';
import React, { useState } from 'react';
// components

import { collection, doc, getCountFromServer, getDoc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';

import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FacebookMessengerShareButton, FacebookShareButton, LinkedinShareButton, MailruShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { db } from '../firebase.config';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { UserAuth } from '../context/AuthContext';
import CustomButton from '../components/CustomButton';
import CheckCard from '../components/CheckCard';
import { shortNumber } from '../utils/formatNumber';
import Scrollbar from '../components/Scrollbar';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 350,
  bgcolor: 'background.paper',
  p: 4,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 7,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '7px',
  boxShadow: 0,
};

export default function DashboardApp() {
  const theme = useTheme();
  const { eventID } = useParams();
  const [events, setEvents] = React.useState();
  const [seances, setSeances] = React.useState([]);
  const [selectedSeance, setSelectedSeances] = React.useState(null);
  const [seanceParticipant, setSeanceParticipant] = React.useState();
  const [org, setOrg] = React.useState({});
  const [refresh, setRefresh] = React.useState(true);
  const [tmp, setTmp] = React.useState(0);
  const [countTickets, setCountTickets] = React.useState(0);
  const [tickets, setTicket] = React.useState([]);
  const [stats, setStat] = React.useState([]);
  const [Totalticket, setTotalTicket] = React.useState([]);
  const [repartTicket, setrepartTicket] = React.useState([]);
  const [ticketN, setTicketN] = React.useState([]);
  const [Recette, setRecette] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const createEvent = (doc) => {
    setrepartTicket([]);

    setEvents(doc);
  };

  React.useEffect(() => {
    const id = eventID;
    let e;
    const docRef0 = doc(db, 'evenement', id);
    setrepartTicket([]);
    setTicket([])
    setSeances([])
    setStat([]);

    const fetchData = async () => {
      try {
        setLoading(true);
        setrepartTicket([]);
        setTicket([])
        // setSeances([])
        setStat([]);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          e = docSnap.data();
          createEvent(e);
          setTmp(1);
        }

        if (events !== null) {
          const tmpdate = new Date(
            format(new Date(Date.now()), "yyyy-MM-dd")
          );
          const seanceSnap = await getDocs(query(collection(db, `evenement/${eventID}/seances`), where("date", ">=", tmpdate), orderBy('date', 'asc'), limit(3)));
          setSeances([])
          seanceSnap.forEach((element) => {
            setSeances((seances) => [...seances, element]);
          });

          const q = query(collection(db, `evenement/${eventID}/tickets`), limit(3));

          const snapshot = await getDocs(q);
          const snapshot1 = await getCountFromServer(collection(db, `evenement/${eventID}/tickets`));
          setCountTickets(snapshot1.data().count)
          setTotalTicket(snapshot.size);
          setTicket([])
          setrepartTicket([]);
          setTicketN([]);
          let montant = 0;
          snapshot.forEach((d) => {
            setTicket((tickets) => [...tickets, d]);
            montant += d.data().sell * d.data().price;
            const repart = { label: d.data().ticket_name, value: d.data().sell };
            setrepartTicket((repartTicket) => [...repartTicket, repart]);
            setTicketN((ticketN) => [...ticketN, d.data().ticket_name]);
          });

          const docSnap1 = await getDoc(doc(db, 'organisations', docSnap.data().orgId));
          setOrg(docSnap1.data());
          console.log('org : ', org);
          const statsSnap = await getDocs(query(collection(db, `evenement/${eventID}/stats`)));
          statsSnap.forEach((element) => {
            setStat((stats) => [...stats, element]);
          });

          const recetteSnap1 = await getDoc(doc(db, `evenement/${eventID}/recette`, eventID));
          setRecette(recetteSnap1.data().recette);
        }
        setShareUrl(`https://choptonticket.com/fr/event/${eventID}`)
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, [tmp, refresh]);

  React.useEffect(() => {
    const id = eventID;
    let e;
    const docRef0 = doc(db, 'evenement', id);
    setLoading(true);
    setrepartTicket([]);
    setTicket([])
    setSeances([])
    setStat([]);
    const fetchData = async () => {
      try {

        if (events !== null && selectedSeance !== null) {
          console.warn(selectedSeance.data())
          const partSnap = await getDoc(doc(db, `evenement/${eventID}/seances`, selectedSeance.id));
          setSeanceParticipant(partSnap.data().participants)
          setRecette(partSnap.data().recette)
          // ticket
          setrepartTicket([]);
          setTicketN([]);
          let tmpTotalTicket = 0;
          partSnap.data().billets.forEach(async (d) => {
            if (d.status === 1) {
              tmpTotalTicket = 1 + tmpTotalTicket

              const ticketSnap = await getDoc(doc(db, `evenement/${eventID}/tickets`, d.id));
              setTicket((tickets) => [...tickets, ticketSnap]);
              const repart = { label: ticketSnap.data().ticket_name, value: d.sell };
              setrepartTicket((repartTicket) => [...repartTicket, repart]);
              setTicketN((ticketN) => [...ticketN, ticketSnap.data().ticket_name]);
            }
          });
          setTotalTicket(tmpTotalTicket)
          // ---------------
          // stats
          const statsSnap = await getDocs(query(collection(db, `evenement/${eventID}/seances/${selectedSeance.id}/stats`)));
          statsSnap.forEach((element) => {
            setStat((stats) => [...stats, element]);
          });
          //------------
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, [selectedSeance]);

  const [sevenDays, setSevenDays] = React.useState([
    '01 jan',
    '02 jan',
    '03 jan',
    '04 jan',
    '05 jan',
    '06 jan',
    '07 jan',
  ]);

  const [sevenDaysData, setSevenDaysData] = React.useState([
    {
      name: 'Tarif normal',
      type: 'line',
      fill: 'solid',
      data: [23, 11, 22, 27, 13, 22, 37],
    },
    {
      name: 'Tarif VIP',
      type: 'line',
      fill: 'solid',
      data: [44, 55, 41, 67, 22, 43, 21],
    },
    {
      name: 'Tarif VVIP',
      type: 'line',
      fill: 'solid',
      data: [4, 60, 20, 67, 96, 30, 20],
    },
    {
      name: 'DELUX',
      type: 'line',
      fill: 'solid',
      data: [30, 25, 36, 30, 45, 35, 64],
    },
  ]);

  // publier mon evenement
  const publier = async () => {
    setOpenModal(true);
    try {
      // handleToggle();
      const statRef = doc(db, `evenement`, `${eventID}`);

      await updateDoc(statRef, {
        status: 1,
      });
      if (tmp === 0) {
        setTmp[1];
      } else {
        setTmp[0];
      }
      setOpenModal(false);
    } catch (error) {
      // handleClose();
      console.log(error.message);
      setOpenModal(false);
    }
  };
  const stopPublication = async () => {
    handleClosConfirm();
    setOpenModal(true);
    try {
      // handleToggle();
      const statRef = doc(db, `evenement`, `${eventID}`);

      await updateDoc(statRef, {
        status: 0,
      });
      if (tmp === 0) {
        setTmp[1];
      } else {
        setTmp[0];
      }
      setOpenModal(false);
    } catch (error) {
      // handleClose();
      console.log(error.message);
      setOpenModal(false);
    }
  };

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return date;
  };
  const handleTimestamp1 = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[1];
  };

  // backdrop
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  // confirmation modam
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClosConfirm = () => setOpenConfirm(false);

  // Modal Detail revenu
  const [openRecetteModal, setOpenRecetteModal] = React.useState(false);
  const [infoRevenue, setInfoRevenue] = React.useState(false);
  const handleOpenRecetteModal = async () => {
    setOpenRecetteModal(true);
    try {
      const recetteSnap1 = await getDoc(doc(db, `evenement/${eventID}/recette`, eventID));
      setRecette(recetteSnap1.data().recette);
      setInfoRevenue(recetteSnap1.data());
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseRecetteModal = () => setOpenRecetteModal(false);

  // Modal custom links
  const [openCustomModal, setOpenCustomModal] = React.useState(false);
  const [custom, setCustom] = React.useState('');
  const [linkExist, setLinkExist] = React.useState(false);
  const [loadingSub, setLoadingSub] = React.useState(false);
  const handleCustomLinks = async (e) => {
    setLoadingSub(true)
    setCustom(e)
    try {
      if (e.length > 3) {
        const check = await getDoc(doc(db, `links`, e));
        if (check.exists()) {
          setLinkExist(true)
        } else {
          setLinkExist(false)
        }
      }

    } catch (error) {
      console.log(error);
    }
    setLoadingSub(false)
  };
  const [isAvailable, setIsAvailable] = React.useState(false);
  const handleOpenCustomModal = async () => {
    setOpenCustomModal(true);
  };
  const handleCloseCustomModal = () => setOpenCustomModal(false);

  const saveCustomLink = async () => {
    setLoadingSub(true)
    try {
      const link = await setDoc(doc(db, `links`, custom),{
        subdomain: custom,
        eventID
      });
      await updateDoc(doc(db, `evenement`, eventID),{
        customLink: custom
      });
      handleCloseCustomModal()
      setMsg({ content: 'la demande de creation de votre lien est faite. votre sera disponible dans maximun 24h', color: 'green' });
      setOpenSnack(true);
      setRefresh(!refresh)
    } catch (error) {
      console.log(error);
    }
    setLoadingSub(false)
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openFiltre = Boolean(anchorEl);
  const [openClos, setOpenClos] = useState(false);
  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (e) => {
    setAnchorEl(null);
    console.log(e)
    if (e !== 0) {
      console.log(seances[e - 1].data())
      setSelectedSeances(seances[e - 1])
    } else {
      setSeances([])
      setSelectedSeances(null)
      setRefresh(!refresh)
    }
    if (e === 2) {
      setOpenClos(true);
    }
  };

  const today = new Date();
  const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes());
  const [hoverVisibility, setHoverVisibility] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(`https://choptonticket.com/event/${eventID}`)
    setMsg({ content: 'Lien copié dans le papiier presse', color: 'green' });
    setOpenSnack(true);
  };

  const goToSite = () => {
    // Remplacez l'URL par celle que vous voulez ouvrir
    const url = `https://choptonticket.com/fr/event/${eventID}`;
    window.open(url, '_blank'); // '_blank' ouvre l'URL dans un nouvel onglet
  };

  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const [shareUrl, setShareUrl] = React.useState()

  return (
    <Page title="Dashboard">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openModal}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      <Modal
        open={openConfirm}
        onClose={handleClosConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography paddingBottom={2} id="modal-modal-title" variant="body1" component="h2">
            Souhaiter réellement clos votre evenement
          </Typography>
          <Stack alignItems="center" alignContent="center" justifyContent="space-around" direction="row" spacing={2}>
            <CustomButton backgroundColor="black" color="#fff" buttonText="Non" onClick={() => handleClosConfirm()} />
            <CustomButton backgroundColor="#5E17EB" color="#fff" buttonText="Oui" onClick={() => stopPublication()} />
          </Stack>
        </Box>
      </Modal>
      {events && (
        <Container sx={{ marginTop: { xs: 3 } }}>
          <Typography variant='h2'>Tableau de bord</Typography>
          <Grid paddingTop={2} container spacing={3}>
            <Grid item xs={12}>
              <CheckCard />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{
                padding: 2, height: '100%', width: '100%', color: (theme) => theme.palette.primary.darker,
                bgcolor: (theme) => theme.palette.primary.lighter,
              }}>
                <Stack spacing={2}>
                  <Typography variant='body1'><strong>Revenues nettes</strong></Typography>
                  {
                    loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h4'>{shortNumber(Recette)} CFA</Typography>
                  }
                </Stack>
                <Typography paddingTop={4} sx={{ cursor: 'pointer' }} variant='body2' onClick={() => {
                  // navigate(`/${eventID}/dashboard/statistique`, { replace: true });
                  handleOpenRecetteModal();
                }}>Voir details</Typography>
              </Card>
              {/** Modal repartition de vente */}
              <Modal
                open={openRecetteModal}
                onClose={handleCloseRecetteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ borderRadius: '7px' }}
              >
                <Box sx={styleModal}>
                  <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='subtitle1'>
                      Répartition des ventes
                    </Typography>
                  </Box>

                  <Divider />
                  <Stack spacing={2} padding={3}>
                    <Stack direction="row" justifyContent="space-between" sx={{ px: 2 }} dir="ltr">
                      <Typography variant="body2"> <strong>Revenu global</strong></Typography>
                      <Typography variant="body2"><strong>{infoRevenue?.recette} CFA</strong></Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" sx={{ px: 2, paddingLeft: 4 }} dir="ltr">
                      <Typography variant="body2">frais ctt</Typography>
                      <Typography variant="body2">- {infoRevenue?.frais} CFA</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" sx={{ px: 2, pl: 4 }} dir="ltr">
                      <Typography variant="body2">Code promo</Typography>
                      <Typography variant="body2">- {infoRevenue?.codePromo} CFA</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" sx={{ px: 2, pl: 4 }} dir="ltr">
                      <Typography variant="body2">reduction</Typography>
                      <Typography variant="body2">- {infoRevenue?.reduction} CFA</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" sx={{ px: 2 }} dir="ltr">
                      <Typography variant="body2"><strong>Revenu net</strong></Typography>
                      <Typography variant="body2"><strong>{infoRevenue?.recette - infoRevenue?.frais} CFA</strong></Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" sx={{ px: 2, pl: 4 }} dir="ltr">
                      <Typography variant="body2">Vente en ligne</Typography>
                      <Typography variant="body2">{infoRevenue?.recette - infoRevenue?.frais + infoRevenue?.codePromo} CFA</Typography>
                    </Stack>
                    {
                      infoRevenue?.codePromo === 0 ? null :
                        <Stack direction="row" justifyContent="space-between" sx={{ px: 2, paddingLeft: 4 }} dir="ltr">
                          <Typography variant="body2">Promotion Choptonticket</Typography>
                          <Typography variant="body2"> {infoRevenue?.codePromo} CFA</Typography>
                        </Stack>
                    }

                  </Stack>

                  <Divider />
                  <Box padding={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                    <CustomButton type='secondaire' buttonText='fermer' onClick={handleCloseRecetteModal} />
                  </Box>
                </Box>
              </Modal>
              {/** Fin Modal repartition de vente */}
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <Card sx={{
                padding: 2, height: '100%', width: '100%', color: (theme) => theme.palette.primary.darker,
                bgcolor: (theme) => theme.palette.warning.lighter,
              }}>
                <Stack spacing={2}>
                  <Typography variant='body1'><strong>Tickets vendus</strong></Typography>
                  {
                    loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h4'>{selectedSeance != null ? shortNumber(seanceParticipant) : shortNumber(events.participant)}</Typography>
                  }

                </Stack>
                <Typography paddingTop={4} sx={{ cursor: 'pointer' }} variant='body2' onClick={() => {
                  navigate(`/${eventID}/dashboard/participants`);
                }}>Voir commandes</Typography>
              </Card>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <Card sx={{
                padding: 2, height: '100%', width: '100%', color: (theme) => theme.palette.primary.darker,
                bgcolor: (theme) => theme.palette.info.lighter,
              }}>
                <Stack spacing={2}>
                  <Typography variant='body1'><strong>Nombre de billets</strong></Typography>
                  {
                    loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h4'>{countTickets}</Typography>
                  }

                </Stack>
                <Typography paddingTop={4} sx={{ cursor: 'pointer' }} variant='body2' onClick={() => {
                  navigate(`/${eventID}/dashboard/billets`);
                }}>Voir</Typography>
              </Card>
            </Grid>


            <Grid item xs={12} md={6} lg={6} sx={{ width: '100%', height: '100%' }}>
              {loading ? (
                <Skeleton sx={{ width: '100%', height: '100%' }} variant="rounded" />
              ) : (
                <Box>
                  <Typography variant='h4'>Partage</Typography>
                  <Scrollbar>
                    <Box paddingTop={4} paddingBottom={2} paddingX={1}>
                      <Stack direction={'row'} alignItems={'center'}>
                        <Typography variant='body2'><strong>Lien de l'event</strong></Typography>
                        <CustomButton type={'tertiaire'} buttonText={'personnaliser'} onClick={handleOpenCustomModal}/>
                        {/** Modal custom link */}
                        <Modal
                          open={openCustomModal}
                          onClose={handleCloseCustomModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          sx={{ borderRadius: '7px' }}
                        >
                          <Box sx={styleModal}>
                            <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Typography variant='subtitle1'>
                                Créer un lien personnaliser
                              </Typography>
                            </Box>

                            <Divider />
                            <Stack spacing={2} padding={3}>
                              <Stack direction="row" sx={{ px: 2 }} dir="ltr">
                                <TextField fullWidth value={custom} onChange={(e) => handleCustomLinks(e.target.value)}/>
                                <TextField fullWidth value={'.choptonticket.com'} disabled/>
                              </Stack>
                            </Stack>

                            <Divider />
                            <Stack spacing={1} direction={'row'} padding={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                              <CustomButton type='secondaire' buttonText='fermer' onClick={handleCloseCustomModal} />
                              <CustomButton loading={loadingSub} type='primaire' buttonText='Créer' onClick={saveCustomLink} />
                            </Stack>
                          </Box>
                        </Modal>
                        {/** Fin Modal custom links */}
                      </Stack>


                      <Box onMouseEnter={() => { setHoverVisibility(true) }} onMouseLeave={() => { setHoverVisibility(false) }}>
                        <Stack paddingTop={1} spacing={1} direction={{ xs: 'column', sm: 'row' }} alignContent='center'>
                          <Typography sx={{ color: '#5E17EB', cursor: 'pointer' }} onClick={goToSite} variant="body2">{`https://choptonticket.com/event/${eventID}`}</Typography>
                          {
                            hoverVisibility && <Box onClick={handleCopy}>
                              <Stack direction='row' alignItems='center' sx={{ cursor: 'pointer', bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1 }} spacing={0.5}>
                                <Iconify icon='bi:copy' sx={{ width: 15, height: 15 }} />
                                <Typography fontSize={10} variant='caption'>Copier</Typography>
                              </Stack>
                            </Box>
                          }
                        </Stack>
                      </Box>
                    </Box>
                  </Scrollbar>
                  <Box paddingX={1}>
                    <Typography variant='body2'><strong>Partager sur</strong></Typography>
                    <Stack direction='row' spacing={1}>
                      <FacebookShareButton
                        url={`https://choptonticket.com/event/${eventID}?fb=hkjsdqsd`}
                        quote={'Title or jo bhi aapko likhna ho'}
                        hashtag={'#portfolio...'}
                      >
                        <IconButton>
                          <Iconify icon='ri:facebook-fill' />
                        </IconButton>
                      </FacebookShareButton>

                      <FacebookMessengerShareButton >
                        <IconButton>
                          <Iconify icon='teenyicons:messenger-outline' />
                        </IconButton>
                      </FacebookMessengerShareButton>
                      <TwitterShareButton url={`https://choptonticket.com/event/${eventID}?fb=hkjsdqsd`}>
                        <IconButton>
                          <Iconify icon='mingcute:social-x-fill' />
                        </IconButton>
                      </TwitterShareButton>
                      <MailruShareButton>
                        <IconButton>
                          <Iconify icon='material-symbols-light:mail' />
                        </IconButton>
                      </MailruShareButton>

                      <LinkedinShareButton
                        url={`https://choptonticket.com/event/${eventID}?fb=hkjsdqsd`}
                      >
                        <IconButton>
                          <Iconify icon='akar-icons:linkedinv1-fill' />
                        </IconButton>
                      </LinkedinShareButton>

                      <WhatsappShareButton
                        url={`https://choptonticket.com/event/${eventID}?fb=hkjsdqsd`}
                        quote={'Title or jo bhi aapko likhna ho'}
                        hashtag={'#portfolio...'}
                      >
                        <IconButton>
                          <Iconify icon='mingcute:whatsapp-fill' />
                        </IconButton>
                      </WhatsappShareButton>

                    </Stack>
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            {
              events.seanceActive === 1 ?
                <Grid item xs={12} md={6} lg={8}>
                  {loading ? (
                    <Skeleton sx={{ width: '100%', height: '100%' }} variant="rounded" />
                  ) : (
                    <Box>
                      <Typography variant='h4'>Seances à venir</Typography>
                      <Box paddingY={4} paddingX={1}>
                        <Scrollbar>
                          <Table sx={{ minWidth: 500 }} aria-label="caption table">
                            <TableHead>
                              <TableRow>
                                <TableCell><strong>Seances</strong></TableCell>
                                <TableCell align="left"><strong>Ventes</strong></TableCell>

                                <TableCell align="left"><strong>Participants</strong></TableCell>
                              </TableRow>
                            </TableHead>
                            {loading ? (
                              <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" />
                            ) : seances.length === 0 && !loading ? (
                              <caption>
                                <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                  <Iconify icon="icomoon-free:drawer2" sx={{ width: 50, height: 50 }} />
                                  <Typography variant="caption">Aune seances à venir</Typography>
                                </Stack>
                              </caption>
                            ) : (
                              <TableBody>
                                {seances.map((seance, index) => (
                                  <TableRow key={index} hover>
                                    <TableCell component="th" scope="row">
                                      <Stack sx={{ cursor: 'pointer', color: '#5E17EB' }} onClick={() => { navigate(`/${eventID}/dashboard/datelist/${seance.id}`); }}>
                                        <Typography variant='body2'><strong>{seance?.data().name}</strong></Typography>
                                        <Typography variant='body2'>{format(new Date(handleTimestamp(seance?.data().date)), 'EEEE dd MMM yyyy à hh:mm', { locale: fr })}</Typography>
                                      </Stack>

                                    </TableCell>

                                    <TableCell align="left" >
                                      {(seance?.data().recette !== 0 && seance?.data().recette) ? (
                                        `${shortNumber(seance?.data().recette)} CFA`
                                      ) : (
                                        <Typography variant="caption">-</Typography>
                                      )}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="left">
                                      {seance?.data().participants !== 0 ? (
                                        `${shortNumber(seance?.data().participants)}`
                                      ) : (
                                        <Typography variant="caption">-</Typography>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            )}
                          </Table>
                        </Scrollbar>
                      </Box>
                    </Box>
                  )}
                </Grid>

                :

                <Grid item xs={12} md={6} lg={8}>
                  {loading ? (
                    <Skeleton sx={{ width: '100%', height: '100%' }} variant="rounded" />
                  ) : (
                    <Box>
                      <Typography variant='h4'>Vente par type de billet</Typography>
                      <Box paddingY={4} paddingX={1}>
                        <Scrollbar>
                          <Table sx={{ minWidth: 500 }} aria-label="caption table">
                            <TableHead>
                              <TableRow>
                                <TableCell><strong>Ticket</strong></TableCell>
                                <TableCell align="left"><strong>Prix</strong></TableCell>

                                <TableCell align="left"><strong>Vendu</strong></TableCell>
                              </TableRow>
                            </TableHead>
                            {loading ? (
                              <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" />
                            ) : tickets.length === 0 && !loading ? (
                              <caption>
                                <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                  <Iconify icon="icomoon-free:drawer2" sx={{ width: 50, height: 50 }} />
                                  <Typography variant="caption">Aucun billet pour cet event</Typography>
                                </Stack>
                              </caption>
                            ) : (
                              <TableBody>
                                {tickets.map((ticket, index) => (
                                  <TableRow key={index} hover>
                                    <TableCell component="th" scope="row">
                                      <Typography variant='body2'>{ticket.data().ticket_name}</Typography>
                                    </TableCell>

                                    <TableCell align="left" >
                                      {ticket.data().price !== 0 ? (
                                        `${shortNumber(ticket.data().price)} CFA`
                                      ) : (
                                        <Typography variant="caption">Gratuit</Typography>
                                      )}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="left">
                                      <Typography variant='body2'>{ticket.data().sell} / {ticket.data().quota}</Typography>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            )}
                          </Table>
                        </Scrollbar>
                      </Box>
                    </Box>
                  )}
                </Grid>
            }

            <Grid item xs={12} md={6} lg={4}>
              <Box padding={2}>
                <Typography variant='h6'>Recommandations</Typography>
                <Box>
                  <Stack direction='row' spacing={1} paddingY={2}>
                    <Iconify icon='nimbus:marketing' sx={{ width: 24, height: 24 }} />
                    <Typography variant='body2'>Increase your sales by 63% with a Boost marketing campaign. Launch a new campaign</Typography>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} >
              {!loading ? (
                <>
                  <Typography variant='h4'>Evolution des ventes sur la semaine</Typography>
                  <AppWebsiteVisits
                    title=""
                    chartLabels={sevenDays}
                    chartData={sevenDaysData}
                    chartStat={stats}
                    ticketName={ticketN}
                  /></>
              ) : (
                <Skeleton sx={{ height: '100%' }} variant="rounded" />
              )}
            </Grid>
          </Grid>
        </Container>
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography paddingRight={1} variant='body2'>{msg.content}{' '}</Typography>
            <Iconify
              sx={{ color: msg.color }}
              icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
            />
          </Box>
        }
      />
    </Page>
  );
}
