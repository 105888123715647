/* eslint-disable no-nested-ternary */
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import ReactPlayer from 'react-player';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box, Tabs, Tab, Grid, Stack, Button, TextField, IconButton, alpha, Menu, MenuItem, InputAdornment } from '@mui/material';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections

import BasicTabs from '../components/home/BasicTabs';
import HomeNavbar from '../layouts/home/HomeNavbar';
import CustomButton from '../components/CustomButton';
import CheckOrg from '../components/CheckOrg';
import Iconify from '../components/Iconify';
import EventList from '../components/home/eventList';

// ---------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: '#ffff',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: `calc(100%*0.7)`,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// for modal bouton
// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: 5,
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

// ----------------------------------------------------------------------

export default function Home() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(1);
  const [eventName, setEventName] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  return (
    <Page title="Back office">
      <RootStyle>
        <HomeNavbar onOpenSidebar={() => setOpen(true)} />
        <Container >
          <Stack paddingTop={10} spacing={3}>
            <Typography sx={{ fontWeight: 900 }} variant="h2">Mes évenements</Typography>
            <Stack pt={2} spacing={2} direction={{ xs: 'column-reverse', sm: 'row' }} useFlexGap flexWrap="wrap" justifyContent='space-between'>
              <Stack direction='row' spacing={2} flex={1} alignItems='center'>
                <TextField fullWidth value={eventName} onChange={(e) => setEventName(e.target.value)} placeholder='Chercher des evenements' InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="iconoir:search" />
                    </InputAdornment>
                  ),
                }} />
              </Stack>
              <Box paddingY={1}>
                <CustomButton
                size="large"
                  type='primaire'
                  buttonText="Créer un événement"
                  component={RouterLink}
                  to="/bo/new_event"
                />
              </Box>

            </Stack>
            <Stack direction='row' spacing={1}>
              <Box onClick={() => setType(0)} sx={type === 0 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
                :
                { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                <Typography variant='body2'>Tous</Typography>
              </Box>
              <Box onClick={() => setType(1)} sx={type === 1 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
                :
                { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                <Typography variant='body2'>Publié</Typography>
              </Box>
              <Box onClick={() => setType(2)} sx={type === 2 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
                :
                { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                <Typography variant='body2'>Brouillon</Typography>
              </Box>
              <Box onClick={() => setType(3)} sx={type === 3 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
                :
                { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                <Typography variant='body2'>Clos</Typography>
              </Box>
            </Stack>
            <Box>
              <EventList type={type} eventName={eventName} />
            </Box>
          </Stack>

          {
            /*
            <>
            {!smUp && (
              <>
                <Grid container sx={{ paddingTop: 10, paddingBottom: 2 }} spacing={3}>
                  <Grid item xs={12}>
                    <CheckOrg />
                    <Typography variant="h3">Mes évenements</Typography>
                  </Grid>
                  <Grid textAlign="center" item xs={8}>
                    <CustomButton
                      type='primaire'
                      buttonText="Créer un événement"
                      component={RouterLink}
                      to="/bo/new_event"
                    />
                  </Grid>
                </Grid>

                <Box sx={{ pt: 1, pb: 1 }}>
                  <Box sx={{ pt: 1, pb: 1 }} dir="ltr">
                    <BasicTabs />
                  </Box>
                </Box>
              </>
            )}
            {mdUp && (
              <ContentStyle>
                <CheckOrg />
                <Grid alignContent="center" container sx={{ paddingTop: 2, paddingBottom: 2 }} spacing={3}>
                  <Grid item xs={8}>
                    <Typography variant="h2">Mes événements</Typography>
                  </Grid>
                  <Grid textAlign="center" item xs={4}>
                    <CustomButton
                      type='primaire'
                      buttonText="Créer un événement"
                      component={RouterLink}
                      to="/bo/new_event"
                    />
                  </Grid>
                </Grid>

                <Box sx={{ p: 1, pb: 1 }} dir="ltr">
                  <BasicTabs />
                </Box>

              </ContentStyle>
            )}
          </>
          */
          }
        </Container>
      </RootStyle>
    </Page>
  );
}
