import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  useTheme,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Menu,
  Link,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc } from 'firebase/firestore';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';

// mock
import USERLIST from '../../../_mock/user';

import { db } from '../../../firebase.config';
import CheckCard from '../../../components/CheckCard';
import { UserAuth } from '../../../context/AuthContext';
import Coordonnees from "../../organisation/CoordonneesBancaires";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



export default function SecurityPass() {
  const { eventID } = useParams();
  const { user } = UserAuth();
  const theme = useTheme();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  

  
  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [refresh, setRefresh] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    // setModif(false);
    setRefresh((prev) => !prev);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  // take operateurs from firebase
  const [contacts, setContact] = React.useState([]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // GESTION DE LA MODIFICATION
  const [temp, setTemp] = React.useState([]);
  const [name, setName] = React.useState();
  const [id, setId] = React.useState();
  const [pass, setPass] = React.useState();
  

  // *****ticket
  const [ticketVerif, setTicket] = React.useState([]);
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const [personName, setPersonName] = React.useState([]);
  const handleChangeTicket = (event) => {
    const {
      target: { value },
    } = event;
    setTicket(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    console.log(ticketVerif);
  };

  const [modif, setModif] = React.useState(false);
  const handleModif = (index) => {
    if (modif) {
      setModif(false);
    } else {
      const temp = contacts[index].data();
      console.log(index);
      console.log(temp.tickets);
      setName(temp.nameOp);
      setId(temp.idOp);
      setPass(temp.passOp);
      setTicket(temp.tickets);
      setModif(true);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async (e) => { };
  const [menu, setMenu] = React.useState(0);

  // pin
  const [pin, setPin] = React.useState(['', '', '', '']);
  const [isPin, setIsPin] = React.useState(false);
  const [verifiedPin, setVerifiedPin] = React.useState(false);
  const [isVerified, setIsVerified] = React.useState(false);

  const handlePinChange = (index, value) => {
    const newPin = [...pin];
    if (/^\d$/.test(value)) {
      newPin[index] = value;
    }

    if (newPin[index] !== undefined && newPin[index] !== null) {
      newPin[index] = value.charAt(value.length - 1);
    }
    if (index > 0 && value === '') {
      // If a digit is deleted, move focus to the previous input
      document.getElementById(`pin-input-${index - 1}`).focus();
    } else if (index < 3 && value !== '') {
      // Move focus to the next input
      document.getElementById(`pin-input-${index + 1}`).focus();
    }

    setPin(newPin);

    // Check if all 4 digits are entered
    if (newPin.every((digit) => digit !== '')) {
      handleSavePin(newPin.join(''));
    }
  };

  const handleSavePin = async (e) => {
    setLoading(true);
    console.log(e);

    try {
      const refDesc = doc(db, `organisations/`, `${id}`);

      if (e !== '') {
        await updateDoc(refDesc, {
          pin: e,
          pinError: 0
        });
      }
      setPin(['', '', '', '']);
      setLoading(false);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data().idOrg);
        if (docSnap.exists()) {
          // info sur l'organisation
          const q1 = doc(db, `organisations`, docSnap.data().idOrg);
          const orgSnap = await getDoc(q1);
          const tmp = orgSnap.data().pin;
          console.log('PIN : ');
          console.log(tmp);
          if (tmp !== undefined) {
            setIsPin(true);
          } else {
            setIsPin(false);
          }
          // info de paie
          console.log(docSnap.data().idOrg);
          setId(docSnap.data().idOrg);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [user.uid, refresh]);

  return (
 
      <Box
        sx={{
          height: '100%',
          borderRadius: '7px',
          padding: 3,
          border: '2px solid gainsboro',
          display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'
        }}
      >
        <Box sx={{
          width: '100%',
        }}>
          <Typography variant='h5' textAlign="Start">
            Sécurité
          </Typography>
        </Box>


        <Iconify icon='pepicons-print:lock-closed' sx={{ width: 60, height: 60 }} />
        <Stack spacing={3} justifyContent='center'>
          
          <Box dir="ltr">
            {isPin ? (
              <Typography variant='subtitle2' textAlign='center'>Vous avez configuré un code PIN. Pour le changer, contactez notre support.</Typography>
            ) : (
              <>
                <Typography variant='subtitle2' textAlign='center'>Sécurisez l'accès à vos informations de reversement avec un code PIN</Typography>
                <Typography variant='body2' textAlign='center'>Entrez votre PIN :</Typography>
                <Stack direction="row" spacing={2} padding={2} justifyContent="center">
                  {pin.map((digit, index) => (
                    <Box key={index}>
                      <TextField
                        key={index}
                        id={`pin-input-${index}`}
                        type="password"
                        variant="outlined"
                        size="medium"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handlePinChange(index, e.target.value)}
                        sx={{ width: '35px' }}
                      />
                    </Box>
                  ))}
                </Stack>
                <Typography variant='body2' textAlign='center'>Une fois configurez, vous pourrez le recevoir par mail lorsque vous l'aurez oublié</Typography>
              </>
            )}
          </Box>

        </Stack>

      </Box>
    


  );
}
