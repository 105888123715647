import * as Yup from 'yup';
// @mui
import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Grid,
    Container,
    Typography,
    Box,
    Stack,
    Button,
    TextField,
    Divider,
    Backdrop,
    CircularProgress,
    Alert,
    Autocomplete,
    Chip,
    LinearProgress,
} from '@mui/material';


// components
import { LoadingButton, StaticDateTimePicker } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Timestamp, addDoc, arrayUnion, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { parseInt } from 'lodash';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import CustomDateTimePicker from '../../../components/CustomDateTimePicker';
import { db } from '../../../firebase.config';

import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import { FormProvider } from '../../../components/hook-form';
import { Variables } from '../../../context/VariableContext';
import CustomButton from '../../../components/CustomButton';
import { shortNumber } from '../../../utils/formatNumber';
import CustomSnack from '../../../components/customSnack';




export default function ModifyTicket() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { eventID, billetID } = useParams();

    const { generalReload, setGeneralReload } = Variables()
    const [event, setEvent] = React.useState();
    const [seances, setSeances] = React.useState([]);
    const [ticketData, setTicketData] = React.useState();

    // champs

    // tarif
    const [checked, setChecked] = React.useState(true);
    const [associationSeanceType, setAssociationSeanceType] = React.useState(0);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    // montant
    const [montant, setMontant] = React.useState();
    const [commission, setCommission] = React.useState(0);
    const [revenu, setRevenu] = React.useState(0);
    const handleMontant = (event) => {
        setMontant(event.target.value);
        if (montant !== 0) {
            if (event.target.value >= 10000) {
                console.log('event sup =', event.target.value);
                console.log('montant =', montant, 'cfa');
                const tmp = event.target.value * 5;

                const tmp1 = tmp / 100;

                setCommission(tmp1);
                setRevenu(event.target.value - tmp1);
            } else {
                console.log('event inf =', event.target.value);
                console.log('montant =', montant, 'cfa');
                const tmp = event.target.value * 7;

                const tmp1 = tmp / 100;

                setCommission(tmp1);
                setRevenu(event.target.value - tmp1);
            }
        } else {
            setRevenu(0);
            setCommission(0);
        }
    };

    // quantité
    const [limitedQt, setLinmitedQt] = React.useState(true);

    const handleLimitedQt = () => {
        setLinmitedQt((prev) => !prev);
    };

    // programmation
    // date / time
    const [dateDebut, setDateDebut] = React.useState(null);
    const [timeDebut, setTimeDebut] = React.useState('');

    const handleDateDebutChange = (newDate) => {
        setDateDebut(newDate);
    };

    const handleTimeDebutChange = (newTime) => {
        console.log('parent')
        setTimeDebut(newTime);
    };
    const [dateFin, setDateFin] = React.useState(null);
    const [timeFin, setTimeFin] = React.useState('');

    const handleDateFinChange = (newDate) => {
        setDateFin(newDate);
    };

    const handleTimeFinChange = (newTime) => {
        setTimeFin(newTime);
    };

    // convert timestamp

    const handleTimestamp = (e) => {
        console.log('handle time stamp')
        const date = e.toDate();
        return format((new Date(date.toJSON())), 'EEE dd MMM yyyy', { locale: fr }).toString();
    };

    const handleTimestampCustom = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.')[0].split('T')[1].split(':');
        return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
    };


    const [autoVente, setAutoVente] = React.useState(false);

    // programmation
    const [quantitePerClient, setQuantitePerClient] = React.useState();

    // config des champs
    const [libelle, setLibelle] = React.useState('');
    const [libelleError, setLibelleError] = React.useState(false);
    const [prixError, setPrixError] = React.useState(false);
    const [quantite, setQuantite] = React.useState();
    const [quantiteError, setQuantiteError] = React.useState(false);
    const [advancedConfig, setAdvancedConfig] = React.useState(false);
    const [desc, setDesc] = React.useState('');
    const [seanceError, setSeanceError] = React.useState(false);
    const RegisterSchema = Yup.object().shape({
        nom: Yup.string().required('Nom est obligatoire'),
        // lastName: Yup.string().required('Prénom est obliagtoire'),
        // email: Yup.string().email('Email doit est être un email valide').required('Email est obligatoire'),
        // password: Yup.string().required('Mot de passe est obligatoire'),
    });

    //  snack bar
    const [openSnack, setOpenSnack] = React.useState(false);
    const [msg, setMsg] = React.useState({ content: '', color: '' });
    const handleCloseSnack = () => {
        setOpenSnack(false);
    };

    const defaultValues = {
        nom: 'ticket',
        // lastName: '',
        // email: '',
        // password: '',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;
    const [loading, setLoading] = React.useState(false);
    // billet
    const onSubmit = async () => {
        setLoading(true);
        setLibelleError(false);
        setPrixError(false);
        setQuantiteError(false);
        setSeanceError(false)
        let tmp = false;

        try {
            // handleToggle();
            console.log(quantite)
            // Vérification des erreurs
            if (libelle === '') {
                setLibelleError(true);
                tmp = true;
            }
            if (checked && (!montant || montant === 0)) {
                setPrixError(true);
                tmp = true;
            }
            if (!quantite || quantite === 0) {
                setQuantiteError(true);
                tmp = true;
            }
            if ((event?.seanceActive === 0 && quantite < ticketData?.data().sell)) {
                setQuantiteError(true);
                tmp = true;
                setMsg({ content: 'Vous devez saisir un quota supérieur au nombre de ticket vendu', error: true });
                setOpenSnack(true);
                setLoading(false);
                return;
            }
            if (associationSeanceType === 1 && selectedTagsId.length === 0) {
                setSeanceError(true)
                tmp = true;
            }

            if (tmp) {
                console.error('champs requis');
                setMsg({ content: 'Une erreur est survenue', error: true });
                setOpenSnack(true);
                setLoading(false);
                return; // Arrêter la fonction si des erreurs sont présentes
            }
            const eventRef = doc(db, `evenement/${eventID}`);
            // Créer le billet
            await updateDoc(doc(db, `evenement/${eventID}/tickets`, ticketData?.id), {
                ticket_name: libelle,
                status: 1,
                price: checked ? parseInt(montant) : 0,
                description: desc,
                updateDate: Timestamp.fromDate(new Date()),
            });

            const statRef = doc(db, `evenement/${eventID}/tickets`, ticketData?.id);

            if (event?.seanceActive === 0) {
                await updateDoc(statRef, { quota: parseInt(quantite)});
            }

            // Gestion de l'automatisation de la vente
            if (autoVente) {
                const tmpDateDebut = new Date(dateDebut);
                tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
                const tmpDateFin = new Date(dateFin);
                tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);

                await updateDoc(doc(db, `automationTickets/scheduling/automations`, ticketData?.data().automation), {
                    debut: Timestamp.fromDate(tmpDateDebut),
                    fin: tmpDateFin ? Timestamp.fromDate(tmpDateFin) : null,
                });

                await updateDoc(statRef, { status: 2 });
            }


            // Mise à jour du prix minimal
            const tmpM = checked ? parseInt(montant) : 0;
            

            if (!event?.minPrice || tmpM < event?.minPrice) {
                await updateDoc(eventRef, { minPrice: tmpM });
            }
            setMsg({ content: 'Le billet a été créé avec succès', error:false });
            setOpenSnack(true);
            // setGeneralReload(!generalReload);
            if (!tmp) navigate(-1, { replace: true });
        } catch (error) {
            console.error('Erreur lors de la création du billet :', error.message);
            setMsg({ content: 'Une erreur est survenue', error: true });
            setOpenSnack(true);
        }

        setLoading(false);
        handleClose();
        
    };


    // chargement
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    const [isModified, setIsModified] = React.useState(false);

    const handleFieldChange = () => {
        setIsModified(true);
    };

    const [selectedTags, setSelectedTags] = React.useState([]);
    const [selectedTagsId, setSelectedTagsId] = React.useState([]);
    const handleTagChange = async (event, value) => {
        setLoading(true)
        const tmp = []
        console.log(value)
        value.forEach(element => {
            console.log(element.id)
            tmp.push(element.id)
        });
        try {
            setSelectedTagsId(tmp)
            setSelectedTags(value);
        } catch (error) {
            console.log(error)
        }
        console.log(selectedTags)
        setLoading(false)
    };

    React.useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const docRef0 = doc(db, 'evenement', eventID);
                const docSnap = await getDoc(docRef0);
                if (docSnap.exists()) {
                    setEvent(docSnap.data());
                }

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [eventID]);

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            setSeances([])
            const tmpTags = []
            const tmpTagsId = []
            try {
                if (event?.seanceActive === 1 && ticketData) {
                    console.log('seance ---------------------------------')
                    const q = query(collection(db, `evenement/${eventID}/seances`));
                    const snapshot = await getDocs(q);
                    snapshot.forEach((d) => {
                        setSeances((seances) => [...seances, d]);
                        console.log(ticketData?.data()?.seances?.indexOf(d.id))
                        if(ticketData?.data()?.seances?.indexOf(d.id) >= 0){
                            console.log('seance 1 ---------------------------------')
                            tmpTagsId.push(d.id)
                            tmpTags.push(d)
                        }
                    });
                    setSelectedTagsId(tmpTagsId)
                    setSelectedTags(tmpTags);

                }
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [event, ticketData]);

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            setSeances([])
            if (!ticketData) {
                const docRef0 = doc(db, `evenement/${eventID}/tickets`, billetID);
                const docSnap = await getDoc(docRef0);
                if (docSnap.exists()) {
                    setTicketData(docSnap)
                    try {

                        setLibelle(docSnap.data().ticket_name);
                        setDesc(docSnap.data().description);
                        setMontant(parseInt(docSnap.data().price));
                        setAssociationSeanceType(parseInt(docSnap.data().seanceType))
                        if (parseInt(docSnap.data().price) !== 0) {
                            setChecked(true);
                        } else if (parseInt(docSnap.data().price) === 0) {
                            setChecked(false);
                        }
                        setQuantite(parseInt(docSnap.data().quota));
                        setAssociationSeanceType(parseInt(docSnap.data().seanceType))
                        if (parseInt(docSnap.data().price) !== 0) {
                            console.log('montant =', parseInt(docSnap.data().price));
                            const tmp = parseInt(docSnap.data().price) * 5;

                            const tmp1 = tmp / 100;

                            setCommission(tmp1);

                            setRevenu(parseInt(ticketData.data().price) - tmp1);
                        }
                        setLoading(false);
                        if (ticketData.data().automation) {
                            setAdvancedConfig(true)
                            setAutoVente(true)
                            console.log(ticketData.data().automation)
                            try {
                                const automationDoc = await getDoc(doc(db, `automationTickets/scheduling/automations`, ticketData.data().automation));
                                if (automationDoc.exists()) {
                                    console.log(automationDoc.data())
                                    setDateDebut(handleTimestamp(automationDoc.data().debut))
                                    setTimeDebut(handleTimestampCustom(automationDoc.data().debut))
                                    setDateFin(handleTimestamp(automationDoc.data().fin))
                                    setTimeFin(handleTimestampCustom(automationDoc.data().fin))
                                }


                            } catch (error) {
                                console.log(error)
                                setMsg({ content: 'Une erreur est survenue', color: 'red' });
                                setOpenSnack(true);
                            }

                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            }


        })();
    }, [eventID]);

    // show update
 
      React.useEffect(() => {
        (async () => {
            try {
                console.log(ticketData?.data().ticket_name)
                let checkTmp = false
                if (parseInt(ticketData?.data().price) !== 0) {
                    checkTmp = true;
                } else if (parseInt(ticketData?.data().price) === 0) {
                    checkTmp = false;
                }
                if (libelle !== ticketData?.data().ticket_name || parseInt(montant) !== ticketData?.data().price || desc !== ticketData?.data().description || parseInt(quantite) !== ticketData?.data().quota || checked !== checkTmp) {
                  setIsModified(true);
                } else {
                    setIsModified(false);
                }
            } catch (error) {
                console.error(error)
            }
            
        })();
    }, [libelle, quantitePerClient,autoVente,timeFin,dateFin,timeDebut,dateDebut,montant,desc,quantite, ticketData, checked]);

    return (
        <Page title="Dashboard">
            {
                loading && <LinearProgress />
            }

            <Container sx={{ marginTop: { xs: 3 } }}>
                <Stack alignItems="start" justifyContent="space-between" mb={5}>
                    <CustomButton
                        type='back'
                        buttonText='Retour'
                        startIcon={<Iconify icon='ion:arrow-back-outline' sx={{ width: 20, height: 20 }} />}
                        onClick={() => window.history.back()}
                    />
                    <Typography variant="h4" gutterBottom>
                        Modifier le billet
                    </Typography>
                </Stack>
                <Stack spacing={4} marginBottom={10}>
                    <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px', paddingY: 2 }}>
                        <Box sx={{ p: 3 }} dir="ltr">
                            <Grid alignItems="center" container spacing={3}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Typography> Nom du ticket</Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        placeholder="Exemple: Grand public, VIP, Adulte, Entrée unique, Enfant.."
                                        value={libelle}
                                        required
                                        onChange={(e) => setLibelle(e.target.value)}
                                        name="nom"
                                        error={libelleError}
                                        helperText={libelleError ? 'Cette information est requise' : ''}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px', paddingY: 2 }}>
                        <Box sx={{ p: 3 }} dir="ltr">
                            <Grid alignItems="center" container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Stack direction='row' spacing={2}>
                                        <Box onClick={() => setChecked(true)} sx={checked ? { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                            :
                                            { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingY={2} >
                                            <Typography variant='body1'>Payant</Typography>
                                        </Box>
                                        <Box onClick={() => setChecked(false)} sx={!checked ? { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                            :
                                            { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingY={2} >
                                            <Typography variant='body1'>Gratuit</Typography>
                                        </Box>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    {!checked && <Alert sx={{ borderRadius: '7px' }} severity="info">Ce billet sera vendu gratuitement</Alert>}
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={2}>
                                        <Box flex={1}>
                                            <Stack spacing={2}>
                                                <Typography>Prix de vente du ticket</Typography>
                                                <TextField
                                                    placeholder="Entrer le prix du ticket"
                                                    name="montant"
                                                    type="number"
                                                    fullWidth
                                                    required
                                                    error={prixError}
                                                    disabled={!checked}
                                                    helperText={prixError ? 'Veuillez entrer une valeur valide' : 'Montant en CFA'}
                                                    onChange={handleMontant}
                                                    value={montant}
                                                />
                                            </Stack>
                                            {montant !== 0 && (
                                                <>
                                                    <Grid paddingY={1} paddingX={2} alignItems="center" container>
                                                        <Grid item xs={12} sm={10} md={10}>
                                                            <Stack spacing={2}>
                                                                <Stack direction="row" justifyContent="space-between">
                                                                    <Typography variant="caption">Commission ctt</Typography>
                                                                    <Typography variant="caption">
                                                                        <strong>{shortNumber(commission)} CFA</strong>
                                                                    </Typography>
                                                                </Stack>
                                                                <Stack direction="row" justifyContent="space-between">
                                                                    <Typography variant="caption">Votre revenue</Typography>
                                                                    <Typography variant="caption">
                                                                        <strong>{shortNumber(revenu)} CFA</strong>
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )}
                                        </Box>
                                        <Box flex={1}>
                                            <Stack spacing={2}>
                                                <Typography>Quantité disponible</Typography>
                                                {
                                                    event?.seanceActive === 1 ?
                                                        <Alert sx={{ borderRadius: '7px' }} severity="info">Affiner la quantité max dans la configuration de chaque séance </Alert>
                                                        :
                                                        <>
                                                            <TextField
                                                                value={quantite}
                                                                required
                                                                onChange={(e) => setQuantite(e.target.value)}
                                                                name="quantite"
                                                                type="number"
                                                                error={quantiteError}
                                                                fullWidth
                                                                helperText={
                                                                    quantiteError ? 'Veuillez entrer une valeur valide' : 'le nombre de quantité mise en vente'
                                                                }
                                                            />
                                                            {event?.seanceActive === 1 && <Alert sx={{ borderRadius: '7px' }} severity="info">La quantité sera appliqué pour toutes les seances lors de la creation du billet. il pourra ensuite être affiné dans la configuration de chaque seance </Alert>}

                                                        </>

                                                }

                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {
                        event?.seanceActive === 1 &&
                        <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px', paddingY: 5, paddingX: 3 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Typography variant='h4'>Séance</Typography>
                                    <Typography variant="caption">
                                        {' '}
                                        Associé le billet a une seance
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    <Stack direction='row' spacing={2}>
                                        <Box sx={associationSeanceType === 0 ? { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'not-allowed' }
                                            :
                                            { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'not-allowed' }} padding={2} >
                                            <Typography variant='body1'>Toutes les seances</Typography>
                                        </Box>
                                        <Box sx={associationSeanceType === 1 ? { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'not-allowed' }
                                            :
                                            { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'not-allowed' }} padding={2} >
                                            <Typography variant='body1'>Seance specifiques</Typography>
                                        </Box>
                                    </Stack>
                                </Grid>
                                {
                                    associationSeanceType === 1 && <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            disabled
                                            options={seances}
                                            getOptionLabel={(seance) => handleTimestamp(seance.data().date)}
                                            value={selectedTags}
                                            onChange={handleTagChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder={'Choisisser au moins une seances'}
                                                    error={seanceError}
                                                    multiline
                                                    helperText={seanceError ? 'choisisser au moins une seance' : ''}
                                                />
                                            )}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((seance, index) => (
                                                    <Chip
                                                        sx={{ bgcolor: '#F5EFFA' }}
                                                        key={seance.id}
                                                        label={seance.data().name ? seance.data().name : handleTimestamp(seance.data().date)}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                        />
                                    </Grid>
                                }

                            </Grid>
                        </Box>
                    }

                    <Stack justifyContent='space-between' dir="ltr">
                        <Stack direction='row' justifyContent='space-between' dir="ltr" onClick={() => setAdvancedConfig(!advancedConfig)} sx={{ cursor: 'pointer' }}>
                            <Button
                                variant="text"
                                sx={{
                                    textTransform: 'initial',
                                    border: '2px solid transparent',
                                    color: '#5E17EB',
                                    backgroundColor: 'transparent',
                                    borderRadius: '7px',
                                    borderColor: 'transparent',
                                    '&:hover': {
                                        color: '#5E17EB',
                                        backgroundColor: 'transparent',
                                        borderColor: 'transparent',
                                    },
                                }}
                                onClick={() => setAdvancedConfig(!advancedConfig)}
                            >
                                Configuration avancée
                            </Button>
                            <Iconify onClick={() => setAdvancedConfig(!advancedConfig)} icon={advancedConfig ? "icon-park-solid:up-one" : "icon-park-solid:down-one"} sx={{ color: '#5E17EB' }} />
                        </Stack>
                        <Divider />
                    </Stack>
                    {advancedConfig && (
                        <>
                            <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px', paddingY: 5, paddingX: 3 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} >
                                        <Typography variant='h4'>Description</Typography>
                                        <Typography variant="caption">
                                            {' '}
                                            La description apparaitra sur la page de vente et sur le Ticket PDF
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            fullWidth
                                            name="desc"
                                            placeholder="Donnez plus d'information sur le ticket, tels les conditions d'accès, le bonus liés au ticket"
                                            rows={5}
                                            value={desc}
                                            onChange={(e) => setDesc(e.target.value)}
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px', paddingY: 5, paddingX: 3 }}>
                                <Grid alignItems="center" container spacing={3}>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography> Quantité Max par client</Typography>
                                        <Typography variant="caption">
                                            {' '}
                                            Limitez la quantité de tickets que peut acheter un client
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            type="number"
                                            value={quantitePerClient}
                                            onChange={(e) => setQuantitePerClient(e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px', paddingY: 5, paddingX: 3 }}>
                                <Grid alignItems="center" container spacing={3}>
                                    <Grid item xs={10}>
                                        <Typography variant='h4'> Mise en vente</Typography>
                                        <Typography variant="caption">Les participants pouront acheter ce billet :</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Stack direction='row' spacing={2}>
                                            <Box onClick={() => setAutoVente(false)} sx={!autoVente ? { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                                :
                                                { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingY={2} >
                                                <Typography variant='body1'>Maintenant</Typography>
                                            </Box>
                                            <Box onClick={() => setAutoVente(true)} sx={autoVente ? { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                                :
                                                { display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingY={2} >
                                                <Typography variant='body1'>Planifier</Typography>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                {autoVente ? (
                                    <Box sx={{ mt: 2, p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                                        <Alert severity="warning">
                                            Cet billet sera mise en vente {dateDebut && timeDebut && !dateFin && !timeFin ? 'a partir de' : 'du'}  {dateDebut && timeDebut ? format((new Date(dateDebut).setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0)), 'eee dd MMM yyyy HH:mm', { locale: fr }) : '-'}
                                            {dateFin && timeFin ? ` au ${format((new Date(dateFin).setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0)), 'eee dd MMM yyyy HH:mm', { locale: fr })}` : ''}
                                        </Alert>
                                        <Stack spacing={1} padding={3} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                                            <Box>
                                                <Typography>Début</Typography>
                                            </Box>
                                            <Stack spacing={1}>
                                                <CustomDateTimePicker date={dateDebut} time={timeDebut} onDateChange={handleDateDebutChange} onTimeChange={handleTimeDebutChange} />
                                            </Stack>
                                            <Box paddingTop={2}>
                                                <Typography>Fin</Typography>
                                            </Box>
                                            <Stack spacing={1}>
                                                <CustomDateTimePicker date={dateFin} time={timeFin} minDate={dateDebut || null} onDateChange={handleDateFinChange} onTimeChange={handleTimeFinChange} />
                                            </Stack>

                                        </Stack>

                                    </Box>
                                ) : null}
                            </Box>
                        </>
                    )}
                </Stack>
                {(!loading && isModified) && (
                    <Container>
                        <Stack
                            direction='row'
                            spacing={2}
                            sx={{
                                position: 'fixed',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: 'white',
                                padding: '16px',
                                // borderTop: '2px solid gainsboro',
                                boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
                                zIndex: 1000,
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'center',

                            }}
                        >
                            <CustomButton
                                type='secondaire'
                                buttonText='Ignorer'
                                onClick={() => window.history.back()}
                            />
                            <CustomButton
                                type='primaire'
                                buttonText='Enregister'
                                onClick={onSubmit}
                            />
                        </Stack>
                    </Container>

                )}

                <CustomSnack open={openSnack} handleClose={handleCloseSnack} msg={msg} />
            </Container>
        </Page>
    );
}
