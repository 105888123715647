import React from 'react';
import { Carousel } from 'antd';
import { Box, Button, Container, Grid, Link, Stack, Typography } from '@mui/material';
import Iconify from '../Iconify';
import logo from '../../assets/logo/logo2.png';
import androidBadge from '../../assets/badges/google-play-badge.png';
import iosBadge from '../../assets/badges/ios.svg';
// import {ReactComponent as iosBadge} from '../../assets/badges/ios.svg';

function AppFooter() {
  return (
    <div className="footer ">
      <div className="container-fluid">
        <Box sx={{ bgcolor: '#1F1F1F', color: 'white' }}>
          <Box width='100%' height={3} sx={{ background: "linear-gradient(0.25turn, #e66465, #9198e5)", }} />
          <Container sx={{ pt: 3 }}>
            <Grid container spacing={4}>
              {/* Section Logo et Description */}
              <Grid item xs={12} sm={6} md={3}>
                <Link href="/fr/" underline="none">
                  <img src={logo} alt="logo" style={{ width: '120px', marginBottom: '16px' }} />
                </Link>

              </Grid>

              {/* Section Liens Utiles */}
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle1" sx={{ color: 'white' }} gutterBottom>
                  Liens utiles
                </Typography>
                <Link href="https://support.choptonticket.com/fr/categories/legacy/articles/cgu" color="inherit" underline="hover"><Typography variant="body2">Conditions d'utilisation</Typography></Link>
                <Link href="https://support.choptonticket.com/fr/categories/legacy/articles/cgp" color="inherit" underline="hover"><Typography variant="body2">Politique de confidentialité</Typography></Link>
              </Grid>

              {/* Section Réseaux Sociaux */}
              <Grid item xs={12} sm={6} md={3}>
                <Typography sx={{ color: 'white' }} variant="subtitle1" gutterBottom>
                  Suivez-nous
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Link href="#" color="inherit">
                    <Iconify icon="akar-icons:facebook-fill" width={24} height={24} />
                  </Link>
                  <Link href="#" color="inherit">
                    <Iconify icon="akar-icons:instagram-fill" width={24} height={24} />
                  </Link>
                  <Link href="#" color="inherit">
                    <Iconify icon="akar-icons:twitter-fill" width={24} height={24} />
                  </Link>
                  <Link href="#" color="inherit">
                    <Iconify icon="akar-icons:linkedin-fill" width={24} height={24} />
                  </Link>
                </Box>
              </Grid>

              {/* Section Achat Sécurisé */}
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle1" sx={{ color: 'white' }} gutterBottom>
                  Achat Sécurisé
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Profitez de notre plateforme sécurisée pour acheter vos tickets en toute confiance.
                </Typography>

              </Grid>
            </Grid>
          </Container>
          <Box sx={{}}>
            <Container>
              {/* Section Support */}
              <Box sx={{ mt: 4 }}>
              <Typography variant="subtitle1" sx={{color:'white'}} gutterBottom>
                Support
              </Typography>
                <Typography variant="body2" >
                  Besoin d'aide ? Consultez notre page de support ou contactez-nous.
                </Typography>
                <Link href="https://support.choptonticket.com/" target="_blank" // pour ouvrir le lien dans un nouvel onglet
                  rel="noopener noreferrer" color="inherit" underline="hover"><Typography variant="body2">Page de support</Typography></Link>

                <Link
                  href="http://api.whatsapp.com/send?phone=2250709219343"
                  underline="hover"
                  target="_blank" // pour ouvrir le lien dans un nouvel onglet
                  rel="noopener noreferrer" color="inherit" // pour des raisons de sécurité
                >
                  <Typography variant="body2">Contactez-nous</Typography>
                </Link>
              </Box>
            </Container>
            {/* Droits d'Auteur */}
            <Box sx={{ textAlign: 'center', paddingY: 4 }}>
              <Typography variant="body2" color="white">
                © {new Date().getFullYear()} Choptonticket. Tous droits réservés.
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default AppFooter;
