/* eslint-disable no-nested-ternary */
/* eslint-disable object-shorthand */
import { LoadingButton } from "@mui/lab";
import { Button, styled } from "@mui/material";
import React from "react";

const CustomButton = ({
  backgroundColor,
  color,
  buttonText,
  heroBtn,
  guideBtn,
  getStartedBtn,
  type,
  startIcon,
  loading,
  ...buttonProps
}) => {
  const CustomButton = styled(LoadingButton)(({ theme }) => (type === 'primaire' ? {
    textTransform: 'initial',
    border: '2px solid transparent',
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#5E17EB',
    borderRadius: '7px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#A47CF4',
      color: 'white',
      borderColor: '#A47CF4',
    },

  } : type === 'secondaire' ? {
    textTransform: 'initial',
    border: '2px solid #141414',
    color: 'black',
    backgroundColor: 'transparent',
    borderRadius: '7px',
    paddingLeft: 20,
    paddingRight: 20,
    borderColor: '#141414',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#141414',
      borderColor: '#141414',
    },

  } : type === 'tertiaire' ? {
    textTransform: 'initial',
    border: '2px solid transparent',
    color: '#5E17EB',
    backgroundColor: 'transparent',
    borderRadius: '7px',
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#5E17EB',
      borderColor: 'transparent',
    }

  } : type === 'back' ? {
    textTransform: 'initial',
    border: '2px solid transparent',
    color: '#141414',
    backgroundColor: 'transparent',
    borderRadius: '7px',
    borderColor: 'transparent',
    paddingLeft: 20,
    paddingRight: 20,
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#141414',
      borderColor: 'transparent',
    },

  } : {
    textTransform: 'initial',
    border: '2px solid transparent',
    color: 'white',
    backgroundColor: '#5E17EB',
    borderRadius: '7px',
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'white',
      color: '#5E17EB',
      borderColor: '#5E17EB',
    },

  }));

  return <CustomButton {...buttonProps} startIcon={startIcon} loading={loading} loadingPosition="end">
    {buttonText}
  </CustomButton>;
};

export default CustomButton;