import { Image } from "@mui/icons-material";
import { Document, Page } from "@react-pdf/renderer";

import ItemsTable from "./component/itemsTable";
import Title from "./component/title";

const styles = ({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 84,
        height: 70,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});


const PdfDocument = ({ billetList, commandeList }) => (
        <Document>
            <Page size="A4" style={styles.page} >

                 <Title title={'LISTE DES PARTICIPANTS PAR ORDRE NUMERIQUE (N° BILLET)'} participants={billetList.length} />
                <ItemsTable billets={billetList} commandes={commandeList} />
                
            </Page>
        </Document>
    )

export default PdfDocument;