/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Row, Col } from 'antd';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import logo from '../../assets/logo/C.png';
import controle from '../../assets/images/scanner1.png';
// import dashbord from '../../assets/images/dashboard1.png';
import dashbord from '../../assets/images/dashpreview.png';
import Iconify from '../Iconify';
import CustomButton from '../CustomButton';

const items = [
  {
    key: '1',
    icon: <i className="fas fa-chart-pie" />,
    title: 'CTTManagerApp',
    content: 'Monitor ticket sales and complete attendee check-ins on the go with our easy-to-use mobile app.',
  },
  {
    key: '2',
    icon: <i className="fas fa-desktop" />,
    title: 'ChopTonTicket',
    content:
      'cu nostro dissentias consectetuer mel. Ut admodum conceptam mei, cu eam tation fabulas abhorreant. His ex mandamus.',
  },
  {
    key: '3',
    icon: <i className="fas fa-database" />,
    title: 'CTTScanner',
    content:
      'Contrôlez facilement les billets et droits de vos participants. Papier, application ou matériel professionnel, vous avez le choix !',
  },
];

function DashFeaure() {
  return (
    <div id="feature" className="block featureBlock ">
      <div className="container-fluid">
        <Grid container spacing={5} paddingY={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4">
              Créez et gerez vos événements en quelques minutes graces à
            </Typography>
            <Stack spacing={1} paddingY={2}>
              
              <Stack direction="row" alignItems="center">
                <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                <Typography fontSize={18}>Une interface conviviale pour une navigation intuitive.</Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                <Typography fontSize={18}>Des fonctionnalités de configuration rapides et accessibles.</Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                <Typography fontSize={18}>
                  Des options de personnalisation étendues pour adapter votre billetterie à vos besoins uniques.
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                <Typography fontSize={18}>
                  Une flexibilité totale dans la définition des prix, des remises éventuelles et des quantités
                  disponibles.
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ height: '100%', alignItems: 'center' }}>
            
              <Box sx={{ borderRadius: '7px' }}>
                <img height={`100%`} width={`100%`} src={dashbord} loading="lazy" />
              </Box>
            
          </Grid>
        </Grid>
        <Stack paddingTop={3} direction="row" spacing={2} justifyContent="center">

          <CustomButton size="large" buttonText='En savoir plus' type='secondaire' />
          <CustomButton size="large" buttonText='Démarer maintenant' type='primaire' href="/bo/new_event" />
        </Stack>
      </div>
    </div>
  );
}

export default DashFeaure;
