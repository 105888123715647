/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable import/order */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Backdrop,
    CircularProgress,
    Grid,
    Divider,
    LinearProgress,
    Tooltip,
    TextField,
    TableHead,
    IconButton,
    Switch,
    Box,
    Link,
    Skeleton,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, Timestamp, updateDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
// mock
import { db } from '../../../firebase.config';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { format, setDate } from 'date-fns';
import { fr, id } from 'date-fns/locale';
import { LoadingButton } from '@mui/lab';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { width } from '@mui/system';
import { shortNumber } from '../../../utils/formatNumber';
import CustomDateTimePicker from '../../../components/CustomDateTimePicker';
import CustomButton from '../../../components/CustomButton';

// ----------------------------------------------------------------------

export default function ShowSeance() {
    const { eventID } = useParams();
    const { seanceID } = useParams();
    const [modif, setModif] = useState(false);
    // loading
    const [loading, setLoading] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };
    // for change value of globla quota
    const [open, setOpen] = useState(false);
    const [refreh, setRefresh] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        // setModif(false);
        setRefresh((prev) => !prev);
    };

    // take tickets from firebase
    const [event, setEvent] = useState();
    const [seance, setSeance] = useState();
    const [tickets, setTickets] = useState([]);
    const [quotas, setQuotas] = useState([]);
    const [groupDate, setGroup] = useState([]);
    const [itemGroup, setItemGroup] = useState([]);
    const [active, setActive] = useState([]);
    const [activeJ, setActiveJ] = useState([]);
    const [activeI, setActiveI] = useState([]);
    const [count, setCount] = useState(0);
    const [statusB, setStatusB] = useState([]);
    const [modifQuota, setModifQuota] = useState(false);
    const [modifInfo, setModifInfo] = useState(false);


    const handleTime = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.');
        return tmp[0];
    };
    const handleTimestamp = (e) => {
        console.log('handle time stamp')
        const date = e.toDate();
        return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
    };

    const handleTimestampCustom = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.')[0].split('T')[1].split(':');
        return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
    };

    const handleTimestamp1 = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.');
        const t = tmp[0].split('T');
        return t[1];
    };

    // update status séance

    const updateState = async () => {
        const tmpBillets = seance.data().billets;
        try {
            handleToggle();
            const statRef = doc(db, `evenement/${eventID}/seances`, `${seance.id}`);
            if (seance.data().status === 1) {
                await updateDoc(statRef, {
                    status: parseInt(0),
                });
            } else {
                await updateDoc(statRef, {
                    status: parseInt(1),
                });
            }

            handleClose();
        } catch (error) {
            setRefresh(!refreh);
            handleClose();
            console.log(error.message);
        }

        console.log(tmpBillets);
    };

    // update des infos de la seance
    const updateInfos = async () => {
        try {
            handleToggle();
            console.log(debut)
            const tmpDateDebut = new Date(dateDebut);
            // Utiliser setHours pour remplacer l'heure et les minutes
            tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
            // enregistrement des modifications
            const statRef = doc(db, `evenement/${eventID}/seances`, `${seance.id}`);
            await updateDoc(statRef, {
                name: nom,
                date: Timestamp.fromDate(tmpDateDebut),
            });

            // mis à jour de date de fin et debut
            const eventRef = doc(db, `evenement`, `${eventID}`);
            if (new Date(handleTimestamp(event.dateDebut)) > tmpDateDebut) {
                console.log('ok')
                await updateDoc(eventRef, {
                    dateDebut: Timestamp.fromDate(tmpDateDebut),
                });
            } else if (new Date(handleTimestamp(event.dateFin)) < tmpDateDebut) {
                await updateDoc(eventRef, {
                    dateFin: Timestamp.fromDate(tmpDateDebut),
                });
            }

            setModifInfo(false)
            handleClose();
        } catch (error) {
            handleClose();
            console.log(error);
        }
    };

    // update de billets de la seance
    const update = async () => {
        let tmpBillets = []
        console.log(statusB)
        if (seance.data().billets) {
            //
            tmpBillets = seance.data().billets;
            for (let index = 0; index < statusB.length; index++) {
                if (statusB[index]) {
                    const trouve = tmpBillets?.findIndex(({ id }) => id === tickets[index].id);
                    if (trouve !== -1) {
                        tmpBillets?.splice(trouve, 1, {
                            id: tmpBillets[trouve].id,
                            quota: parseInt(quotas[index]),
                            sell: tmpBillets[trouve].sell,
                            status: parseInt(1),
                        });
                    } else {
                        tmpBillets.push({ id: tickets[index].id, quota: parseInt(0), sell: parseInt(0), status: parseInt(1) });
                    }
                }
                if (!statusB[index]) {
                    const trouve = tmpBillets?.findIndex(({ id }) => id === tickets[index].id);
                    if (trouve !== -1) {
                        tmpBillets?.splice(trouve, 1, {
                            id: tmpBillets[trouve].id,
                            quota: parseInt(quotas[index]),
                            sell: tmpBillets[trouve].sell,
                            status: parseInt(0),
                        });
                    }
                }
            }
        } else {
            for (let index = 0; index < statusB.length; index++) {
                if (statusB[index]) {

                    tmpBillets.push({ id: tickets[index].id, quota: parseInt(quotas[index]), sell: parseInt(0), status: parseInt(1) });

                }
                else if (!statusB[index]) {
                    tmpBillets.push({ id: tickets[index].id, quota: parseInt(quotas[index]), sell: parseInt(0), status: parseInt(0) });
                }
            }
        }


        try {
            handleToggle();
            const statRef = doc(db, `evenement/${eventID}/seances`, `${seance.id}`);
            await updateDoc(statRef, {
                billets: tmpBillets,
            });
            setModifQuota(false)
            handleClose();
        } catch (error) {
            handleClose();
            console.log(error.message);
        }

        console.log(tmpBillets);
    };
    const navigate = useNavigate();

    // recuperation des dates
    useEffect(() => {
        (async () => {
            // setTickets([]);
            try {
                setLoading(true);
                // event
                const docRef0 = doc(db, 'evenement', eventID);
                const docSnap = await getDoc(docRef0);
                if (docSnap.exists()) {
                    setEvent(docSnap.data());
                }
                // date info
                const dateRef = doc(db, `evenement/${eventID}/seances`, `${seanceID}`);
                const snapDate = await getDoc(dateRef);
                if (snapDate.exists()) {
                    setSeance(snapDate);
                }

                // recuperation des tickets
                const q1 = query(collection(db, `evenement/${eventID}/tickets`));
                const snapshot1 = await getDocs(q1);
                const tmpStatus = [];
                const tmpQuota = [];
                let tmpCount = 0;
                snapshot1.forEach((d) => {
                    setTickets((tickets) => [...tickets, d]);


                    console.log('ticket')
                    console.log(d.id)
                    // console.log(snapDate.data()?.billets)
                    if (snapDate.data()?.billets?.find(({ id }) => id === d.id)) {
                        tmpQuota.push(
                            snapDate.data().billets[
                                snapDate.data().billets.findIndex(({ id }) => id === d.id)
                            ].quota
                        );
                        if (
                            snapDate.data().billets[
                                snapDate.data().billets.findIndex(({ id }) => id === d.id)
                            ].status === 1
                        ) {
                            tmpStatus.push(true);
                            tmpCount += 1;
                        } else {
                            tmpStatus.push(0);
                        }
                        console.log('trouvé')
                    } else {
                        console.log('pas trouvé')
                        tmpQuota.push(0);
                        tmpStatus.push(false);
                    }

                });
                setDateDebut(handleTimestamp(snapDate.data().date))
                setTimeDebut(handleTimestampCustom(snapDate.data().date))
                setDebut(handleTime(snapDate.data().date));
                setNom(snapDate.data().name)
                setLieu(snapDate.data().lieu_event);
                setVille(snapDate.data().city);
                setPays(snapDate.data().country);
                setCount(tmpCount);
                setQuotas(tmpQuota);
                setStatusB(tmpStatus);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [eventID, seanceID]);

    useEffect(() => {
        (async () => {
            try {
                await update()
            } catch (error) {
                console.log(error);
            }
        })();
    }, [statusB]);



    // supression d'un billet
    const handleSupp = async () => {
        handleToggle();
        console.log('supp');
        if (seance.data().participants === 0) {
            try {
                await deleteDoc(doc(db, `evenement/${eventID}/seances`, `${seance.id}`));
                navigate(-1)
            } catch (error) {
                console.log(error.message);
                handleClose();
            }
        }
        handleClose();
    };
    const [nom, setNom] = useState('');
    const [debut, setDebut] = useState('');
    const addDebut = (e) => {
        const today = new Date();
        const tmp = new Date(e);
        // const tmpF = new Date(fin);
        console.log(today);
        console.log(tmp);
        if (tmp <= today) {
            // setDebutError(true);
            // setDebutErrorMsg("La valeur de champ n'est pas valide");
        }
        setDebut(e);
    };

    // handle location
    const searchOptions = {
        componentRestrictions: { country: 'CIV' },
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['establishment'],
    };
    const [address, setAddress] = useState('');
    const [lieuError, setLieuError] = useState(false);
    const [lieu, setLieu] = useState('');
    const [ville, setVille] = useState('');
    const [pays, setPays] = useState('');
    const [location, setLocation] = useState('');
    const handleSelect = async (address, placeId, suggestion) => {
        console.log(suggestion.description);
        const tmp = suggestion.description.split(',');
        setLieu(tmp[0]);
        setVille(tmp[tmp.length - 2]);
        setPays(tmp[tmp.length - 1]);
        setAddress(suggestion.description);
        const results = await geocodeByAddress(address);
        const ll = await getLatLng(results[0]);
        setLocation(ll);
    };

    // date / time
    const [dateError, setDateError] = useState(false);
    const [dateErrorMsg, setDateErrorMsg] = useState('');
    const [dateDebut, setDateDebut] = useState(null);
    const [timeDebut, setTimeDebut] = useState('');


    const handleDateDebutChange = (newDate) => {
        setDateDebut(newDate);
    };

    const handleTimeDebutChange = (newTime) => {
        console.log('parent')
        setTimeDebut(newTime);
    };

    return (
        <Page title="Seance">
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {loading && <LinearProgress />}
            {
                seance ?
                    <Container>
                        <Stack spacing={3}>
                            <Stack direction="row" justifyContent="space-between" mb={2}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        textTransform: 'initial',
                                        border: '2px solid transparent',
                                        color: 'black',
                                        backgroundColor: 'white',
                                        borderRadius: '7px',
                                        borderColor: 'black',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: 'black',
                                            color: 'white',
                                            borderColor: 'black',
                                        },
                                    }}
                                    startIcon={<Iconify icon="material-symbols:arrow-back-ios" />}
                                    onClick={() => navigate(-1)}
                                >
                                    retour
                                </Button>
                                <Stack direction="row" spacing={1}>
                                    <LoadingButton
                                        sx={{
                                            textTransform: 'initial',
                                            border: '2px solid transparent',
                                            color: 'white',
                                            backgroundColor: 'red',
                                            borderRadius: '7px',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                backgroundColor: 'white',
                                                color: 'red',
                                                borderColor: 'red',
                                            },
                                        }}
                                        disabled={seance?.data().participants === 0 ? false : true}
                                        onClick={handleSupp}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        Supprimer
                                    </LoadingButton>
                                    <LoadingButton
                                        sx={{
                                            textTransform: 'initial',
                                            border: '2px solid transparent',
                                            color: 'white',
                                            backgroundColor: '#5E17EB',
                                            borderRadius: '7px',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                backgroundColor: 'white',
                                                color: '#5E17EB',
                                                borderColor: '#5E17EB',
                                            },
                                        }}
                                        onClick={updateState}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {seance.data().status === 1
                                            ? 'Desactiver la seance'
                                            : 'Activer la séance'}
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                            {
                                !modifInfo ?
                                    <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px', padding: 3 }}>
                                        <Stack direction='column'>
                                            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                                <Typography variant="h5" gutterBottom>
                                                    {format(new Date(handleTimestamp(seance.data().date)), 'EEEE dd MMMM yyyy', { locale: fr })
                                                        .toString()
                                                        .toLocaleUpperCase()}{' '}
                                                    - {handleTimestampCustom(seance.data().date)}
                                                </Typography>
                                                <CustomButton type='secondaire' buttonText='Modifier' loading={loading} onClick={() => setModifInfo(true)} />
                                            </Stack>
                                            <Typography><strong>{nom != null ? nom : '-'}</strong></Typography>
                                        </Stack>
                                    </Box>

                                    :
                                    <>
                                        <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px', padding: 3 }}>
                                            <Grid alignItems="center" container paddingY={1}>
                                                <Grid item xs={12} sm={3}>
                                                    <Typography>Nom de la seance</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <TextField
                                                        id="nom-seance"
                                                        type="text"
                                                        value={nom}
                                                        onChange={(e) => setNom(e.target.value)}
                                                        fullWidth
                                                        helperText='ce champ est optionnel'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid alignItems="center" container paddingY={1}>
                                                <Grid item xs={12}>
                                                    <Stack spacing={1} padding={3} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px', border: `${dateError ? '1px solid red' : null}` }}>
                                                        <Box>
                                                            <Typography>Date de début</Typography>
                                                        </Box>
                                                        <Stack spacing={1}>
                                                            <CustomDateTimePicker date={dateDebut} time={timeDebut} onDateChange={handleDateDebutChange} onTimeChange={handleTimeDebutChange} />
                                                        </Stack>
                                                    </Stack>
                                                </Grid>

                                            </Grid>
                                            <Stack direction='row' justifyContent='end' spacing={2}>
                                                <CustomButton type='secondaire' buttonText='Annuler' loading={loading} onClick={() => setModifInfo(false)} />
                                                <CustomButton type='primaire' onClick={updateInfos} loading={loading} buttonText='Enregistrer' />
                                            </Stack>
                                        </Box>
                                    </>

                            }

                            <Grid alignItems="center" container columnSpacing={2}>
                                <Grid item xs={6} sm={6} md={4}>
                                    <Card sx={{
                                        padding: 2, height: '100%', width: '100%', color: (theme) => theme.palette.primary.darker,
                                        bgcolor: (theme) => theme.palette.primary.lighter,
                                    }}>
                                        <Stack spacing={2}>
                                            <Typography variant='body1'><strong>Revenues nettes</strong></Typography>
                                            {
                                                loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h4'>{shortNumber(seance.data().recette)} CFA</Typography>
                                            }
                                        </Stack>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={6} md={4}>
                                    <Card sx={{
                                        padding: 2, height: '100%', width: '100%', color: (theme) => theme.palette.primary.darker,
                                        bgcolor: (theme) => theme.palette.warning.lighter,
                                    }}>
                                        <Stack spacing={2}>
                                            <Typography variant='body1'><strong>Tickets vendus</strong></Typography>
                                            {
                                                loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h4'>{shortNumber(seance.data().participants)}</Typography>
                                            }
                                        </Stack>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} sm={6} md={4}>
                                    <Card sx={{
                                        padding: 2, height: '100%', width: '100%', color: (theme) => theme.palette.primary.darker,
                                        bgcolor: (theme) => theme.palette.info.lighter,
                                    }}>
                                        <Stack spacing={2}>
                                            <Typography variant='body1'><strong>Billets actifs</strong></Typography>
                                            {
                                                loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h4'>{count} / {tickets.length}</Typography>
                                            }

                                        </Stack>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Box>
                                <Stack alignItems='end' paddingY={1}>
                                    {
                                        !modifQuota ? <CustomButton type='tertiaire' buttonText='Editer les quotas' onClick={() => {
                                            setModifQuota(true);
                                        }} /> :
                                            <Stack direction='row' spacing={1}>
                                                <CustomButton type='secondaire' buttonText='Annuler' onClick={() => {
                                                    setModifQuota(false);
                                                }} />
                                                <CustomButton type='primaire' buttonText='Enregistrer' onClick={update} loading={loading} />
                                            </Stack>
                                    }
                                </Stack>
                                <Box>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 500 }} aria-label="caption table">
                                            <TableHead sx={{ bgcolor: '#f2f2f2' }}>
                                                <TableRow>
                                                    <TableCell>Billets</TableCell>
                                                    <TableCell align="right">Montant</TableCell>
                                                    <TableCell align="right">Ventes</TableCell>
                                                    <TableCell align="right">Quotas</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tickets.map((ticket, index) => (
                                                    <TableRow key={index} hover sx={{ cursor: 'pointer' }}>
                                                        <TableCell component="th" scope="row">
                                                            {ticket.data().ticket_name}
                                                        </TableCell>
                                                        <TableCell align="right">{ticket.data().price} CFA</TableCell>
                                                        <TableCell align="right">
                                                            {seance?.data()?.billets?.find(({ id }) => id === ticket.id) ? (
                                                                <>
                                                                    {seance.data().billets[
                                                                        seance.data().billets.findIndex(({ id }) => id === ticket.id)
                                                                    ].sell ? (
                                                                        seance.data().billets[
                                                                            seance.data().billets.findIndex(({ id }) => id === ticket.id)
                                                                        ].sell
                                                                    ) : (
                                                                        <div>-</div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <div>-</div>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {quotas[index] !== 0 && !modifQuota ? (
                                                                quotas[index]
                                                            ) : quotas[index] === 0 && !modifQuota ? (
                                                                <>-</>
                                                            ) : (
                                                                <TextField
                                                                    size="small"
                                                                    value={quotas[index]}
                                                                    onChange={(e) => {
                                                                        setQuotas((existItem) => [
                                                                            ...existItem.slice(0, index),
                                                                            e.target.value,
                                                                            ...existItem.slice(index + 1),
                                                                        ]);
                                                                    }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip title="Activer le ticket">
                                                                <Switch
                                                                    checked={statusB[index]}
                                                                    size="small"
                                                                    key={index}
                                                                    onChange={(e) => {
                                                                        console.log(e.target.checked);
                                                                        setStatusB((existItem) => {
                                                                            if (statusB[index]) {
                                                                                let tmp = count;
                                                                                tmp -= 1;
                                                                                setCount(tmp);
                                                                            } else {
                                                                                let tmp = count;
                                                                                tmp += 1;
                                                                                setCount(tmp);
                                                                            }
                                                                            return [
                                                                                ...existItem.slice(0, index),
                                                                                !statusB[index],
                                                                                ...existItem.slice(index + 1),
                                                                            ];
                                                                        });
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Stack>
                    </Container>
                    : null
            }

        </Page>
    );
}
