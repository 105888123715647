// component
import { useParams } from 'react-router-dom';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;



const navConfigOrg = [
  {
    title: 'Information',
    path: '/organisation/app',
    icon: getIcon('octicon:organization-24'),
  },
  {
    title: 'CTT-Doors',
    path: '/organisation/door',
    icon: getIcon('vaadin:qrcode'),
  },
  {
    title: 'ESPACE de travail',
    path: '',
    icon: getIcon(''),
  },
  {
    title: 'Membres de l\'équipe',
    path: '/organisation/coordonnee_reversement',
    icon: getIcon('mdi:account-payment'),
  },
  
  {
    title: 'Parrainage',
    path: '/organisation/ctt_patners',
    icon: getIcon('octicon:organization-24'),
  },
  {
    title: 'Plans',
    path: '/organisation/ctt_patners',
    icon: getIcon('octicon:organization-24'),
  },
  
  
  
];

export default navConfigOrg;
