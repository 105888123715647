/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Paper,
  MenuList,
  MenuItem,
  Button,
  Link,
  Modal,
  Divider,
  Grid,
  TextField,
  Select,
  Snackbar,
  Alert,
  Tooltip,
  Menu,
  Skeleton,
  Avatar,
  CircularProgress,
} from '@mui/material';
// components
import { useState, useEffect } from 'react';
import { collection, doc, getCountFromServer, getDoc, query, updateDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { parseInt } from 'lodash';
import { LoadingButton } from '@mui/lab';
import useResponsive from '../../hooks/useResponsive';
import { db } from '../../firebase.config';
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import { Variables } from '../../context/VariableContext';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: 'white',
  borderBottom:'1px solid gainsboro',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: `90%`, sm: 500 },
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '7px',
  p: 4,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 7,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function DashboardNavbar({ onOpenSidebar }) {
  const [active, setActive] = useState();
  const { eventID } = useParams();
  // modal de duplication
  const [openDupplicate, setOpenDupplicate] = useState(false);
  const [openClos, setOpenClos] = useState(false);
  const [open, setOpen] = useState(false);
  const [montant, setMontant] = useState();
  const [demande, setDemande] = useState(0);
  const [event, setEvent] = useState();
  const [tmp, setTmp] = useState(0);
  const [load, setLoad] = useState(false);
  const [date, setDate] = useState('');
  const [ticketCount, setTicketCount] = useState(0);
  const navigate = useNavigate();
  const {generalReload, setGeneralReload} = Variables()

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
    if (e === 0) {
      setOpenDupplicate(true);
    } else if (e === 2) {
      setOpenClos(true);
    }
  };

  const handleOpenDupplicate = (id) => {
    setActive(id);
    setOpenDupplicate(true);
  };

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[0];
  };

  // recuperation des données
  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = eventID;
        let e;
        const docRef0 = doc(db, 'evenement', id);
        // setLoading(true);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          e = docSnap.data();
          setEvent(e);
          setTmp(1);
          setDate(handleTimestamp(e.dateDebut));
        }
        // count
        const snapshot = await getCountFromServer(query(collection(db, `evenement/${eventID}/tickets`)));
        setTicketCount(snapshot.data().count);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData().catch(console.error);
  }, [load, generalReload]);
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const xlUp = useResponsive('up', 'xl');

  const handleCloseDupplicate = () => {
    setOpenDupplicate(false);
    setResult();
  };
  const handleCloseClos = () => setOpenClos(false);
  //
  const handleChangement = (stat) => {
    console.log(stat);
    try {
      if (stat === 0) {
        handleBrouillon();
      } else if (stat === 1) {
        goLive();
      } else if (stat === 2) {
        handleClos();
      } else if (stat === 3) {
        handleSupp();
      }
    } catch (error) {
      setMsg('Une erreur est survenue ');
      setSeverity('error');
      setOpenSnack(true);
      console.log(error);
    }
    setSeverity('success');
    setMsg('ok');
    setOpenSnack(true);
  };
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [resultDetail, setResultDetail] = useState();
  const handleBrouillon = async () => {
    console.log('repasser en mode brouillon');
    try {
      setLoading(true);
      const refEvent = doc(db, `evenement`, eventID);
      const docSnap = await getDoc(refEvent);
      if (docSnap.exists()) {
        await updateDoc(refEvent, {
          status: parseInt(0),
        });
      }
      setResult('done');
      setResultDetail('vous êtes repasser en mode Brouillon');
      setLoading(false);
      setLoad(!load);
    } catch (error) {
      setResult('error');
      setLoading(false);
      setLoad(!load);
      console.log(error);
    }
  };
  const goLive = async () => {
    console.log('go live');
    navigate(`/${eventID}/publish`, { replace: true });
  };

  const handleClos = async () => {
    console.log('cette action est ireversible');
    setLoading(true);
    try {
      const refEvent = doc(db, `evenement`, eventID);
      const docSnap = await getDoc(refEvent);
      if (docSnap.exists()) {
        await updateDoc(refEvent, {
          status: parseInt(2),
        });
      }
      setResult('done');
      setLoading(false);
      setLoad(!load);
      navigate(`/bo`, { replace: true });
    } catch (error) {
      setResult('error');
      setLoading(false);
      setLoad(!load);
      console.log(error);
    }
  };

  const handleSupp = () => {
    console.log('cette action est ireversible');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };
  const [openSnack, setOpenSnack] = useState(false);
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('');

  return (
    <RootStyle>
      <Modal
        open={openDupplicate}
        onClose={handleCloseDupplicate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Repasser en mode brouillon
          </Typography>
          <Divider />
          {!result ? (
            <>
              <Typography id="modal-modal-description" sx={{ my: 2 }}>
                Une fois votre évenement en mode brouillon, il ne sera plus visible au public. voulez vous continuer ?
              </Typography>
              <Grid justifyContent="flex-end" container spacing={1}>
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: 'black',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                        borderColor: 'black',
                      },
                    }}
                  >
                    Annuler
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    onClick={handleBrouillon}
                    variant="outlined"
                    loading={loading}
                    loadingPosition="end"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                  >
                    Repasser en mode brouillon
                  </LoadingButton>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Typography id="modal-modal-description" sx={{ my: 2 }}>
                {resultDetail}
              </Typography>
              <Grid justifyContent="flex-end" container spacing={1}>
                <Grid item>
                  <Button
                    onClick={handleCloseDupplicate}
                    variant="outlined"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                  >
                    Continuer
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={openClos}
        onClose={handleCloseClos}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Clore cet évenement
          </Typography>
          <Divider />
          {
            <>
              <Typography id="modal-modal-description" sx={{ my: 2 }}>
                Si votre événement est terminé et que la billetterie est arrêtée, vous pouvez clore cet événement.
              </Typography>

              <Typography id="modal-modal-description" sx={{ my: 2 }}>
                À la suite de la clôture d’un événement : Vous pourrez télécharger la reddition de compte définitive
                depuis la page “Comptabilité” de votre compte Choptonticket Vous ne pourrez plus effectuer de ventes en
                ligne ou en guichet pour cet événement Vous ne pourrez plus faire de demande de remboursement depuis la
                gestion des participants (les demandes en cours seront toujours valides)
              </Typography>

              <Typography color="red" id="modal-modal-description" sx={{ my: 2 }}>
                Attention : Cette action est ireversible.
              </Typography>
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
                  <CircularProgress />
                </Box>
              )}
              <Grid justifyContent="flex-end" container spacing={1}>
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: 'black',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                        borderColor: 'black',
                      },
                    }}
                    onClick={() => setOpenClos(false)}
                  >
                    Annuler
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    // eslint-disable-next-line no-unneeded-ternary
                    disabled={loading ? true : false}
                    onClick={handleClos}
                  >
                    Clore cet événement
                  </Button>
                </Grid>
              </Grid>
            </>
          }
        </Box>
      </Modal>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          {/* !event ? (
            <Skeleton sx={{ width: { xs: 60, sm: 300 } }} />
          ) : (
            <Stack direction="row" alignItems="center" spacing={1} width={{ xs: 60, sm: 300 }}>
              <Avatar alt={event.nomEvent} src={event.idVisuel} />
              {smUp && (
                <Typography noWrap fontSize={18} fontWeight={500} color="#304273">
                  {event.nomEvent}
                </Typography>
              )}
            </Stack>
          ) */}
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ sm: 1.5 }}>
          {!mdUp ? null : (
            <>
              <Link
                href="/bo/"
                underline="hover"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  '&:hover': {
                    color: '#5E17EB',
                  },
                }}
              >
                <Stack direction="row" textAlign="center" spacing={0.5}>
                  <Iconify icon="mdi-light:calendar" sx={{ minHeight: 22, minWidth: 22 }} />
                  <Typography noWrap>Mes événements</Typography>
                </Stack>
              </Link>
              <Link
                href="/organisation/app"
                underline="hover"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  '&:hover': {
                    color: '#5E17EB',
                  },
                }}
              >
                <Stack direction="row" textAlign="center" spacing={0.5}>
                  <Iconify icon="uil:setting" sx={{ height: 22, width: 22 }} />
                  <Typography noWrap>Organisation</Typography>
                </Stack>
              </Link>
              <Link
                href="/finance"
                underline="hover"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  '&:hover': {
                    color: '#5E17EB',
                  },
                }}
              >
                <Stack direction="row" textAlign="center" spacing={0.5}>
                  <Iconify icon="carbon:finance" sx={{ height: 21, width: 21 }} />
                  <Typography noWrap>Finance</Typography>
                </Stack>
              </Link>

              <Link
                href="/marketing"
                underline="hover"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  '&:hover': {
                    color: '#5E17EB',
                  },
                }}
              >
                <Stack direction="row" textAlign="center" spacing={0.5}>
                  <Iconify icon="nimbus:marketing" sx={{ height: 22, width: 22 }} />
                  <Typography>Marketing</Typography>
                </Stack>
              </Link>
              <Link
                href="/marketing"
                underline="hover"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  '&:hover': {
                    color: '#5E17EB',
                  },
                }}
              >
                <Stack direction="row" textAlign="center" spacing={0.5}>
                  <Iconify icon="ion:analytics-outline" sx={{ height: 22, width: 22 }} />
                  <Typography>Analyse</Typography>
                </Stack>
              </Link>
            </>
          )}

          {/* <NotificationsPopover /> 
          {event && (
            <Select
              onChange={(e) => {
                handleChangement(e.target.value);
              }}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              open={open}
              value={event.status}
              sx={{ borderRadius: '7px' }}
            >
              <MenuItem value={1}>{event.status === 1 ? <>Publier</> : <>Publier cet évenement</>}</MenuItem>
              <MenuItem value={0}>{event.status === 0 ? <>Brouillon</> : <>Repasser en brouillon</>}</MenuItem>
              <MenuItem value={2}>{event.status === 2 ? <>Clos</> : <>Clore cet évenement</>}</MenuItem>
              <MenuItem disabled value={3}>
                {event.status === 3 ? <>Publier</> : <>Supprimer cet évenement</>}
              </MenuItem>
            </Select>
          )} */}
          {event && (
            <>
              {event.status === 0 ? (
                <Button
                  onClick={goLive}
                  disabled={(event.idVisuel === '' || ticketCount === 0) ? true : false}
                  variant="outlined"
                  // size={smUp ? 'large' : 'small'}
                  size='large'
                  sx={ (event.idVisuel === '' || ticketCount === 0) ? {
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#f2f2f2',
                    borderRadius: '7px',
                  } :  {
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#5E17EB',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: '#5E17EB',
                    },
                  }}
                >
                  <Typography variant={smUp ? 'body2' : 'caption'}>Publier l'évenement</Typography>
                </Button>
              ) : event.status === 1 ? (
                <>
                  <Button
                    onClick={handleClick}
                    aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    variant="outlined"
                    disableElevation
                    startIcon={
                      <Iconify icon="bi:dot" sx={{ width: 30, height: 30, alignItems: 'center', color: 'green' }} />
                    }
                    endIcon={<Iconify icon="mdi:menu-down" sx={{ width: 25, height: 25, alignItems: 'center' }} />}
                    sx={{
                      color: 'black',
                      backgroundColor: 'white',
                      border: '1.5px solid black',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1.5px solid black',
                      },
                    }}
                  >
                    {event.status === 1 ? (
                      <>Publier</>
                    ) : event.status === 0 ? (
                      <>Publier l'évenement</>
                    ) : event.status === 2 ? (
                      <>Clos</>
                    ) : null}
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                  >
                    {event.status !== 1 && (
                      <MenuItem onClick={handleCloseMenu} disableRipple value={1}>
                        Publier cet évenement
                      </MenuItem>
                    )}
                    {event.status !== 0 && (
                      <MenuItem
                        onClick={(e) => {
                          handleCloseMenu(e.target.value);
                        }}
                        disableRipple
                        value={0}
                      >
                        Repasser en brouillon
                      </MenuItem>
                    )}
                    {event.status !== 2 && (
                      <MenuItem
                        onClick={(e) => {
                          handleCloseMenu(e.target.value);
                        }}
                        disableRipple
                        value={2}
                      >
                        Clore cet événement
                      </MenuItem>
                    )}
                  </StyledMenu>
                </>
              ) : (
                <Button
                  onClick={handleClick}
                  aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  variant="outlined"
                  disableElevation
                  disabled
                  startIcon={
                    <Iconify icon="bi:dot" sx={{ width: 30, height: 30, alignItems: 'center', color: 'orange' }} />
                  }
                  endIcon={<Iconify icon="mdi:menu-down" sx={{ width: 25, height: 25, alignItems: 'center' }} />}
                  sx={{
                    color: 'black',
                    backgroundColor: 'white',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: 'black',
                      border: '2px solid black',
                    },
                  }}
                >
                  {event.status === 1 ? (
                    <>Publier</>
                  ) : event.status === 0 ? (
                    <>Publier l'évenement</>
                  ) : event.status === 2 ? (
                    <>Clos</>
                  ) : null}
                </Button>
              )}
            </>
          )}
         
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
      <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={`${severity}`} sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </RootStyle>
  );
}
