/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Radio,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import { Container } from '@mui/system';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  Timestamp,
  setDoc,
  serverTimestamp,
  addDoc,
  getCountFromServer,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import { db } from '../../firebase.config';

import { UserAuth } from '../../context/AuthContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import CheckOrg from '../../components/CheckOrg';
import NumberTextField from '../../components/NumberTextField';
import CustomButton from '../../components/CustomButton';
import CustomSnack from '../../components/customSnack';
import CustomNumber from '../../components/customNumber';
import SecurityPass from '../finance/component/securityPass';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingX: 1, paddingY: 5, minHeight: 400 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const params = useParams;

// ----------------------------------------------------------------------

export default function Coordonnees() {
  const { user } = UserAuth();
  const [refresh, setRefresh] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState(0);
  const [infoType, setInfoType] = React.useState(0);
  const [opMobile, setOpMobile] = React.useState();
  const [opMobileError, setOpMobileError] = React.useState(false);
  const [moyenVir, setMoyenVir] = React.useState([]);
  const [isPaie, setIsPaie] = React.useState(false);
  // loading
  const [loading, setLoading] = React.useState(false);

  const [edit, setEdit] = React.useState(false);

  // owner info
  const [name, setName] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [firstname, setFirstname] = React.useState('');
  const [city, setCity] = React.useState('');
  const [country, setCountry] = React.useState('');

  const handleFirstname = (e) => {
    setFirstname(e)
    setEdit(true)
  }
  const handleLastname = (e) => {
    setLastname(e)
    setEdit(true)
  }
  const handleCity = (e) => {
    setCity(e)
    setEdit(true)
  }
  const handleCountry = (e) => {
    setCountry(e)
    setEdit(true)
  }

  const handleName = (e) => {
    setName(e)
    setEdit(true)
  }

  // bank info

  // mobile account info

  // Field bancaire
  const [nom, setNom] = React.useState('');
  const [nomError, setNomError] = React.useState(false);
  const [numBank, setBank] = React.useState('');
  const [numBankError, setBankError] = React.useState(false);
  const [codeSwift, setSwift] = React.useState('');
  const [codeSwiftError, setSwiftError] = React.useState(false);
  const [id, setId] = React.useState();

  const handleNom = (e) => {
    setNom(e)
    setEdit(true)
  }
  const handleNumBank = (e) => {
    setBank(e)
    setEdit(true)
  }
  const handleSwift = (e) => {
    setSwift(e)
    setEdit(true)
  }

  // field Mobile

  const [num, setNum] = React.useState('');
  const [numError, setNumError] = React.useState(false);
  const handleNum = (e) => {
    setNum(e)
    setEdit(true)
  }

  const update = async (e) => {
    setLoading(true);
    console.log(e);

    try {
      const refDesc = doc(db, `organisations/${id}/infoPaie`, `${moyenVir[activeModif].id}`);

      if (e === 0) {
        await updateDoc(refDesc, {
          name: nom,
          iban: numBank,
          swift: codeSwift,
          updateDate: serverTimestamp(),
        });
      } else if (e === 1) {
        await updateDoc(refDesc, {
          name: nom,
          op: opMobile,
          mobile: num,
          updateDate: serverTimestamp(),
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addVir = async () => {
    setLoading(true);
    let tmpError = false;
    if (nom === '') {
      setNomError(true);
    }
    if (numBank === '' && type === 0) {
      setBankError(true);
      tmpError = true;
    }
    if (codeSwift === '' && type === 0) {
      setSwiftError(true);
      tmpError = true;
    }
    if (opMobile === null && type === 1) {
      setOpMobileError(true);
      tmpError = true;
    }
    if (num === '' && type === 1) {
      setNumError(true);
      tmpError = true;
    }
    if (tmpError) {
      setLoading(loading)
      return
    }
    try {
      if (!tmpError) {
        const paieRef = doc(db, `organisations/${id}/infoPaie`, `${id}`);
        const docSnap = await getDoc(paieRef);
        if (docSnap.exists()) {
          await updateDoc(doc(db, `organisations/${id}/infoPaie`, `${id}`), {
            owner: infoType === 0 ? { ownerType: infoType, lastname, firstname, city, country } : { ownerType: infoType, name, city, country },
            account: type === 0 ? { type, numBank, codeSwift, nom } : { type, opMobile, num },
            updateDate: serverTimestamp()
          });
        }
        else {
          await setDoc(doc(db, `organisations/${id}/infoPaie`, `${id}`), {
            owner: infoType === 0 ? { ownerType: infoType, lastname, firstname, city, country } : { ownerType: infoType, name, city, country },
            account: type === 0 ? { type, numBank, codeSwift, nom } : { type, opMobile, num },
            createDate: serverTimestamp()
          });
        }
      }

      setLoading(false);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // pin
  const [pin, setPin] = React.useState(['', '', '', '']);
  const [isPin, setIsPin] = React.useState(false);
  const [pinError, setPinError] = React.useState(0);
  const [verifiedPin, setVerifiedPin] = React.useState(false);
  const [isVerified, setIsVerified] = React.useState(false);

  const handlePinChange = (index, value) => {
    const newPin = [...pin];
    if (/^\d$/.test(value)) {
      newPin[index] = value;
    }

    if (newPin[index] !== undefined && newPin[index] !== null) {
      newPin[index] = value.charAt(value.length - 1);
    }
    if (index > 0 && value === '') {
      // If a digit is deleted, move focus to the previous input
      document.getElementById(`pin-input-${index - 1}`).focus();
    } else if (index < 3 && value !== '') {
      // Move focus to the next input
      document.getElementById(`pin-input-${index + 1}`).focus();
    }

    setPin(newPin);

    // Check if all 4 digits are entered
    if (newPin.every((digit) => digit !== '')) {
      handleSavePin(newPin.join(''));
    }
  };

  const handleSavePin = async (e) => {
    setLoading(true);
    console.log(e);

    try {
      const refDesc = doc(db, `organisations/`, `${id}`);

      if (e !== '') {
        await updateDoc(refDesc, {
          pin: e,
        });
      }
      setPin(['', '', '', '']);
      setLoading(false);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // pin verification
  // Modal Pinoursement
  const [openPinModal, setOpenPinModal] = React.useState(false);
  const [loadingPin, setLoadingPin] = React.useState(false);
  const handleOpenPinModal = () => setOpenPinModal(true);
  const handleClosePinModal = () => setOpenPinModal(false);

  const show = () => {
    if (isPin) {
      handleOpenPinModal();
    } else {
      setIsVerified(true);
    }
  };
  const verified = (index, value) => {
    const newPin = [...pin];
    if (/^\d$/.test(value)) {
      newPin[index] = value;
    }

    if (newPin[index] !== undefined && newPin[index] !== null) {
      newPin[index] = value.charAt(value.length - 1);
    }
    if (index > 0 && value === '') {
      // If a digit is deleted, move focus to the previous input
      document.getElementById(`pin-input-${index - 1}`).focus();
    } else if (index < 3 && value !== '') {
      // Move focus to the next input
      document.getElementById(`pin-input-${index + 1}`).focus();
    }

    setPin(newPin);

    // Check if all 4 digits are entered
    if (newPin.every((digit) => digit !== '')) {
      makePinVerification(newPin.join(''));
    }
  };

  const makePinVerification = async (e) => {
    // setLoadingVerification(true);
    try {
      //
      const q1 = doc(db, `organisations`, id);
      const orgSnap = await getDoc(q1);
      const tmp = orgSnap.data().pin;
      console.log('PIN : ');
      console.log(tmp);
      if (tmp === e) {
        console.log('PIN is correct : ');
        // chargement des informations
        const q2 = doc(db, `organisations/${id}/infoPaie`, id);
        const info = await getDoc(q2);
        setInfoType(info.data()?.owner.ownerType)
        if (info.data()?.owner.ownerType === 0) {
          setLastname(info.data().owner.lastname)
          setFirstname(info.data().owner.firstname)
        } else {
          setName(info.data()?.owner.name)
        }
        setCity(info.data()?.owner.city)
        setCountry(info.data()?.owner.country)
        setType(info.data()?.account.type)
        if (info.data()?.account.type === 0) {
          setBank(info.data()?.account.numBank)
          setSwift(info.data()?.account.codeSwift)
          setNom(info.data()?.account.nom)
        } else {
          setNum(info.data()?.account.num)
          setOpMobile(info.data()?.account.opMobile)
        }
        setIsVerified(true);
        await updateDoc(doc(db, `organisations`, `${id}`), {
          pinError: 0
        });
      } else {
        //
        setPinError(pinError + 1)
        await updateDoc(doc(db, `organisations`, `${id}`), {
          pinError: pinError + 1
        });

      }
    } catch (error) {
      console.log(error);
    }
    document.getElementById(`pin-input-${0}`).focus();
    setPin(['', '', '', '']);
  };

  React.useEffect(() => {
    (async () => {
      try {
        setMoyenVir([]);
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data().idOrg);
        if (docSnap.exists()) {
          // info sur l'organisation
          const q1 = doc(db, `organisations`, docSnap.data().idOrg);
          const orgSnap = await getDoc(q1);
          const tmp = orgSnap.data().pin;
          setPinError(orgSnap.data().pinError)
          console.log('PIN : ');
          console.log(tmp);
          if (tmp !== undefined) {
            setIsPin(true);
          } else {
            setIsPin(false);
            const q2 = doc(db, `organisations/${docSnap.data().idOrg}/infoPaie`, docSnap.data().idOrg);
            const info = await getDoc(q2);
            setInfoType(info.data().owner.ownerType)
            if (info.data()?.owner.ownerType === 0) {
              setLastname(info.data()?.owner.lastname)
              setFirstname(info.data()?.owner.firstname)
            } else {
              setName(info.data()?.owner.name)
            }
            setCity(info.data()?.owner.city)
            setCountry(info.data()?.owner.country)
            setType(info.data()?.account.type)
            if (info.data()?.account.type === 0) {
              setBank(info.data()?.account.numBank)
              setSwift(info.data()?.account.codeSwift)
              setNom(info.data()?.account.nom)
            } else {
              setNum(info.data()?.account.num)
              setOpMobile(info.data()?.account.opMobile)
            }
          }
          // info de paie
          console.log(docSnap.data().idOrg);
          setId(docSnap.data().idOrg);
          const q = collection(db, `organisations/${docSnap.data().idOrg}/infoPaie`);
          const querySnapshot = await getCountFromServer(q);
          console.log('collection ', querySnapshot.data().count);
          if (querySnapshot.data().count > 0) {
            setIsPaie(true);
          } else {
            setIsPaie(false);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [user.uid, refresh]);

  const [selectedValue, setSelectedValue] = React.useState();

  const handleChangePaie = (event) => {
    setSelectedValue(event);
  };
  const [activeModif, setActiveModif] = React.useState();

  return (
    <Page title="Finance - Coordonnées">

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Coordonnées Bancaire | Mobile
            <br />
            <Typography variant="body2">Vous permets de faire des demandes de virement</Typography>
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          {
            loading ?
              <Grid item xs={12}>
                <Box
                  sx={{
                    height: '100%',
                    borderRadius: '7px',
                    padding: 3,
                    border: '2px solid gainsboro',
                    display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'
                  }}
                >

                  <CircularProgress />


                </Box>

              </Grid>
              : isPaie && isPin && !isVerified ?

                <Grid item xs={12}>
                  {
                    pinError === 3 ?
                    <Box
                        sx={{
                          height: '100%',
                          borderRadius: '7px',
                          padding: 3,
                          border: '2px solid gainsboro',
                          display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'
                        }}
                      >
                        <Iconify icon='pepicons-print:lock-closed' sx={{ width: 60, height: 60 }} />
                        <Stack spacing={3} justifyContent='center'>
                          <Typography variant='subtitle2' textAlign='center'>Nombre maximun d'essais atteint. veuillez contacter choptonticket pour debloquer code PIN</Typography>
                        </Stack>

                      </Box>
                    :
                      <Box
                        sx={{
                          height: '100%',
                          borderRadius: '7px',
                          padding: 3,
                          border: '2px solid gainsboro',
                          display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'
                        }}
                      >
                        <Iconify icon='pepicons-print:lock-closed' sx={{ width: 60, height: 60 }} />
                        <Stack spacing={3} justifyContent='center'>
                          <Typography variant='subtitle2' textAlign='center'>Entrez votre code de sécurité pour accéder</Typography>
                          <Box sx={{ p: 2 }} dir="ltr">
                            <Stack direction="row" spacing={2} justifyContent="center">
                              {pin.map((digit, index) => (
                                <Box key={index}>
                                  <TextField
                                    key={index}
                                    id={`pin-input-${index}`}
                                    type="password"
                                    variant="outlined"
                                    size="medium"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => verified(index, e.target.value)}
                                    sx={{ width: '35px' }}
                                  />
                                </Box>
                              ))}
                            </Stack>
                            {
                              pinError > 0 && <Typography variant='caption' sx={{ color: 'red' }}>Votre code d'access est faux. {3 - pinError} essais restants.</Typography>
                            }

                          </Box>
                          <Stack spacing={1}>
                            <Typography variant='body2' textAlign='center'>Code oublié ?</Typography>
                            <Typography variant='body2' textAlign='center'>Renvoyer a test@test.com</Typography>
                          </Stack>
                        </Stack>

                      </Box>
                  }


                </Grid>

                :
                <Grid item xs={12}>
                  <Box
                    sx={{
                      height: '100%',
                      borderRadius: '7px',
                      padding: 3,
                      border: '2px solid gainsboro'
                    }}
                  >
                    <Typography variant='h5' textAlign="Start">
                      <strong>Information de reversement</strong>
                    </Typography>
                    <Typography variant="body2" textAlign="Start">
                      Ces informations seront utilisé pour vous reverser votre argent à la fin de vos evenement
                    </Typography>
                    <Box paddingTop={2}>
                      <Stack spacing={3}>
                        <Stack spacing={2}>
                          <Typography variant='subtitle1'>Information sur le proprietaire *</Typography>
                          <Stack my={2} direction='row' spacing={1}>
                            <Box onClick={() => setInfoType(0)} sx={infoType === 0 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                              :
                              { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                              <Typography variant='body2'>Particulier</Typography>
                            </Box>
                            <Box onClick={() => setInfoType(1)} sx={infoType === 1 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                              :
                              { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                              <Typography variant='body2'>Entreprise</Typography>
                            </Box>
                          </Stack>
                          <Stack spacing={1} direction='row'>
                            {
                              infoType === 0 ?
                                <>
                                  <TextField fullWidth placeholder='nom' value={lastname} onChange={(e) => handleLastname(e.target.value)} />
                                  <TextField fullWidth placeholder='prenom' value={firstname} onChange={(e) => handleFirstname(e.target.value)} />
                                </>
                                :
                                <TextField fullWidth placeholder='Nom de l entreprise' value={name} onChange={(e) => handleName(e.target.value)} />
                            }

                          </Stack>
                          <Stack spacing={1} direction='row'>
                            <TextField fullWidth placeholder='Ville' value={city} onChange={(e) => handleCity(e.target.value)} />
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Pays</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Pays"
                                value={country}
                                onChange={(e) => handleCountry(e.target.value)}
                              >
                                <MenuItem value='Côte d Ivoire'>Côte d'ivoire</MenuItem>
                              </Select>
                            </FormControl>
                          </Stack>

                        </Stack>
                        <Stack spacing={2}>
                          <Typography variant='subtitle1'>Information sur le compte *</Typography>
                          <Stack my={2} direction='row' spacing={1}>
                            <Box onClick={() => setType(0)} sx={type === 0 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                              :
                              { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                              <Typography variant='body2'>Compte bancaire</Typography>
                            </Box>
                            <Box onClick={() => setType(1)} sx={type === 1 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                              :
                              { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                              <Typography variant='body2'>Mobile money</Typography>
                            </Box>
                          </Stack>
                          {
                            type === 0 ?
                              <>
                                <TextField
                                  label="Nom de la banque"
                                  placeholder="Ex. : SGBCI, Atlantique banque"
                                  value={nom}
                                  onChange={(e) => handleNom(e.target.value)}
                                  id="nameField"
                                  type="text"
                                  fullWidth
                                  error={nomError}
                                  helperText={nomError && nom === '' ? 'Ce champ est requis' : ''}
                                />
                                <TextField
                                  label="Numero IBAN"
                                  value={numBank}
                                  onChange={(e) => handleNumBank(e.target.value)}
                                  placeholder="Numero IBAN"
                                  id="licenceField"
                                  type="text"
                                  error={numBankError}
                                  helperText={numBankError && numBank === '' ? 'Ce champ est requis' : ''}
                                  fullWidth
                                />
                                <TextField
                                  label="Code BIC / SWIFT"
                                  value={codeSwift}
                                  onChange={(e) => handleSwift(e.target.value)}
                                  placeholder="Code BIC / SWIFT"
                                  id="licenceField"
                                  type="text"
                                  error={codeSwiftError}
                                  helperText={codeSwiftError && codeSwift === '' ? 'Ce champ est requis' : ''}
                                  fullWidth
                                />
                              </>
                              :
                              <>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Operateur Mobile</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Operateur Mobile"
                                    value={opMobile}
                                    onChange={(e) => setOpMobile(e.target.value)}
                                  >
                                    <MenuItem value='Orange'>Orange Money</MenuItem>
                                    <MenuItem value='MTN'>MTN Money</MenuItem>
                                    <MenuItem value='Moov'>Moov Money</MenuItem>
                                  </Select>
                                </FormControl>
                                <CustomNumber number={num} onNumberChange={(e) => handleNum(e)} />
                              </>
                          }
                        </Stack>
                        {
                          (edit || !isPaie) && <Stack direction='row' spacing={2} justifyContent='end'>
                            <CustomButton buttonText='Enregistrer' type='primaire' onClick={addVir} />
                          </Stack>
                        }

                      </Stack>

                    </Box>
                  </Box>
                </Grid>
          }


          <Grid item xs={12} >
            <SecurityPass />
          </Grid>

        </Grid>
      </Container>
      <CustomSnack />
    </Page>
  );
}
