import React, { useState } from 'react';
import { TextField, MenuItem, FormControl, InputLabel, Select, Box, Grid } from '@mui/material';
import List from '../assets/countryCode.json'

const countryCodes = List;

const CustomNumber = ({ code, number, onContryCodeChange, onNumberChange }) => {
    const [countryCode, setCountryCode] = useState('+225');
    const [phoneNumber, setPhoneNumber] = useState(number);

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        onContryCodeChange(event.target.value)
    };

    const handlePhoneNumberChange = (event) => {
        const input = event.target.value.replace(/\s+/g, ''); // Supprime les espaces
        const formattedInput = input.match(/.{1,2}/g)?.join(' ') || ''; // Regroupe par deux et réinsère les espaces
        setPhoneNumber(formattedInput);
        onNumberChange(formattedInput)
      };
      React.useEffect(() => {
        (async () => {
          try {
            if (code) {
                setCountryCode(code)
            }
            
          } catch (error) {
            console.log(error);
          }
        })();
      }, []);

    return (
        <Grid container>
            <Grid item xs={5}>
                <FormControl fullWidth variant="outlined" style={{ minWidth: 120, marginRight: 8 }}>
                    <Select
                        value={countryCode}
                        onChange={handleCountryCodeChange}
                        disabled
                    >
                        {countryCodes.map((option) => (
                            <MenuItem key={option.dial_code} value={option.dial_code}>
                                {`${option.name} (${option.dial_code})`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={7}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="02 78 85 96 85"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    inputProps={{
                        maxLength: 14, // Limite à 14 caractères (10 chiffres + 4 espaces)
                        pattern: '^(\\d{2}\\s?){4,5}$' // Pattern pour valider l'entrée
                      }}
                />
            </Grid>
        </Grid>
    );
};

export default CustomNumber;
