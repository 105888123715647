import React from 'react';

import { Collapse } from 'antd';
import { Box, Button, Container, Grid, Stack, Typography, styled } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Iconify from '../Iconify';
import CustomButton from '../CustomButton';

const { Panel } = Collapse;

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: '1px solid white',
  borderRadius: '7px',
  backgroundColor: 'transparent',
  color: 'white',
  marginBottom: 2,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function AppFaq() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div id="faq" className="block faqBlock bgBlack">
      <div className="aboutBlock bgBlack">
        <div>
          <Typography paddingTop={2} textAlign="center" variant="h2" color="white">
            Questions fréquemments posées
          </Typography>
        </div>
        <Container sx={{ color: 'white', paddingBottom:8 }}>
          <Grid container paddingTop={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid item xs={12} sm={10}>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>
                    <strong>Votre Solution est-elle sécurisée ?</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Choptonticket garantit une solution à la fois sûre et rassurante. Nous accordons une attention
                    particulière à la gestion sécurisée de l'accès, à la séparation des données, ainsi qu'à la
                    sauvegarde et à la redondance des services pour assurer la protection optimale de vos informations.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography>
                    <strong>A qui s'adresse nos service ?</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Choptontiket est une solution en libre-service, offrant ainsi la possibilité de maintenir un
                    contrôle total sur votre événement et de personnaliser vos billetteries de manière optimale. Cette
                    solution est adoptée par divers acteurs tels que des associations, des entreprises, des
                    indépendants, des collectivités et des particuliers, couvrant ainsi un large éventail de secteurs et
                    d'applications.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>
                    <strong>Puis-je proposer des réductions ou des codes de promotions à mes participants</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Les codes promotionnels représentent une stratégie efficace pour stimuler les achats. Proposez des
                    réductions aux nouveaux inscrits, utilisez-les pour évaluer l'impact de vos initiatives
                    promotionnelles, ou offrez-les à des segments spécifiques de clients pour mesurer la portée de vos
                    événements.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                  <Typography>
                    <strong>
                      Pourrais-je facilement récupérer la liste de mes ventes après la fin de mon événement ?
                    </strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Effectivement vous avez la possibilité d'exporter vos données si vous le souhaitez. Cependant
                    pourquoi se limiter à l'exportation quand vous pouvez obtenir plus avec Choptonticket ? Nous mettons
                    à votre disposition un outil CRM qui vous permet d'organiser des campagnes d'e-mails et des
                    notifications push (pour IOS et Android) directement depuis la plateforme. De plus, vous avez une
                    vue d'ensemble complète de tous les participants de tous vos événements organisés avec
                    Choptonticket.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                  <Typography>
                    <strong>En cas d'annulation, que se passe-t-il ?</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    En cas d'annulation d'un événement, Choptonticket offre une solution facile et efficace pour le
                    remboursement des participants. Les organisateurs ont la possibilité d'utiliser notre outil dédié au
                    remboursement, qui simplifie le processus en quelques étapes. Les fonds peuvent être restitués aux
                    clients de manière transparente, assurant ainsi une gestion fluide des remboursements en cas
                    d'annulation d'un événement.Notre équipe de support est également disponible pour accompagner les
                    organisateurs tout au long de ce processus, garantissant une expérience satisfaisante pour tous les
                    parties impliquées.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                  <Typography>
                    <strong>Comment me tenir informé des billets vendu ?</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Choptonticket met a votre disposition un tableau de bord vous permettant de suivre en temps réel vos
                    ventes et accéder à des informations statistiques. Vous recevrez egalement un rapport sur le nombre
                    de billet vendu de manière hebdomadaire
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          <Stack spacing={2} direction="column" padding={5} alignItems="center" justifyContent="center">
            <Typography paddingX={{ lg: '15%', xl: '20%' }} variant="h3" textAlign="center" color="white">
              Vous avez une autre question ?
            </Typography>
            <Typography textAlign="start" fontSize={18}>
              Contactez-nous ici et nous serons heureux de répondre à toutes vos questions supplémentaires.
            </Typography>
            <Button
              size="large"
              sx={{
                textTransform: 'initial',
                border: '2px solid transparent',
                color: '#141414',
                backgroundColor: 'white',
                borderRadius: '7px',
                '&:hover': {
                  backgroundColor: '#141414',
                  color: 'white',
                  borderColor: 'white',
                },
              }}
              variant="contained"
              href="/bo/new_event"
            >
              Nous contacter
            </Button>
          </Stack>
          
            <Stack
              padding={2}
              spacing={2}
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography paddingX={{ lg: '15%', xl: '20%' }} variant="h3" textAlign="center" color="white">
                Prêt à vous lancer ?
                <Iconify icon="ion:rocket" sx={{ minWidth: 40, minHeight: 40, ml: 1, color: '#5E17EB' }} />
              </Typography>
            </Stack>
            <Grid paddingX={5} paddingTop={5} alignItems="center" container spacing={5}>
              <Grid item xs={12} sm={6}>
                <Button
                  size="large"
                  fullWidth
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#5E17EB',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: '#5E17EB',
                    },
                  }}
                  variant="contained"
                  href="/bo/new_event"
                >
                  Créer un évenenement maintenant
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  size="large"
                  fullWidth
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: '#141414',
                    backgroundColor: 'white',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: '#141414',
                      color: 'white',
                      borderColor: 'white',
                    },
                  }}
                  variant="contained"
                  href="/bo/new_event"
                >
                  Nous contacter
                </Button>
              </Grid>
            </Grid>
          
        </Container>
      </div>
    </div>
  );
}

export default AppFaq;
