/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Grid,
    CardActions,
    CardContent,
    Box,
    Modal,
    TextField,
    LinearProgress,
    IconButton,
    Tooltip,
    Backdrop,
    CircularProgress,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Switch,
    FormGroup,
    Snackbar,
    Skeleton,
    Alert,
    ToggleButtonGroup,
    styled,
    ToggleButton,
    InputAdornment,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDocs, query, Timestamp, updateDoc } from 'firebase/firestore';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../../../firebase.config';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import CustomButton from '../../../components/CustomButton';
import CustomSnack from '../../../components/customSnack';
import NumberTextField from '../../../components/NumberTextField';


// ----------------------------------------------------------------------
// Toogle Group Button
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: 0.5,
        width: 60,
        height: 30,
        border: 0,
        // background: 'black',
        '&.Mui-disabled': {
            border: 0,
        },
        '&.Mui-selected': {
            border: 0,
            background: '#5E17EB',
            color: 'white',
            '&:hover': {
                backgroundColor: '#5E17EB',

            },
        },
        '&:not(:first-of-type)': {
            // background: 'red',
            borderRadius: '7px',
        },
        '&:first-of-type': {
            // background: 'black',
            borderRadius: '7px',
        },
    },
}));
//-----------------------------------------------------------------------
// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

// for modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'white',
    border: '1px solid white',
    borderRadius: '7px',
    boxShadow: 0,
    p: 3,
};

export default function AddAffiliate() {
    const [page, setPage] = useState(0);
    const { eventID } = useParams();
    const navigate = useNavigate();
    // loading
    const [loading, setLoading] = React.useState(false);



    //  snack bar
    const [openSnack, setOpenSnack] = React.useState(false);
    const [msg, setMsg] = React.useState({ content: '', color: '' });
    const handleCloseSnack = () => {
        setOpenSnack(false);
    };

    // navigate(-1, { replace: true });



    // Modal deleted
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const handleOpenDeleteModal = () => setOpenDeleteModal(true);
    const handleCloseDeleteModal = () => setOpenDeleteModal(false);

    // variables
    const [name, setName] = React.useState('');
    const [nameError, setNameError] = React.useState({});
    const [emailError, setEmailError] = React.useState({});
    const [email, setEmail] = React.useState('');
    const [commission, setCommission] = React.useState(false);
    const [commissionType, setCommissionType] = React.useState(0);
    const [commissionValue, setCommissionValue] = React.useState(0);
    const [commissionError, setCommissionError] = React.useState({});
    const [codePromo, setCodePromo] = React.useState(false);
    const [promoCode, setPromoCode] = React.useState('');
    const [promoType, setPromoType] = React.useState(0);
    const [promoValue, setPromoValue] = React.useState(0);
    const handleCommission = () => {
        setCommission(!commission)
    };
    const handleName = (e) => {
        setName(e.target.value)
    };
    const handleEmail = (e) => {
        setEmail(e.target.value)
    };
    const handleCommissionValue = (e) => {
        console.log(e.target)
        console.log(e)
        setCommissionValue(e)
    };
    const handlePromo = () => {
        setCodePromo(!codePromo)
    };
    const handlePromoCode = (e) => {
        setPromoCode(e)
    };
    const handlePromoValue = (e) => {
        setPromoCode(e)
    };

    const checkValue = () => {
        let validate = true
        console.log(name)
        if (name === '') {
            setNameError({ error: true, msg: 'ce champ est requeis' })
            validate = false
        } else {
            setNameError({})
        }
        if (email === '') {
            setEmailError({ error: true, msg: 'ce champ est requeis' })
            validate = false
        } else {
            setEmailError({})
        }
        if (commission && commissionValue === 0) {
            setCommissionError({ error: true, msg: 'ce champ est requeis' })
            validate = false
        } else {
            setCommissionError({})
        }
        return validate
    }

    // create affiliation
    const createAffiliation = async () => {
        try {
            // Appel à la fonction Cloud pour créer l'affiliation
            setLoading(true)
            const validate = checkValue()
            if (!validate) {
                setLoading(false)
                setMsg({ error: true, content: 'Erreur lors de la création de l’affiliation' })
                setOpenSnack(true)
                return
            }
            const createAffiliationFunction = httpsCallable(functions, 'addAffiliate');

            const response = await createAffiliationFunction({
                eventID,
                partnerData: { name, email },
                commissionData: { type: commissionType, value: commissionValue },
                promoData: { promoCode, type: promoType, value: promoValue }
            });


            console.log('commssion:', commissionValue);
            if (response.data.message === 'ok') {
                console.log('Affiliation créée avec succès:', response.data);
                setMsg({ error: false, content: 'Affiliation créée avec succès' })
                setOpenSnack(true)
                navigate(-1)
            } else {
                console.log('Erreur lors de la création de l’affiliation:', response.data);
                setMsg({ error: true, content: 'Erreur lors de la création de l’affiliation' })
                setOpenSnack(true)
            }

        } catch (error) {
            setMsg({ error: true, content: 'Erreur lors de la création de l’affiliation' })
            setOpenSnack(true)
            console.error('Erreur lors de la création de l’affiliation:', error);
        }
        setLoading(false)
    };


    return (
        <Page title="Affilation">
            {loading && <LinearProgress />}
            <Container sx={{ marginTop: { xs: 3 }, paddingBottom: 8 }}>
                <Stack
                    alignItems='start'
                    mb={5}
                    spacing={2}
                >
                    <CustomButton type='back' buttonText='Retour' onClick={() => navigate(-1)} />
                    <Typography variant="h2" gutterBottom>
                        Nouvelle affiliation <br />
                    </Typography>
                </Stack>

                <Stack spacing={3}>

                    <Box sx={{ border: '1px solid gainsboro', borderRadius: '7px' }} padding={2}>
                        <Typography variant='h4'>Informations sur le patenaire</Typography>
                        <Grid container sx={{ bgcolor: '#f2f2f2' }} padding={2}>
                            <Grid xs={12} sm={6}>
                                <Stack padding={1}>
                                    <Typography>Nom</Typography>
                                    <TextField value={name} onChange={(e) => handleName(e)} required type='text' error={nameError?.error} helperText={nameError?.msg} />
                                </Stack>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <Stack padding={1}>
                                    <Typography>Email</Typography>
                                    <TextField required type='email' value={email} onChange={(e) => handleEmail(e)} error={emailError?.error} helperText={emailError?.msg} />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ border: '1px solid gainsboro', borderRadius: '7px' }} padding={2}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography variant='h4'>Commission</Typography>
                            <Paper
                                elevation={0}
                                sx={{
                                    display: 'flex',
                                    width: 120,
                                    marginY: 1,
                                    border: (theme) => `1px solid #f2f2f2`,
                                    borderRadius: '7px',
                                    flexWrap: 'wrap',
                                    bgcolor: '#f2f2f2',
                                }}
                            >
                                <StyledToggleButtonGroup
                                    value={commission}
                                    exclusive
                                    onChange={handleCommission}
                                    aria-label="Platform"
                                    sx={{ color: 'red' }}
                                >
                                    <ToggleButton value={false}>Non</ToggleButton>
                                    <ToggleButton value>Oui</ToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </Stack>
                        {
                            commission &&
                            <Grid container sx={{ bgcolor: '#f2f2f2' }} padding={2}>
                                <Grid xs={12} >
                                    <Box sx={{ display: 'flex' }}>
                                        <Box onClick={() => setCommissionType(0)} sx={commissionType === 0 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                            :
                                            { bgcolor: 'gainsboro', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                                            <Typography variant='body2'>Montant fixe</Typography>
                                        </Box>
                                        <Box padding={0.5} />
                                        <Box onClick={() => setCommissionType(1)} sx={commissionType === 1 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                            :
                                            { bgcolor: 'gainsboro', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                                            <Typography variant='body2'>Pourcentage</Typography>
                                        </Box>
                                    </Box>

                                </Grid>
                                <Grid xs={12} sm={6}>
                                    <Stack padding={1} spacing={0.5}>
                                        <Typography variant='body1' >{commissionType === 0 ? 'Montant de commission (CFA)' : 'Pourcentage de commission (%)'}</Typography>
                                        <NumberTextField value={commissionValue} onChange={handleCommissionValue} />
                                        <Box>
                                            <Typography variant='body2'>le partner aura une commission de <strong>{commissionValue} {commissionType === 0 ? 'CFA' : '%'}</strong> sur chaque achat</Typography>
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                        }

                    </Box>
                    <Box sx={{ border: '1px solid gainsboro', borderRadius: '7px' }} padding={2}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography variant='h4'>Code promo</Typography>
                            <Paper
                                elevation={0}
                                sx={{
                                    display: 'flex',
                                    width: 120,
                                    marginY: 1,
                                    border: (theme) => `1px solid #f2f2f2`,
                                    borderRadius: '7px',
                                    flexWrap: 'wrap',
                                    bgcolor: '#f2f2f2',
                                }}
                            >
                                <StyledToggleButtonGroup
                                    value={codePromo}
                                    exclusive
                                    onChange={handlePromo}
                                    aria-label="Platform"
                                    sx={{ color: 'red' }}
                                >
                                    <ToggleButton value={false}>Non</ToggleButton>
                                    <ToggleButton value>Oui</ToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </Stack>
                        {
                            codePromo &&
                            <Grid container sx={{ bgcolor: '#f2f2f2' }} padding={2}>

                                <Grid xs={12} >
                                    <Box sx={{ display: 'flex' }}>
                                        <Box onClick={() => setPromoType(0)} sx={promoType === 0 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                            :
                                            { bgcolor: 'gainsboro', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                                            <Typography variant='body2'>Montant fixe</Typography>
                                        </Box>
                                        <Box padding={0.5} />
                                        <Box onClick={() => setPromoType(1)} sx={promoType === 1 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                            :
                                            { bgcolor: 'gainsboro', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                                            <Typography variant='body2'>Pourcentage</Typography>
                                        </Box>
                                    </Box>

                                </Grid>
                                <Grid xs={12} sm={6}>
                                    <Stack padding={1} spacing={0.5}>
                                        <Typography variant='body1' >{promoType === 0 ? 'Montant de commission (CFA)' : 'Pourcentage de commission (%)'}</Typography>
                                        <NumberTextField value={promoValue} onChange={handlePromoValue} />
                                    </Stack>
                                </Grid>
                                <Grid xs={12} sm={6}>
                                    <Stack padding={1} spacing={0.5}>
                                        <Typography>Code à saisir</Typography>
                                        <NumberTextField value={promoCode} onChange={handlePromoCode} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        }

                    </Box>

                </Stack>

            </Container>
            <Container>
                <Stack
                    direction='row'
                    spacing={2}
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        padding: '16px',
                        // borderTop: '2px solid gainsboro',
                        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
                        zIndex: 1000,
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                    }}
                >
                    <CustomButton
                        type='secondaire'
                        buttonText='Annuler'
                        loading={loading}
                        onClick={() => window.history.back()}
                    />
                    <CustomButton
                        loading={loading}
                        type='primaire'
                        buttonText='Créer'
                        onClick={createAffiliation}
                    />
                </Stack>
            </Container>
            <CustomSnack open={openSnack} handleClose={handleCloseSnack} msg={msg} />

        </Page>
    );
}
