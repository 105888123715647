import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//

import DashboardSidebar from '../dashboard/DashboardSidebar';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import DashboardSidebarOrg from '../dashboard/DashboardSidebarOrg';
import HomeNavbar from '../home/HomeNavbar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 70;

const RootStyle = styled('div')({
  display: 'flex',
  // minHeight: '100%',
  backgroundColor:'white',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  // minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  backgroundColor:'white',
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP ,
  }
}));

// ----------------------------------------------------------------------

export default function CrmLayout() {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <HomeNavbar />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
