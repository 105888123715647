/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Grid,
    CardActions,
    CardContent,
    Box,
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Chip,
    OutlinedInput,
    MenuItem,
    useTheme,
    Tooltip,
    IconButton,
    Backdrop,
    CircularProgress,
    Skeleton,
    InputAdornment,
    Menu,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';

// mock
import USERLIST from '../../../../_mock/user';

import { db } from '../../../../firebase.config';
import CheckCard from '../../../../components/CheckCard';
import { UserAuth } from '../../../../context/AuthContext';
import { Variables } from '../../../../context/VariableContext';
import Swipeable from '../swiper';
import CustomButton from '../../../../components/CustomButton';

// ----------------------------------------------------------------------
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 7,
        border: '0.5px solid black',
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));
// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

// for modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ViewPopulation() {
    const { eventID } = useParams();

    const navigate = useNavigate();
    const { populationID } = useParams();
    const { user } = UserAuth();
    const theme = useTheme();
    const [orgID, setOrgID] = React.useState();

    // loading
    const [loading, setLoading] = React.useState(false);

    // GESTION DE LA MODIFICATION
    const { openDrawer, setOpenDrawer } = Variables();
    const { type, setType } = Variables();

    const handleImportation = () => {
        setType(6);
        setOpenDrawer(!openDrawer);
    };


    // for change value of globla quota
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [refreh, setRefresh] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        // setModif(false);
        setRefresh((prev) => !prev);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    // take operateurs from firebase
    const [contacts, setContacts] = React.useState([]);
    const [population, setPopulation] = React.useState();

    // take operateurs from firebase
    const [curent, setCurent] = React.useState();
    const [tmp, setTmp] = React.useState();
    // const [type, setType] = React.useState();
    const [search, setSearch] = React.useState('');


    React.useEffect(() => {
        (async () => {
            setContacts([]);
            try {
                setLoading(true);
                if (populationID) {
                    const docRef0 = doc(db, 'organisateurs', `${user.uid}`);

                    const docSnap = await getDoc(docRef0);
                    /* if (docSnap.exists()) {
                      
                    } */
                    setOrgID(docSnap.data().idOrg)
                    const popRef0 = doc(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/populations`, `${populationID}`);

                    const popSnap = await getDoc(popRef0);
                    if (popSnap.exists()) {
                        console.log(popSnap.data())
                        setPopulation(popSnap)
                        const q = query(collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/contacts`));
                        const snapshot = await getDocs(q);
                        snapshot.forEach(async (d) => {
                            setContacts((populations) => [...populations, d]);
                        });
                    }


                }
                setLoading(false);
            } catch (error) {
                console.log('error');
            }
        })();
    }, [refreh, openDrawer, user, populationID]);

    const handleModif = async (index) => {
        setType(6);
        setOpenDrawer(!openDrawer);
    };

    const handleTimestamp = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.');
        return tmp[0];
    };

    return (
        <Page title="Population">
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container sx={{ marginTop: { xs: 3 } }}>
                <CustomButton type='back' buttonText='Retour' onClick={() => { navigate(`/marketing/populations`) }} />
                <Stack direction='row' spacing={2} mb={5} mt={1}>
                    <Box style={{ backgroundColor: '#F5EFFA', borderRadius: '7px', width: 95, height: 95, border: '1px solid gainsboro', fontSize: "1.5rem", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {population?.data().emoji}
                    </Box>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            {population?.data().name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {population?.data().description}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {population?.data().createdAt}
                        </Typography>
                    </Box>
                </Stack>

                <Stack spacing={1} mb={1}>
                    <Stack direction='row' alignItems='center'>
                        <Typography variant='subtitle1'>Filtres</Typography>
                        <CustomButton type='tertiaire' buttonText='editer' onClick={handleModif} />

                    </Stack>

                    <Stack direction='row' useFlexGap flexWrap="wrap" rowGap={1} spacing={2} padding={2} sx={{ border: '1px solid #f2f2f2', borderRadius: '7px' }}>
                        {
                            population?.data().filterEvent != null ? 
                            <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={1} direction='row' spacing={0.5}>
                                <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                                <Typography variant='body2'>Participations de:</Typography>
                                <Typography variant='body2'><strong> biny club +2</strong></Typography>
                            </Stack>
                                : null
                        }
                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={1} direction='row' spacing={0.5}>
                            <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                            <Typography variant='body2'>Participations de:</Typography>
                            <Typography variant='body2'><strong> biny club +2</strong></Typography>
                        </Stack>
                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={1} direction='row' spacing={0.5}>
                            <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                            <Typography variant='body2'>Non participations de:</Typography>
                            <Typography variant='body2'><strong> biny club +2</strong></Typography>
                        </Stack>
                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={1} direction='row' spacing={0.5}>
                            <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                            <Typography variant='body2'>Nombre total d'event:</Typography>
                            <Typography variant='body2'><strong> biny club +2</strong></Typography>
                        </Stack>
                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={1} direction='row' spacing={0.5}>
                            <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                            <Typography variant='body2'>Total dépensé:</Typography>
                            <Typography variant='body2'><strong> 1000 CFA et 2000 CFA</strong></Typography>
                        </Stack>
                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={1} direction='row' spacing={0.5}>
                            <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                            <Typography variant='body2'>Dernier achat:</Typography>
                            <Typography variant='body2'><strong> 1000 CFA et 2000 CFA</strong></Typography>
                        </Stack>
                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={1} direction='row' spacing={0.5}>
                            <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                            <Typography variant='body2'>Abonné a ma page:</Typography>
                            <Typography variant='body2'>true</Typography>
                        </Stack>
                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={1} direction='row' spacing={0.5}>
                            <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                            <Typography variant='body2'>Abonné aux notif:</Typography>
                            <Typography variant='body2'>true</Typography>
                        </Stack>
                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={1} direction='row' spacing={0.5}>
                            <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                            <Typography variant='body2'>Abonné aux newsletters:</Typography>
                            <Typography variant='body2'>true</Typography>
                        </Stack>
                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} padding={1} direction='row' spacing={0.5}>
                            <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                            <Typography variant='body2'>Date d'ajout:</Typography>
                            <Typography variant='body2'>true</Typography>
                        </Stack>
                    </Stack>
                </Stack>

                <Grid container spacing={2} paddingBottom={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={12} sm={4} md={6}>
                        {loading ? (
                            <Skeleton width="20%">
                                <Typography>.</Typography>
                            </Skeleton>
                        ) : (
                            <Typography variant="subtitle1">{setContacts.length} contacts</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>

                        <Stack direction='row' spacing={2}>
                            <TextField InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Iconify icon="iconoir:search" />
                                    </InputAdornment>
                                ),
                            }} placeholder="Rechercher" fullWidth />
                        </Stack>
                    </Grid>
                </Grid>
                <Card>
                    <Scrollbar>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="caption table">
                                <TableHead sx={{ bgcolor: 'gainsboro' }}>
                                    <TableRow>
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Pays</TableCell>
                                        <TableCell>Age</TableCell>
                                        <TableCell>Genre</TableCell>
                                        <TableCell>Abonné</TableCell>
                                        <TableCell>Notif push</TableCell>
                                        <TableCell>Newsletters</TableCell>
                                        <TableCell>Date d'ajout</TableCell>
                                    </TableRow>
                                </TableHead>
                                {loading ? (
                                    <caption>
                                        <Skeleton variant="rounded" width="100%" />
                                    </caption>
                                ) : contacts.length === 0 && !loading ? (
                                    <caption>
                                        <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                                            <Typography variant="caption">Aucune donnée</Typography>
                                        </Stack>
                                    </caption>
                                ) : (
                                    <TableBody>
                                        {contacts.map((contact, index) => (
                                            <TableRow key={index} hover>
                                                <TableCell
                                                    id={contact.data().nom}
                                                    onClick={(e) => handleModif(index)}
                                                    key={contact.data().nom}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <Stack>
                                                        <Stack direction="row">
                                                            {contact.data().nom} {contact.data().prenom}
                                                        </Stack>
                                                        {contact.id}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                                                    {contact.data().pays ? contact.data().pays : '-'}
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                                                    {contact.data().age ? contact.data().age : '-'}
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                                                    {contact.data().genre ? contact.data().genre : '-'}
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                                                    {contact.data().push || contact.data().newslettres ? (
                                                        <Box
                                                            component="button"
                                                            paddingX={2}
                                                            paddingY={0.5}
                                                            sx={{
                                                                borderRadius: '5px',
                                                                fontSize: 11,
                                                                backgroundColor: '#C9E4D4',
                                                                border: '1px dashed #C9E4D4',
                                                            }}
                                                        >
                                                            oui
                                                        </Box>
                                                    ) : (
                                                        <Box
                                                            component="button"
                                                            paddingX={2}
                                                            paddingY={0.5}
                                                            sx={{
                                                                borderRadius: '5px',
                                                                fontSize: 11,
                                                                backgroundColor: '#FB8888',
                                                                border: '1px dashed #FB8888',
                                                            }}
                                                        >
                                                            non
                                                        </Box>
                                                    )}
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                                                    {contact.data().push ? (
                                                        <Box
                                                            component="button"
                                                            paddingX={2}
                                                            paddingY={0.5}
                                                            sx={{
                                                                borderRadius: '5px',
                                                                fontSize: 11,
                                                                backgroundColor: '#C9E4D4',
                                                                border: '1px dashed #C9E4D4',
                                                            }}
                                                        >
                                                            oui
                                                        </Box>
                                                    ) : (
                                                        <Box
                                                            component="button"
                                                            paddingX={2}
                                                            paddingY={0.5}
                                                            sx={{
                                                                borderRadius: '5px',
                                                                fontSize: 11,
                                                                backgroundColor: '#FB8888',
                                                                border: '1px dashed #FB8888',
                                                            }}
                                                        >
                                                            non
                                                        </Box>
                                                    )}
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                                                    {contact.data().newslettres ? (
                                                        <Box
                                                            component="button"
                                                            paddingX={2}
                                                            paddingY={0.5}
                                                            sx={{
                                                                borderRadius: '5px',
                                                                fontSize: 11,
                                                                backgroundColor: '#C9E4D4',
                                                                border: '1px dashed #C9E4D4',
                                                            }}
                                                        >
                                                            oui
                                                        </Box>
                                                    ) : (
                                                        <Box
                                                            component="button"
                                                            paddingX={2}
                                                            paddingY={0.5}
                                                            sx={{
                                                                borderRadius: '5px',
                                                                fontSize: 11,
                                                                backgroundColor: '#FB8888',
                                                                border: '1px dashed #FB8888',
                                                            }}
                                                        >
                                                            non
                                                        </Box>
                                                    )}
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                                                    {contact.data().createDate ? format(new Date(handleTimestamp(contact.data().createDate)), 'dd/MM/yy', {
                                                        locale: fr,
                                                    }).toString() : '-'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>
            </Container>
            <Swipeable type={type} organisation={orgID} popID={populationID} />
        </Page>
    );
}
