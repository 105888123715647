import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';

const NumberTextField = ({ label, value, onChange, ...buttonProps }) => {
  const inputRef = useRef(null); // Crée une référence pour accéder directement à l'input

  const handleChange = (event) => {
    let inputValue = event.target.value;

    // Supprime le 'e' immédiatement avant toute autre mise à jour
    if (inputValue.includes('e') || inputValue.includes('E')) {
      inputValue = inputValue.replace(/e/gi, ''); // supprime toutes les occurrences de 'e' ou 'E'
      
      // Forcer la mise à jour immédiate de l'input
      inputRef.current.value = inputValue;
    }

    // Appel de la fonction onChange avec la nouvelle valeur
    onChange(inputValue.replace('e',''));
  };

  const handleFocus = (event) => {
    // Empêcher le défilement de changer la valeur en focusant sur l'input
    event.target.addEventListener('wheel', (e) => e.preventDefault(), { passive: false });
  };

  const handleBlur = (event) => {
    // Retirer l'event listener lors du blur pour éviter les fuites de mémoire
    event.target.removeEventListener('wheel', (e) => e.preventDefault());
  };

 
 
  return (
    <TextField
      {...buttonProps}
      label={label}
      value={value}
      inputRef={inputRef} // Attache la ref à l'input
      onChange={handleChange}
      type="number"
      onFocus={handleFocus} // Ajout de l'écouteur d'événement lorsque l'input est en focus
      onBlur={handleBlur} // Suppression de l'écouteur d'événement lorsque l'input perd le focus
      
      inputProps={{
        shrink: true,
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }}
    />
  );
};

export default NumberTextField;
