import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Grid,
    CardActions,
    CardContent,
    Box,
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Chip,
    OutlinedInput,
    MenuItem,
    useTheme,
    Tooltip,
    IconButton,
    Backdrop,
    CircularProgress,
    Menu,
    Link,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';

// mock
import USERLIST from '../../../_mock/user';

import { db } from '../../../firebase.config';
import CheckCard from '../../../components/CheckCard';
import { UserAuth } from '../../../context/AuthContext';
import Coordonnees from "../../organisation/CoordonneesBancaires";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}
const options = ['Editer la campagne', 'Supprimer la campagne'];

// for modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function Settings() {
    const { eventID } = useParams();
    const { user } = UserAuth();
    const theme = useTheme();
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (e) => {
        console.log('cell click ', e.target.id);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    // for change value of globla quota
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [refreh, setRefresh] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        // setModif(false);
        setRefresh((prev) => !prev);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    // take operateurs from firebase
    const [contacts, setContact] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            setContact([]);
            try {
                setLoading(true);

                const docRef0 = doc(db, 'organisateurs', `${user.uid}`);

                const docSnap = await getDoc(docRef0);
                /* if (docSnap.exists()) {
                  
                } */
                // setIdOrg(docSnap.data().idOrg)
                const q = query(collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/campagnes`));
                const snapshot = await getDocs(q);
                snapshot.forEach(async (d) => {
                    setContact((contacts) => [...contacts, d]);
                });
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [refreh, user]);

    // loading
    const [loading, setLoading] = React.useState(false);

    // GESTION DE LA MODIFICATION
    const [temp, setTemp] = React.useState([]);
    const [name, setName] = React.useState();
    const [id, setId] = React.useState();
    const [pass, setPass] = React.useState();

    // *****ticket
    const [ticketVerif, setTicket] = React.useState([]);
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
        };
    }

    const [personName, setPersonName] = React.useState([]);
    const handleChangeTicket = (event) => {
        const {
            target: { value },
        } = event;
        setTicket(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
        console.log(ticketVerif);
    };

    const [modif, setModif] = React.useState(false);
    const handleModif = (index) => {
        if (modif) {
            setModif(false);
        } else {
            const temp = contacts[index].data();
            console.log(index);
            console.log(temp.tickets);
            setName(temp.nameOp);
            setId(temp.idOp);
            setPass(temp.passOp);
            setTicket(temp.tickets);
            setModif(true);
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSubmit = async (e) => { };
    const [menu, setMenu] = React.useState(0);

    return (
        <Page title="Campagne">
            <Box>
                <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container sx={{ marginTop: { xs: 3 }, }}>
                <Stack my={2} direction='row' spacing={1}>
                        <Box onClick={() => setMenu(0)} sx={menu === 0 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
                            :
                            { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                            <Typography variant='body2'>Coordonnés bancaires</Typography>
                        </Box>
        
                    </Stack>
                    {
                        menu === 0 ?
                        <Coordonnees />
                        : null
                    }
                </Container>
            </Box>

        </Page>
    );
}
