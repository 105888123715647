
import React from 'react';
import { Carousel } from 'antd';
import { Button, Container, Typography, Grid, Card, TextField, Box, Stack } from '@mui/material';
import Typewriter from 'typewriter-effect';
import CustomButton from '../CustomButton';

const items = [
  {
    key: '1',
    title: 'La solution de billetterie idéale pour votre ',
    content:
      'Proposez la meilleure expérience de billetterie en ligne, de marketing evenementiel et controle d\'acces a vous et votre public, avec la solution la plus complète',
  },
];

function AppHero() {
  return (
    <div id="hero" className="heroBlock">
      <Carousel>
        {items.map((item) => (
            <div key={item.key} className="container-fluid">
              <Grid container paddingX={{ sm: '15%' }} paddingY={{ xs: 5, lg: 2, xl: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  paddingX={{ sm: '15%', lg: '3.5%', xl: '15%' }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div>
                    <Typography color="white" paddingTop={10} variant="h1">
                      {item.title} <Typewriter
                      options={{
                        loop:true
                      }}
                      onInit={(typewriter) => {
                        typewriter
                          .typeString('Concert')
                          .pauseFor(1000)
                          .deleteAll()
                          .typeString('Festival')
                          .pauseFor(1000)
                          .deleteAll()
                          .typeString('Brunch')
                          .pauseFor(1000)
                          .deleteAll()
                          .typeString('événement sportif')
                          .pauseFor(1000)
                          .deleteAll()
                          .typeString('Soirée clubing')
                          .start();
                      }}
                    />
                    </Typography>
                    <Typography align='center' color="white" paddingY={{ xs: 5, lg: 2, xl: 5 }} variant='subtitle1'>
                      {item.content}
                    </Typography>
                    

                    <div className="btnHolder">
                      <Stack direction='row' spacing={1}>
                      
                      <Button
                        fullWidth
                        sx={{
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          color: 'white',
                          height: 60,
                          backgroundColor: '#5E17EB',
                          borderRadius: '7px',
                          '&:hover': {
                            backgroundColor: 'white',
                            color: '#5E17EB',
                            borderColor: '#5E17EB',
                          },
                        }}
                        variant="contained"
                        href="/bo/"
                      >
                        Commencer maintenant
                      </Button>
                      </Stack>
                      
                      
                    </div>
                    <Typography textAlign="center" variant='body2' padding={2} color="white">
                      Aucune installation requise et c'est gratuit
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
      </Carousel>
    </div>
  );
}

export default AppHero;
