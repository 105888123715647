import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useLocation, useNavigate } from 'react-router-dom';
import CampaignCrm from './component/campaignCrm';
import ContactsCrm from './component/contactsCrm';
import PopulationCrm from './component/populationCrm';
import Iconify from '../../components/Iconify';
import CanalVente from '../communication/canal_de_vente/CanalDeVente';
import Dashboard from './component/dashboard';
import Parameter from './component/parameter';

export default function CrmTab() {
  const [value, setValue] = React.useState('1');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === '1') {
      if (!pathname.includes('campaigns')) {
        navigate(`/marketing`);
      }

    }
    if (newValue === '2') {
      if (!pathname.includes('campaigns')) {
        navigate(`/marketing/campaigns`);
      }

    }
    else if (newValue === '3') {
      if (!pathname.includes('contacts')) {
        navigate(`/marketing/contacts`);
      }
    }
    else if (newValue === '4') {
      if (!pathname.includes('populations')) {
        navigate(`/marketing/populations`);
      }
    }
    else if (newValue === '5') {
      if (!pathname.includes('socialmedia')) {
        navigate(`/marketing/socialmedia`);
      }
    }
    else if (newValue === '6') {
      if (!pathname.includes('settings')) {
        navigate(`/marketing/settings`);
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const tmp = pathname.split('/')
        console.log(tmp[tmp.length - 1])
        if (tmp[tmp.length - 1] === 'campaigns') {
          navigate(`/marketing/campaigns`, { replace: true });
          setValue('2')
        } else if (tmp[tmp.length - 1] === 'contacts') {
          navigate(`/marketing/contacts`, { replace: true });
          setValue('3')
        } else if (tmp[tmp.length - 1] === 'populations') {
          navigate(`/marketing/populations`, { replace: true });
          setValue('4')
        }
        else if (tmp[tmp.length - 1] === 'socialmedia') {
          navigate(`/marketing/socialmedia`, { replace: true });
          setValue('5')
        }
        else if (tmp[tmp.length - 1] === 'settings') {
          navigate(`/marketing/settings`, { replace: true });
          setValue('6')
        }
      } catch (error) {
        console.log('error');
      }

    })();
  }, [pathname]);


  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{
          paddingX: {xs:0, sm: 5}, position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'background.paper'
        }}>
          <TabList variant="scrollable" allowScrollButtonsMobile  sx={{ indicatorColor: '#5E17EB' }} onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Tableau de bord" value="1" />
            <Tab label="Campagnes" value="2" />
            <Tab label="Contacts" value="3" />
            <Tab label="Populations" value="4" />
            <Tab label="Reseau sociaux" value="5" />
            <Tab label="Paramêtres" value="6" />
          </TabList>
        </Box>
        <Box sx={{ height: 'calc(100vh - 143px)', overflowY: 'auto' }}>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="1">
            <Dashboard />
          </TabPanel>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="2">
            <CampaignCrm />
          </TabPanel>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="3">
            <ContactsCrm />
          </TabPanel>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="4">
            <PopulationCrm />
          </TabPanel>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="5">
            <CanalVente />
          </TabPanel>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="6">
            <Parameter />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}