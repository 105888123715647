import React, { useEffect, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';

function TemplateViewer({ selectedTemplate }) {
  const [templateData, setTemplateData] = useState(null);

  useEffect(() => {
    // Vérifie si `selectedTemplate` a bien des données
    if (selectedTemplate) {
      const data = selectedTemplate.data();
      if (data) {
        setTemplateData(data);
      }
    }
  }, [selectedTemplate]);

  return (
    <Box width="100%" padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
      <Card sx={{ padding: 2, minHeight: 500 }}>
        {templateData ? (
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <iframe
              title={templateData.titre}
              srcDoc={templateData.html}
              style={{ width: '100%', height: 450, border: 'none' }}
            />
            <Typography>{templateData.titre}</Typography>
          </Box>
        ) : (
          <Typography>Chargement...</Typography>
        )}
      </Card>
    </Box>
  );
}

export default TemplateViewer;
