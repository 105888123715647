/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Grid,
    CardActions,
    CardContent,
    Box,
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Chip,
    OutlinedInput,
    MenuItem,
    useTheme,
    Tooltip,
    IconButton,
    Backdrop,
    CircularProgress,
    Skeleton,
    InputAdornment,
    Menu,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';

// mock
import USERLIST from '../../../../_mock/user';

import { db } from '../../../../firebase.config';
import CheckCard from '../../../../components/CheckCard';
import { UserAuth } from '../../../../context/AuthContext';
import { Variables } from '../../../../context/VariableContext';
import CustomButton from '../../../../components/CustomButton';
import PdfRedditionDef from '../../../../components/pdf/comptabilite/redditionDef';

// ----------------------------------------------------------------------
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 7,
        border: '0.5px solid black',
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'white',
    border: '1px solid white',
    borderRadius: '7px',
    boxShadow: 0,
    '@media (max-width: 600px)': {
        width: '90%', // Redimensionne sur mobile
    },
};
// ----------------------------------------------------------------------




export default function Comptabilte() {
    const { eventID } = useParams();
    const { user } = UserAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const [orgID, setOrgID] = React.useState();

    // loading
    const [loading, setLoading] = React.useState(false);

    // GESTION DE LA MODIFICATION
    const { openDrawer, setOpenDrawer } = Variables();
    const { type, setType } = Variables();

    const handleImportation = () => {
        setType(6);
        setOpenDrawer(!openDrawer);
    };


    // for change value of globla quota
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [refreh, setRefresh] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        // setModif(false);
        setRefresh((prev) => !prev);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    // take operateurs from firebase
    const [versements, setVersements] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            setVersements([]);
            try {
                setLoading(true);

                const docRef0 = doc(db, 'organisateurs', `${user.uid}`);

                const docSnap = await getDoc(docRef0);
                /* if (docSnap.exists()) {
                  
                } */
                const q = query(collection(db, `organisations/${docSnap.data().idOrg}/accounting`));
                const snapshot = await getDocs(q);
                snapshot.forEach(async (d) => {
                    const docRef1 = doc(db, 'evenement', `${d.id}`);
                    const docSnap1 = await getDoc(docRef1);
                    if (docSnap1.exists()) {
                        setVersements((versements) => [...versements, docSnap1]);
                    }

                });
                setOrgID(docSnap.data().idOrg)
                setLoading(false);
            } catch (error) {
                console.log('error');
            }
        })();
    }, [refreh, openDrawer]);

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = (e) => {
        setAnchorEl(null);
        if (e === 0) {
            // setOpenDupplicate(true);
        } else if (e === 2) {
            // setOpenClos(true);
        }
    };

    const handleTimestamp = (e) => {
        console.log('handle time stamp')
        const date = e.toDate();
        return date;
    };

    // telecharger re
    const downloadSelectedTicket = async (index) => {
        /* try {
            const docSnap = await getDoc(doc(db, `evenement`, `${eventID}`));
            if (docSnap.exists()) {
                console.log(docSnap.data());
                console.log(billets[index]);
                const orgSnap = await getDoc(doc(db, `organisations`, `${docSnap.data().orgId}`));
                console.log(orgSnap.data());
                const tmp = {
                    billet: billets[index].data(),
                    event: docSnap.data(),
                    commande: commandes[index],
                    org: orgSnap.data(),
                };
                setInfo(tmp);
            }
        } catch (error) {
            console.log(error);
        } */
        // handleCloseMenuDetail();
        // handleOpenTicketModal();
        // handleCloseDownloadModal();
    };

    // Modal download Ticket
    const [openTicketModal, setOpenTicketModal] = React.useState(false);
    const handleOpenTicketModal = () => setOpenTicketModal(true);
    const handleCloseTicketModal = () => setOpenTicketModal(false);


    return (
        <Page title="Historique de reversement">
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container sx={{ marginTop: { xs: 3 } }}>
                <CheckCard />
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Historique de reversement
                    </Typography>
                </Stack>

                <Modal
                    open={openTicketModal}
                    onClose={handleCloseTicketModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ borderRadius: '7px' }}
                >
                    <Box sx={styleModal}>
                        <Typography variant="body2">
                            Cliquez{' '}
                            <PDFDownloadLink
                            // document={<PdfTicket info={info} qr={qrcode} />}
                            // fileName={`${info ? info.billet.refCommande : 'undefined'}.pdf`}
                            >
                                {({ blob, url, loading, error }) => {
                                    console.log('erreur : ', error);
                                    return loading ? 'Genration du ticket......' : 'ici';
                                }}
                            </PDFDownloadLink>{' '}
                            pour techarger le ticket
                        </Typography>
                    </Box>
                </Modal>

                <Card>
                    <Scrollbar>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="caption table">
                                <TableHead sx={{ bgcolor: 'gainsboro' }}>
                                    <TableRow>
                                        <TableCell>Evenement</TableCell>
                                        <TableCell>Date de début</TableCell>
                                        <TableCell>Date de Fin</TableCell>
                                        <TableCell>Reddition</TableCell>
                                        <TableCell>Statut</TableCell>
                                    </TableRow>
                                </TableHead>
                                {loading ? (
                                    <caption>
                                        <Skeleton variant="rounded" width="100%" />
                                    </caption>
                                ) : versements.length === 0 && !loading ? (
                                    <caption>
                                        <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                                            <Typography variant="caption">Aucune donnée</Typography>
                                        </Stack>
                                    </caption>
                                ) : (
                                    <TableBody>
                                        {versements.map((contact, index) => (
                                            <TableRow key={index} hover>
                                                <TableCell
                                                    id={contact.id}
                                                >
                                                    {contact.data().name ? contact.data().name : contact.data().nomEvent}
                                                </TableCell>
                                                <TableCell id={contact.id} >
                                                    <Typography variant='caption'>{format(new Date(handleTimestamp(contact.data().dateDebut)), 'EEE dd, MMMM yyyy', { locale: fr })}</Typography>
                                                </TableCell>
                                                <TableCell id={contact.id} >
                                                    <Typography variant='caption'>{format(new Date(handleTimestamp(contact.data().dateFin)), 'EEE dd, MMMM yyyy', { locale: fr })}</Typography>
                                                </TableCell>
                                                <TableCell id={contact.id} onClick={(e) => { }}>
                                                    <IconButton key={index} onClick={(e) => downloadSelectedTicket(index)}>
                                                        <Iconify icon="material-symbols-light:download" />
                                                        <Typography variant='caption'>temporaire</Typography>
                                                    </IconButton>
                                                    <IconButton key={index} onClick={(e) => downloadSelectedTicket(index)} sx={{ color: 'red' }}>
                                                        <Iconify icon="material-symbols-light:download" />
                                                        <Typography variant='caption'>Definitive</Typography>
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell id={contact.id} onClick={(e) => { }}>
                                                    {
                                                        contact.data().status === 0 ?
                                                            <Typography variant='body2'>Brouillon</Typography>
                                                            : contact.data().status === 1 ?
                                                                <Typography variant='body2'>Publié</Typography>
                                                                : contact.data().status === 2 ?
                                                                    <Typography variant='body2'>Clos</Typography>
                                                                    : null
                                                    }
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>

                <PDFViewer width='100%' height='700' showToolbar>
                    <PdfRedditionDef />
                </PDFViewer>
            </Container>
        </Page>
    );
}
