/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Row, Col } from 'antd';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { animated, useSpring } from 'react-spring';
import logo from '../../assets/logo/C.png';
import controle from '../../assets/images/scanner1.png';
import guichet from '../../assets/images/guichet.png';
import payway from '../../assets/images/payway.png';
import dashbord from '../../assets/images/dashboard1.png';
import Iconify from '../Iconify';
import DashFeaure from './dash';

const ContainerAnim = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  color: 'black',
});

const Line = styled('div')({
  display: 'flex',
  overflow: 'hidden',
});

const AnimatedBox = styled(animated.div)({
  margin: '10px',
  padding: 25,
  border: '1px solid #ccc',
  backgroundColor: 'white',
  borderRadius: '5px',
  width: 300,
  alignContent: 'center',
  textAlign: 'center',
});

function AppFeature() {
  const firstLineProps = useSpring({
    from: { transform: 'translateX(-100%)' },
    to: { transform: 'translateX(100%)' },
    loop: { reverse: true }, // This will make the animation reverse after completing
    config: { duration: 5000 }, // Adjust the duration as needed
  });

  const secondLineProps = useSpring({
    from: { transform: 'translateX(50%)' },
    to: { transform: 'translateX(-50%)' },
    loop: { reverse: true }, // This will make the animation reverse after completing
    config: { duration: 5000 },
  });

  return (
    <div id="feature" className="block featureBlock">
      <div className="container-fluid">
        <div>
          <Typography paddingTop={5} textAlign="center" variant="h2">
            Gérez facilement votre billetterie
          </Typography>
        </div>
        <DashFeaure />
       
        <Typography paddingTop={5} textAlign="center" variant="h4">
          Des outils pour chaque étape de votre événement.
        </Typography>
        <Grid container spacing={5} paddingY={5}>
          <Grid item xs={12} sm={4}>
            <Card sx={{ borderColor: '#FCEAB0', bgcolor: '#AED9E0', height: '100%', borderRadius: '15px' }}>
              { /* <CardMedia component="img" height="300" image={controle} alt="green iguana" /> */}
              <CardContent>
                <Typography variant="h5">Contrôle d’accès</Typography>
                <Typography paddingY={2}>
                  Contrôlez facilement les billets et droits de vos participants. Papier, application ou matériel
                  professionnel, vous avez le choix !
                </Typography>
                <Stack spacing={1} paddingY={2}>
                  <Stack direction="row">
                    <Iconify icon="bi:dot" sx={{ width: 40, height: 40, ml: 1 }} />
                    <Typography >Configuration sans limites</Typography>
                  </Stack>
                  <Stack direction="row">
                    <Iconify icon="bi:dot" sx={{ width: 40, height: 40, ml: 1 }} />
                    <Typography>Prise en main immédiate</Typography>
                  </Stack>
                  <Stack direction="row">
                    <Iconify icon="bi:dot" sx={{ width: 40, height: 40, ml: 1 }} />
                    <Typography >Fonctionnement online / offline</Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ borderColor: '#FCEAB0', height: '100%', bgcolor:'#FFE7D6', borderRadius: '15px' }}>
              
              <CardContent>
                <Typography variant="h5">Nos canaux de vente</Typography>
                <Typography paddingY={2}>
                  Avec Choptonticket, proposer plusieurs alternatives d'achat à votre public.{' '}
                </Typography>
                <Stack spacing={1} paddingY={2}>
                  <Stack direction="row">
                    <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                    <Typography>
                      Rapprochez vous de votre public et offrez leur une liberté sans pareil grace notre application
                      mobile{' '}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                    <Typography >
                      proposer egalement une plateforme web a votre public pour l'achat des tickets
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                    <Typography >
                      Vous disposez d'un site web ? vendez sur votre propre site web.
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ height: '100%', bgcolor: '#F1E4F1', borderRadius: '15px' }}>
          
              <CardContent>
                <Typography variant="h5">Guichet numérique (BIENTÔT)</Typography>
                <Typography paddingY={2}>
                  Ouvrer un point de vente ou vendez des tickets sur place le jour j grace au guichet numérique de
                  choptonticket
                </Typography>
                <Stack spacing={1} paddingY={2}>
                  <Stack direction="row">
                    <Iconify icon="bi:dot" sx={{ width: 40, height: 40, ml: 1 }} />
                    <Typography fontSize={18}>plusieurs gestionnaires</Typography>
                  </Stack>
                  <Stack direction="row">
                    <Iconify icon="bi:dot" sx={{ width: 40, height: 40, ml: 1 }} />
                    <Typography fontSize={18}>gestion approfondit des autorisations</Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      
      <Stack paddingTop={5} display='flex' alignItems='center'>
          <Button
            size="large"
            sx={{
              textTransform: 'initial',
              border: '2px solid transparent',
              color: 'white',
              backgroundColor: '#5E17EB',
              borderRadius: '7px',
              '&:hover': {
                backgroundColor: 'white',
                color: '#5E17EB',
                borderColor: '#5E17EB',
              },
            }}
            variant="contained"
            href="/bo"
          >
            Démarer maintenant
          </Button>
        </Stack>
    </div>
  );
}

export default AppFeature;
