/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Grid,
    CardActions,
    CardContent,
    Box,
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Chip,
    OutlinedInput,
    MenuItem,
    useTheme,
    Tooltip,
    IconButton,
    Backdrop,
    CircularProgress,
    Skeleton,
    InputAdornment,
    Menu,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';

// mock
import USERLIST from '../../../../_mock/user';

import { db } from '../../../../firebase.config';
import CheckCard from '../../../../components/CheckCard';
import { UserAuth } from '../../../../context/AuthContext';
import { Variables } from '../../../../context/VariableContext';
import CustomButton from '../../../../components/CustomButton';

// ----------------------------------------------------------------------
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 7,
        border: '0.5px solid black',
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));
// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

// for modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function Historique() {
    const { eventID } = useParams();
    const { user } = UserAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const [orgID, setOrgID] = React.useState();

    // loading
    const [loading, setLoading] = React.useState(false);

    // GESTION DE LA MODIFICATION
    const { openDrawer, setOpenDrawer } = Variables();
    const { type, setType } = Variables();

    const handleImportation = () => {
        setType(6);
        setOpenDrawer(!openDrawer);
    };


    // for change value of globla quota
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [refreh, setRefresh] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        // setModif(false);
        setRefresh((prev) => !prev);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    // take operateurs from firebase
    const [versements, setVersements] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            setVersements([]);
            try {
                setLoading(true);

                const docRef0 = doc(db, 'organisateurs', `${user.uid}`);

                const docSnap = await getDoc(docRef0);
                /* if (docSnap.exists()) {
                  
                } */
                const q = query(collection(db, `organisations/${docSnap.data().idOrg}/reversements`));
                const snapshot = await getDocs(q);
                snapshot.forEach(async (d) => {
                    const docRef1 = doc(db, 'evenement', `${d.data().idEvent}`);
                    const docSnap1 = await getDoc(docRef1);
                    if (docSnap1.exists()) {
                        setVersements((versements) => [...versements, {id: d.id, versement: d.data(), evenement: docSnap1.data()}]);
                    }
                    
                });
                setOrgID(docSnap.data().idOrg)
                setLoading(false);
            } catch (error) {
                console.log('error');
            }
        })();
    }, [refreh, openDrawer]);

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = (e) => {
        setAnchorEl(null);
        if (e === 0) {
            // setOpenDupplicate(true);
        } else if (e === 2) {
            // setOpenClos(true);
        }
    };

    return (
        <Page title="Historique de reversement">
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container sx={{ marginTop: { xs: 3 } }}>
                <CheckCard />
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Historique de reversement
                    </Typography>
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="caption table">
                                <TableHead sx={{ bgcolor: 'gainsboro' }}>
                                    <TableRow>
                                        <TableCell>Date de versement</TableCell>
                                        <TableCell>Evenement</TableCell>
                                        <TableCell>Moyen</TableCell>
                                        <TableCell>Montant versé (Recette totale - commission CTT)</TableCell>
                                    </TableRow>
                                </TableHead>
                                {loading ? (
                                    <caption>
                                        <Skeleton variant="rounded" width="100%" />
                                    </caption>
                                ) : versements.length === 0 && !loading ? (
                                    <caption>
                                        <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                                            <Typography variant="caption">Aucune donnée</Typography>
                                        </Stack>
                                    </caption>
                                ) : (
                                    <TableBody>
                                        {versements.map((contact, index) => (
                                            <TableRow key={index} hover>
                                                <TableCell
                                                    id={contact.id}
                                                >
                                                    {contact.versement?.data().dateVersement}
                                                </TableCell>
                                                <TableCell id={contact.id} >
                                                    <Typography variant='body2'>{contact.evenement?.data().name}</Typography>
                                                </TableCell>
                                                <TableCell id={contact.id} >
                                                    <Typography variant='body2'>{contact.versement?.data().moyen === 0 ? 'Compte bancaire' : 'Mobile Money'}</Typography>
                                                </TableCell>
                                                <TableCell id={contact.id} onClick={(e) => { }}>
                                                    {contact.versement?.data().montant} {contact.versement?.data().devise}
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>
            </Container>
        </Page>
    );
}
