/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  useTheme,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  InputAdornment,
  Skeleton,
  Menu,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, endBefore, getCountFromServer, getDoc, getDocs, limit, limitToLast, orderBy, query, startAfter } from 'firebase/firestore';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import Papa from 'papaparse';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';

// mock
import USERLIST from '../../../_mock/user';

import { db } from '../../../firebase.config';
import CheckCard from '../../../components/CheckCard';
import { UserAuth } from '../../../context/AuthContext';
import { Variables } from '../../../context/VariableContext';
import Swipeable from './swiper';
import useResponsive from '../../../hooks/useResponsive';
import CustomButton from '../../../components/CustomButton';

// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = ['Importer des contacts', 'Exporter en CSV'];

export default function ContactsCrm() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const { eventID } = useParams();
  const { user } = UserAuth();

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);

  const [refreh, setRefresh] = React.useState(false);

  // take operateurs from firebase
  const [contacts, setContact] = React.useState([]);
  const [curent, setCurent] = React.useState();
  const [tmp, setTmp] = React.useState();
  // const [type, setType] = React.useState();
  const [search, setSearch] = React.useState('');
  const [orgID, setOrgID] = React.useState(null);
  const { openDrawer, setOpenDrawer } = Variables();
  const { type, setType } = Variables();
  // paginnation variable
  const PAGE_SIZE = 3; // Nombre de contacts par page
  const [paginationState, setPaginationState] = useState({
    firstVisible: null,
    lastVisible: null,
    hasNextPage: true,
    hasPreviousPage: false,
  });
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [queryContains, setQueryContains] = React.useState([]);

  const fetchContacts = async (direction = 'initial') => {
    setLoading(true);
    let q = query(collection(db, `organisations/${orgID}/crm/${orgID}/contacts`), limit(PAGE_SIZE))

    try {
      if (direction === 'next' && paginationState.lastVisible) {
        q = q.startAfter(paginationState.lastVisible);
      } else if (direction === 'previous' && paginationState.firstVisible) {
        q = q.endBefore(paginationState.firstVisible).limitToLast(PAGE_SIZE);
      }

      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        snapshot.forEach(async (d) => {
          setContact((contacts) => [...contacts, d]);
        });
        setPaginationState({
          firstVisible: snapshot.docs[0],
          lastVisible: snapshot.docs[snapshot.docs.length - 1],
          hasNextPage: snapshot.docs.length === PAGE_SIZE,
          hasPreviousPage: direction !== 'initial',
        });
      } else if (direction === 'next') {
        setPaginationState((prev) => ({ ...prev, hasNextPage: false }));
      }
    } catch (err) {
      console.log('Erreur lors du chargement des contacts.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    if (page < newPage) {
      next();
    } else {
      console.log('back');
      back();
    }
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const next = async () => {
    const lastVisible = contacts[contacts.length - 1];
    const querySnapshot1 = await getDocs(
      query(
        collection(db, `organisations/${orgID}/crm/${orgID}/contacts`),
        ...queryContains,
        orderBy('createDate', 'desc'),
        startAfter(lastVisible),
        limit(PAGE_SIZE)
      )
    );
    setContact([]);
    querySnapshot1.forEach(async (d) => {
      setContact((billets) => [...billets, d]);
    });
  };

  const back = async () => {
    const lastVisible = contacts[0];
    const querySnapshot1 = await getDocs(
      query(
        collection(db, `organisations/${orgID}/crm/${orgID}/contacts`),
        ...queryContains,
        orderBy('createDate', 'desc'),
        endBefore(lastVisible),
        limitToLast(PAGE_SIZE)
      )
    );
    setContact([]);
    querySnapshot1.forEach(async (d) => {
      setContact((billets) => [...billets, d]);
    });
  };


  React.useEffect(() => {
    (async () => {
      try {
        // const term = searchParams.get('q');
        // console.log('params: ', term);
        const query1 = [];
        setLoading(true);
        setContact([]);
        // setBillet([]);
        /* if (term) {
          //
          const tmpfilter = term.split('&');
          console.log(tmpfilter);
          tmpfilter.forEach((element) => {
            if (element.includes('fs')) {
              console.log('filter statut');
              const tmp = element.split('=');
              console.log(tmp);
              const tmp1 = tmp[1].split(',');
              console.log(tmp1);
              const filterStatut = [];
              tmp1.forEach((element) => {
                filterStatut.push(parseInt(element));
              });
              if (filterStatut.length !== 0) {
                setQueryContains((query) => [...query, where('status', 'in', filterStatut)]);
                query1.push(where('status', 'in', filterStatut));
              }
            }
            if (element.includes('fo')) {
              console.log('filter origine');
            }
            if (element.includes('fb')) {
              console.log('filter billet');
              const tmp = element.split('=');
              console.log(tmp);
              const filterBillet = tmp[1].split(',');
              console.log(filterBillet);
              if (filterBillet.length !== 0) {
                setQueryContains((query) => [...query, where('refTicket', 'in', filterBillet)]);
                query1.push(where('refTicket', 'in', filterBillet));
              }
            }
            if (element.includes('fa')) {
              const today = new Date();
              const date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
              const ttmp = new Date(today.getTime() + 24 * 60 * 60 * 1000);
              const tomorrow = new Date(ttmp.getFullYear(), ttmp.getMonth(), ttmp.getDate());
              console.log('filter date ajout');
              const tmp = element.split('=');
              console.log(tmp);
              const filterHist = tmp[1];
              console.log(filterHist);
              if (filterHist === 'today') {
                console.log('add filtre ajout');
                query1.push(where('createDate', '>=', date));
                query1.push(where('createDate', '<', new Date(today.getTime() + 24 * 60 * 60 * 1000)));
                setQueryContains((query) => [...query, where('createDate', '>=', date)]);
                setQueryContains((query) => [
                  ...query,
                  where('createDate', '<', new Date(today.getTime() + 24 * 60 * 60 * 1000)),
                ]);
              } else if (filterHist === 'yesterday') {
                query1.push(where('createDate', '<=', date));
                query1.push(where('createDate', '>=', new Date(date.getTime() - 24 * 60 * 60 * 1000)));
                setQueryContains((query) => [...query, where('createDate', '<=', date)]);
                setQueryContains((query) => [
                  ...query,
                  where('createDate', '>=', new Date(date.getTime() - 24 * 60 * 60 * 1000)),
                ]);
              } else if (filterHist === 'last-7-days') {
                query1.push(where('createDate', '<=', date));
                query1.push(where('createDate', '>=', new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)));
                setQueryContains((query) => [...query, where('createDate', '<=', date)]);
                setQueryContains((query) => [
                  ...query,
                  where('createDate', '>=', new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)),
                ]);
              } else if (filterHist === 'last-14-days') {
                query1.push(where('createDate', '<=', date));
                query1.push(where('createDate', '>=', new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000)));
                setQueryContains((query) => [...query, where('createDate', '<=', date)]);
                setQueryContains((query) => [
                  ...query,
                  where('createDate', '>=', new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000)),
                ]);
              } else if (filterHist === 'last-30-days') {
                query1.push(where('createDate', '<=', date));
                query1.push(where('createDate', '>=', new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)));
                setQueryContains((query) => [...query, where('createDate', '<=', date)]);
                setQueryContains((query) => [
                  ...query,
                  where('createDate', '>=', new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)),
                ]);
              }
            }
          });
        } else {
          //
        } */
        // count
        const snapshot = await getCountFromServer(query(collection(db, `organisations/${orgID}/crm/${orgID}/contacts`),orderBy('createDate', 'desc'), ...query1));
        setCount(snapshot.data().count);
        // get participants
        const first = query(
          collection(db, `organisations/${orgID}/crm/${orgID}/contacts`),
          orderBy('createDate', 'desc'),
          ...query1,
          limit(PAGE_SIZE)
        );
        const querySnapshot1 = await getDocs(first);
        querySnapshot1.forEach(async (d) => {
          setContact((contacts) => [...contacts, d]);
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      setLoading(false);
    })();
  }, [user,refreh, orgID]);

  React.useEffect(() => {
    (async () => {
      setContact([]);
      try {
        setLoading(true);
        setLoadingPag(true);
        const docRef0 = doc(db, 'organisateurs', `${user.uid}`);
        const docSnap = await getDoc(docRef0);
        // const q = query(collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/contacts`));
        // const q = query(collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/contacts`));
        // const snapshot = await getDocs(q);
        setOrgID(docSnap.data().idOrg)
        /* snapshot.forEach(async (d) => {
          setContact((contacts) => [...contacts, d]);
        }); */
      } catch (error) {
        console.log('error');
      }
      setLoading(false);
      setLoadingPag(false);
    })();
  }, [user, refreh]);

  React.useEffect(() => {
    (async () => {
      setContact([]);
      try {
        // fetchContacts()
      } catch (error) {
        console.log('error');
      }
      setLoading(false);
      setLoadingPag(false);
    })();
  }, [orgID]);

  // loading
  const [loading, setLoading] = React.useState(false);
  const [loadingPag, setLoadingPag] = React.useState(false);

  const handleModif = async (index) => {
    try {
      const docRef0 = doc(db, 'users', `${contacts[index].data().uid}`);
      const docSnap = await getDoc(docRef0);
      if (docSnap.exists()) {
        setCurent(docSnap?.data());
      } else {
        console.log('client n existe pas')
        console.log(contacts[index].data())
        setCurent(contacts[index].data());
        // setIDContact(contacts[index].id)
      }

    } catch (error) {
      console.log('client n existe pas1')
      console.log(error)
      setCurent(contacts[index].data());
      // setIDContact(contacts[index].id)
    }
    setType(1);
    setTmp(index)
    setOpenDrawer(!openDrawer);
  };

  const handleFilter = () => {
    setType(2);
    setOpenDrawer(!openDrawer);
  };
  const handleImportation = () => {
    setType(3);
    setOpenDrawer(!openDrawer);
  };

  const handleSearch = async (tmp) => {
    setSearch(tmp);
    if (search.length > 3) {
      // loading
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };




  return (
    <Page title="Contacts">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <CheckCard />
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <Typography variant="h2" gutterBottom>
            Contacts
          </Typography>
        </Stack>
        <Grid container paddingBottom={3}>
          <Grid item xs={5}>
            <Stack direction='row' spacing={2}>
              <Stack spacing={1} direction='row' onClick={handleImportation} sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} padding={1} >
                <Iconify icon="material-symbols-light:add" sx={{ width: 20, height: 20 }} />
                <Typography variant='body2'><strong>Importer des contacts</strong></Typography>
              </Stack>
              <Stack spacing={1} direction='row' onClick={handleImportation} sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} padding={1} >
                <Iconify icon="material-symbols-light:download" sx={{ width: 20, height: 20 }} />
                <Typography variant='body2'><strong>Exporter en CSV</strong></Typography>
              </Stack>
            </Stack>
          </Grid>

        </Grid>
        <Grid container spacing={2} paddingBottom={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={12} sm={4} md={6}>
            {loading ? (
              <Skeleton width="20%">
                <Typography>.</Typography>
              </Skeleton>
            ) : (
              <Typography variant="h6">{contacts.length} contacts</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={8} md={6}>

            <Stack direction='row' spacing={2}>
              <TextField value={search} onChange={(e) => handleSearch(e.target.value)} InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="iconoir:search" />
                  </InputAdornment>
                ),
              }} placeholder="Rechercher" fullWidth />
              <CustomButton type='primaire' startIcon={<Iconify icon="circum:filter" />} buttonText='Filtre' onClick={handleFilter} />
            </Stack>
          </Grid>
        </Grid>
        <Box>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="caption table">
                <TableHead sx={{ bgcolor: '#f2f2f2' }}>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Pays</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Genre</TableCell>
                    <TableCell>Abonné</TableCell>
                    <TableCell>Notif push</TableCell>
                    <TableCell>Newsletters</TableCell>
                    <TableCell>Date d'ajout</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <caption>
                    <Skeleton variant="rounded" width="100%" />
                  </caption>
                ) : contacts.length === 0 && !loading ? (
                  <caption>
                    <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                      <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                      <Typography variant="caption">Aucune donnée</Typography>
                    </Stack>
                  </caption>
                ) : (
                  <TableBody>
                    {contacts.map((contact, index) => (
                      <TableRow key={index} hover>
                        <TableCell
                          id={contact.data().nom}
                          onClick={(e) => handleModif(index)}
                          key={contact.data().nom}
                          component="th"
                          scope="row"
                        >
                          <Stack>
                            <Stack direction="row">
                              {contact.data().nom} {contact.data().prenom}
                            </Stack>
                            {contact.id}
                          </Stack>
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().pays ? contact.data().pays : '-'}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().age ? contact.data().age : '-'}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().genre ? contact.data().genre : '-'}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().push || contact.data().newslettres ? (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#C9E4D4',
                                border: '1px dashed #C9E4D4',
                              }}
                            >
                              oui
                            </Box>
                          ) : (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#FB8888',
                                border: '1px dashed #FB8888',
                              }}
                            >
                              non
                            </Box>
                          )}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().push ? (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#C9E4D4',
                                border: '1px dashed #C9E4D4',
                              }}
                            >
                              oui
                            </Box>
                          ) : (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#FB8888',
                                border: '1px dashed #FB8888',
                              }}
                            >
                              non
                            </Box>
                          )}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().newslettres ? (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#C9E4D4',
                                border: '1px dashed #C9E4D4',
                              }}
                            >
                              oui
                            </Box>
                          ) : (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#FB8888',
                                border: '1px dashed #FB8888',
                              }}
                            >
                              non
                            </Box>
                          )}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().createDate ? format(new Date(handleTimestamp(contact.data().createDate)), 'dd/MM/yy', {
                            locale: fr,
                          }).toString() : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={count}
                rowsPerPage={PAGE_SIZE}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </Scrollbar>
        </Box>
        <Swipeable type={type} user={curent} email={tmp ? contacts[tmp].id : ''} nbTickets={tmp ? contacts[tmp].data().tickets : 0} nbEvents={tmp ? contacts[tmp].data().events?.length : 0} nbDepense={tmp ? contacts[tmp].data().depenses : 0} organisation={orgID} />
      </Container>
    </Page>
  );
}
