import React, { useState } from 'react';
import { TextField, MenuItem, Select, Grid, InputLabel, FormControl, useMediaQuery, Box } from '@mui/material';
import { LocalizationProvider, DatePicker, } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Iconify from './Iconify';


const CustomDateTimePicker = ({ date, time, onDateChange, onTimeChange, minDate, errorDate, errorHour }) => {
    const [selectedDate, setSelectedDate] = useState(date);
    const [selectedTime, setSelectedTime] = useState(time);
    const [error, setError] = useState(false);

    const today = new Date();
    const minSelectableDate = minDate ? new Date(minDate) : today;

    // Générer les options pour l'heure et les minutes combinées (00:00 - 23:45 par intervalles de 15 minutes)
    const times = [];
    // eslint-disable-next-line no-plusplus
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const hourStr = String(hour).padStart(2, '0');
            const minuteStr = String(minute).padStart(2, '0');
            times.push(`${hourStr}:${minuteStr}`);
        }
    }

    const isMobile = useMediaQuery('(max-width:600px)');
    const menuHeight = isMobile ? '200px' : '300px';

    // Gérer le changement de date
    const handleDateChange = (newValue) => {
        /* console.log("change date")
        console.log(format((new Date(newValue)), 'dd/MM/yyyy', { locale: fr }))
        console.log(newValue) */
        setSelectedDate(newValue);
        onDateChange(newValue);
    };

    // Gérer le changement de temps
    const handleTimeChange = (event) => {
        console.log('enfant')
        const newValue = event.target.value;
        // const tmpDateDebut = new Date(selectedDate);
        // Utiliser setHours pour remplacer l'heure et les minutes
        // tmpDateDebut.setHours(newValue.split(':')[0], newValue.split(':')[1], 0);
        // console.log(tmpDateDebut)
        setSelectedTime(newValue);
        onTimeChange(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <Grid container spacing={3}>
                {/* Sélection de la date */}
                <Grid item xs={12} sm={6}>
                    <DatePicker

                        value={selectedDate}
                        onChange={handleDateChange}
                        minDate={minSelectableDate}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                error={errorDate}
                                placeholder="Choisissez une date" // Placeholder pour le champ de date
                                InputProps={{
                                    ...params.InputProps,
                                    // Utilisation d'un placeholder via `inputProps` si nécessaire
                                    'aria-placeholder': 'Choisissez une date'
                                }}
                            />
                        )}
                    />
                </Grid>

                {/* Sélection de l'heure */}
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-name-label">heure</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age"
                            value={selectedTime}
                            error={errorDate}
                            onChange={handleTimeChange}
                            IconComponent={() => <Box paddingRight={2}><Iconify icon='mdi:clock-outline' sx={{ height: 20, width: 20 }} /></Box>}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: menuHeight, // Définir la hauteur max du menu
                                        // Correspond à la largeur du champ
                                    },
                                },
                            }}
                        >
                            <MenuItem value='' disabled>
                                Choisisser une heure
                            </MenuItem>
                            {times.map((time) => (
                                <MenuItem key={time} value={time}>
                                    {time}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default CustomDateTimePicker;