/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Row, Col } from 'antd';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { animated, useSpring } from 'react-spring';
import logo from '../../assets/logo/C.png';
import controle from '../../assets/images/scanner1.png';
import guichet from '../../assets/images/guichet.png';
import payway from '../../assets/images/payway.png';
import dashbord from '../../assets/images/dashboard1.png';
import Iconify from '../Iconify';
import DashFeaure from './dash';

const ContainerAnim = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    color: 'black',
});

const Line = styled('div')({
    display: 'flex',
    overflow: 'hidden',
});

const AnimatedBox = styled(animated.div)({
    margin: '10px',
    padding: 25,
    border: '1px solid #ccc',
    backgroundColor: 'white',
    borderRadius: '5px',
    width: 300,
    alignContent: 'center',
    textAlign: 'center',
});

function Functions() {
    const firstLineProps = useSpring({
        from: { transform: 'translateX(-100%)' },
        to: { transform: 'translateX(100%)' },
        loop: { reverse: true }, // This will make the animation reverse after completing
        config: { duration: 5000 }, // Adjust the duration as needed
    });

    const secondLineProps = useSpring({
        from: { transform: 'translateX(50%)' },
        to: { transform: 'translateX(-50%)' },
        loop: { reverse: true }, // This will make the animation reverse after completing
        config: { duration: 5000 },
    });

    return (
        <div id="feature" className="block featureBlock">
            <div className="container-fluid">
                <Stack spacing={4} paddingTop={5}>
                    <div>
                        <Stack>
                            <Typography textAlign="center" variant="subtitle1">
                                Billeterie
                            </Typography>
                            <Typography textAlign="center" variant="h2">
                                VENDEZ PLUS,
                                PLUS TÔT
                            </Typography>
                        </Stack>

                    </div>
                    <DashFeaure />

                    <Grid container spacing={{xs:1, sm:5}} rowGap={{xs:5, sm:1}}>
                        <Grid item xs={12} sm={6}>
                            <Stack direction='row' alignItems='start' spacing={1}>
                                <Iconify icon="mingcute:settings-2-line" sx={{ minWidth: {xs:40, sm:80}, minHeight: {xs:40, sm:80} }} />
                                <Stack spacing={1}>
                                    <Typography variant='h5'>
                                        Configurez vos tickets en fonction de votre stratégie.
                                    </Typography>
                                    <Typography variant='body1' fontSize={18}>

                                        Définissez votre jauge, créez plusieurs catégories et types de billets, décidez quand ils doivent être disponibles et personnalisez-les. Créez des offres spéciales pour les réseaux alternatifs et vos ambassadeurs.
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack direction='row' alignItems='start' spacing={1}>
                                <Iconify icon="material-symbols-light:order-approve-outline" sx={{ minWidth: {xs:40, sm:80}, minHeight: {xs:40, sm:80} }} />
                                <Stack spacing={1}>
                                    <Typography variant='h5'>
                                        Vue d'ensemble de toutes vos commandes.
                                    </Typography>
                                    <Typography variant='body1' fontSize={18}>
                                        Vous pouvez filtrez par tags, recherchez via code-barres et exportez vos données en toute autonomie.
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack direction='row' alignItems='start' spacing={1}>
                                <Iconify icon="carbon:security" sx={{ minWidth: {xs:40, sm:80}, minHeight: {xs:40, sm:80} }} />
                                <Stack spacing={1}>
                                    <Typography variant='h5'>
                                        Permettez à vos clients d'acheter et de revendre leurs billets en toute sécurité dans notre application.
                                    </Typography>
                                    <Typography variant='body1' fontSize={18}>
                                        Nous avons conçu notre propre outil intégrée de revente de billets pour lutter contre le marché noir, une fonctionnalité très appréciée par nos utilisateurs !
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack direction='row' alignItems='start' spacing={1}>
                                <Iconify icon="solar:share-circle-line-duotone" sx={{ minWidth: {xs:40, sm:80}, minHeight: {xs:40, sm:80} }} />
                                <Stack spacing={1}>
                                    <Typography variant='h5'>
                                        Votre stratégie de distribution multiple
                                    </Typography>
                                    <Typography variant='body1' fontSize={18}>
                                        Grâce à notre outil d'intégration des ventes externes vous pouvez importer les ventes que vous avez générées sur d'autres distributeurs pour les centraliser sur une seule plateforme.
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                    </Grid>
                    <Box paddingTop={8}>
                        <div>
                            <Stack>
                                <Typography textAlign="center" variant="subtitle1">
                                    Marketing
                                </Typography>
                                <Typography textAlign="center" variant="h2">
                                    FAITES-VOUS CONNAÎTRE ET FIDELISEZ VOTRE AUDIENCE.
                                </Typography>
                            </Stack>

                        </div>
                    </Box>
                    <Grid container spacing={5} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                                <Typography variant='h3'>
                                    Engagez votre audience pour augmenter les premières ventes.
                                </Typography>
                                <Typography variant='body1' fontSize={18}>

                                    Engagez votre communauté sur votre page organisateur disponible sur l'app, le site et en widget. Vos abonnés seront instantanément avertis lorsque vous publiez un nouvel événement, ce qui signifie des ventes plus tôt !


                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack direction='row' alignItems='start'>
                                <Iconify icon="material-symbols-light:order-approve-outline" sx={{ minWidth: 80, minHeight: 80 }} />
                                <Stack spacing={1}>
                                    <Typography variant='h5'>
                                        Vue d'ensemble de toutes vos commandes.
                                    </Typography>
                                    <Typography variant='body1' fontSize={18}>
                                        Vous pouvez filtrez par tags, recherchez via code-barres et exportez vos données en toute autonomie.
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={12} sm={6}>
                            <Stack direction='row' alignItems='start'>
                                <Iconify icon="material-symbols-light:order-approve-outline" sx={{ minWidth: 80, minHeight: 80 }} />
                                <Stack spacing={1}>
                                    <Typography variant='h5'>
                                        Vue d'ensemble de toutes vos commandes.
                                    </Typography>
                                    <Typography variant='body1' fontSize={18}>
                                        Vous pouvez filtrez par tags, recherchez via code-barres et exportez vos données en toute autonomie.
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                                <Typography variant='h3'>
                                    Boostez vos ventes avec l'affiliation !
                                </Typography>
                                <Typography variant='body1' fontSize={18}>
                                    Vous organisez des événements et cherchez à vendre vos billets plus rapidement ? Notre fonctionnalité d'affiliation vous permet de collaborer avec des influenceurs et partenaires pour augmenter votre visibilité et vos ventes !
                                </Typography>
                                <Stack direction="row" alignItems="center">
                                    <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                                    <Typography fontSize={18}>Partenariats simplifiés : Créez des affiliations en quelques clics avec vos partenaires.</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                                    <Typography fontSize={18}>Suivi en temps réel : Suivez les performances de vos affiliés avec des statistiques précises sur les clics et les ventes générées.</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                                    <Typography fontSize={18}>Augmentez vos revenus : Profitez de la puissance des réseaux d'influenceurs pour maximiser la promotion de vos événements.</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Iconify icon="bi:dot" sx={{ minWidth: 40, minHeight: 40, ml: 1 }} />
                                    <Typography fontSize={18}>Automatisation des commissions : Définissez des taux de commission et laissez notre système gérer les paiements automatiquement.</Typography>
                                </Stack>
                            </Stack>
                        </Grid>

                    </Grid>
                    <Grid container spacing={5} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                                <Typography variant='h3'>
                                    Communiquez sans limite.
                                </Typography>
                                <Typography variant='body1' fontSize={18}>
                                    Envoyez de belles campagnes de mailing directement depuis Smartboard, sans limite d'envois ou de contacts, et obtenez un taux d'ouverture élevé en matière de mailing informatif pour assurer que votre public soit bien informé !
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack direction='row' alignItems='start'>
                                <Iconify icon="material-symbols-light:order-approve-outline" sx={{ minWidth: 80, minHeight: 80 }} />
                                <Stack spacing={1}>
                                    <Typography variant='h5'>
                                        Vue d'ensemble de toutes vos commandes.
                                    </Typography>
                                    <Typography variant='body1' fontSize={18}>
                                        Vous pouvez filtrez par tags, recherchez via code-barres et exportez vos données en toute autonomie.
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </div>

            <div className="aboutBlock bgService1">
                <Container sx={{ paddingY: 10, marginTop: 8, color: 'white' }}>
                    <div>
                        <Stack paddingBottom={8}>
                            <Typography color="white" textAlign="center" variant="subtitle1">
                                Type d'evenement
                            </Typography>
                            <Typography color="white" textAlign="center" variant="h2">
                                LA SOLUTION PARFAITE POUR CHAQUE TYPE D'EVENT AVEC CHOPTONTICKET.
                            </Typography>
                        </Stack>

                    </div>
                    
                    <ContainerAnim>
                        <Line>
                            <AnimatedBox style={firstLineProps}>
                                <Typography variant="body1">Ateliers & Formation</Typography>
                            </AnimatedBox>
                            <AnimatedBox style={firstLineProps}>
                                <Typography variant="body1">Concerts</Typography>
                            </AnimatedBox>
                            <AnimatedBox style={firstLineProps}>
                                <Typography variant="body1">Activités sportives</Typography>
                            </AnimatedBox>
                            <AnimatedBox style={firstLineProps}>
                                <Typography variant="body1">Spéctacles</Typography>
                            </AnimatedBox>
                            <AnimatedBox style={firstLineProps}>
                                <Typography variant="body1">Défilés</Typography>
                            </AnimatedBox>
                        </Line>
                        <Line>
                            <AnimatedBox style={secondLineProps}>
                                <Typography variant="body1">Gala / Soirées</Typography>
                            </AnimatedBox>
                            <AnimatedBox style={secondLineProps}>
                                <Typography variant="body1">Expositions</Typography>
                            </AnimatedBox>
                            <AnimatedBox style={secondLineProps}>
                                <Typography variant="body1">Forums</Typography>
                            </AnimatedBox>

                            <AnimatedBox style={secondLineProps}>
                                <Typography variant="body1">Conférences</Typography>
                            </AnimatedBox>
                            <AnimatedBox style={secondLineProps}>
                                <Typography variant="body1">Formations / Atéliers</Typography>
                            </AnimatedBox>
                        </Line>
                    </ContainerAnim>
                </Container>

            </div>

        </div>
    );
}

export default Functions;
