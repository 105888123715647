import React from 'react';
import { Box, Snackbar } from '@mui/material';
import Iconify from './Iconify';

const CustomSnack = ({ open, handleClose, msg }) => (
    <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'space-between' }}>
                {msg?.content}{' '}
                <Box paddingX={1} />
                <Iconify
                    sx={{ color: msg?.error ? 'red' : 'green', height: 20, width: 20 }}
                    icon={`${msg?.error ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
                />
            </Box>
        }
    />
);

export default CustomSnack;