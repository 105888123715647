/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import { faker } from '@faker-js/faker';
// @mui
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Stack,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Link,
  Snackbar,
  Alert,
  AlertTitle,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  InputAdornment,
  makeStyles,
  InputLabel,
} from '@mui/material';

import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
// components

import { collection, doc, Firestore, getDoc, getDocs, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
// import MUIRichTextEditor from "mui-rte";
import ReactQuill from 'react-quill';
import { db, storage } from '../../firebase.config';

import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import CustomButton from '../../components/CustomButton';
import CheckCard from '../../components/CheckCard';
import 'react-quill/dist/quill.snow.css';
// eslint-disable-next-line import/order
import styled from 'styled-components';
import { Variables } from '../../context/VariableContext';
import Maps from '../../components/map/Maps';
import '../editorStyles.css'; //
// eslint-disable-next-line import/order
import CustomDateTimePicker from '../../components/CustomDateTimePicker';


// sections

// ----------------------------------------------------------------------

// const getHtml = editorState => draftToHtml(convertToRaw(editorState.getCurrentContent()));
// ----------------------------------------------------------------------
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0.5,
    width: 100,
    border: 0,
    // background: 'black',
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      border: 0,
      background: '#5E17EB',
      color: 'white',
    },
    '&:not(:first-of-type)': {
      // background: 'red',
      borderRadius: '7px',
    },
    '&:first-of-type': {
      // background: 'black',
      borderRadius: '7px',
    },
  },
}));
//-----------------------------------------------------------------------
console.log(process.env.REACT_APP_MAP_API_KEY);
export default function EventData() {
  // quill rich text
  const modules = {
    toolbar: [
      // [{ 'font': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
      ['clean'],
    ],
  };

  const formats = [
    // 'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];

  const [value, setValue] = useState('');

  const handleChangeQuill = (content, delta, source, editor) => {
    console.log(editor.getHTML()); // html 사용시
    // console.log(JSON.stringify(editor.getContents())); // delta 사용시
    setValue(editor.getHTML());
  };

  // rich text
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  const affiche = () => {
    console.log('salut');
    console.log(editorState.getCurrentContent());
  };
  const theme = useTheme();

  const { eventID } = useParams();

  const { generalReload, setGeneralReload } = Variables();

  const [events, setEvents] = React.useState([]);
  const [description, setDescription] = React.useState([]);
  const [debut, setDebut] = React.useState();
  const [fin, setFin] = React.useState();
  const [debutError, setDebutError] = React.useState(false);
  const [finError, setFinError] = React.useState(false);
  const [debutErrorMsg, setDebutErrorMsg] = React.useState('');
  const [finErrorMsg, setFinErrorMsg] = React.useState('');
  const [lieu, setLieu] = React.useState('');
  const [lieuError, setLieuError] = React.useState(false);
  const [infoLieu, setInfo] = React.useState('');
  const [ville, setVille] = React.useState('');
  const [pays, setPays] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [urlImage, setImage] = React.useState();
  const [imageTmp, setImageTmp] = React.useState();
  const [typeField, setType] = React.useState('');
  const [nameField, setName] = React.useState('');
  const [locationField, setLocation] = React.useState('');
  const [locationField2, setLocation2] = React.useState('');
  const [seance, setActiveSeance] = React.useState(false);

  // date / time
  const [dateError, setDateError] = useState(false);
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  const [dateDebut, setDateDebut] = useState(null);
  const [timeDebut, setTimeDebut] = useState('');


  const handleDateDebutChange = (newDate) => {
    setDateDebut(newDate);
  };

  const handleTimeDebutChange = (newTime) => {
    console.log('parent')
    setTimeDebut(newTime);
  };
  const [dateFin, setDateFin] = useState(null);
  const [timeFin, setTimeFin] = useState('');

  const handleDateFinChange = (newDate) => {
    setDateFin(newDate);
  };

  const handleTimeFinChange = (newTime) => {
    setTimeFin(newTime);
  };

  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };

  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRefresh((prev) => !prev);
  };

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,  // Format 24h
    timeZone: 'Europe/Paris'  // Remplacer par ton fuseau horaire si différent
  };

  React.useEffect(() => {
    (async () => {
      try {
        console.log("get data");
        setEvents([]);
        setLoading(true);
        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          createEventList(docSnap.data());
          const element = docSnap.data();
          if (element.idVisuel) {
            setImageUrl(element.idVisuel);
            setImageTmp(element.idVisuel);
          }
          setName(element.nomEvent);
          setType(element.type);
          setDebut(handleTimestamp(element.dateDebut));
          console.log(element.dateDebut)
          setDateDebut(handleTimestamp(element.dateDebut))
          setTimeDebut(handleTimestampCustom(element.dateDebut))
          setFin(handleTimestamp(element.dateFin));
          setDateFin(handleTimestamp(element.dateFin))
          setTimeFin(handleTimestampCustom(element.dateFin))
          setLieu(element.lieu_event);
          setInfo(element.info);
          if (element.seanceActive) {
            setActiveSeance(1);
          } else {
            setActiveSeance(0);
          }
          setActiveSeance(element.seanceActive);
          if (element.city) {
            setVille(element.city);
          }
          if (element.country) {
            setPays(element.country);
          }
          if (element.localisation) {
            setLocation(element.localisation);
          }
          if (element.localisationDes) {
            setLocation2(element.localisationDes);
          }

          const q = query(collection(db, `evenement/${docSnap.id}/description`));
          const snapshot = await getDocs(q);
          snapshot.forEach((d) => {
            setDescription((description) => [d]);
            setDesc(d.data().valeur);
            if (d.data().valeur) {
              // setEditorState(() => EditorState.createWithContent(convertFromHTML(d.data().valeur)));
              setValue(d.data().valeur);
            } else {
              // setEditorState(() => EditorState.createWithContent(convertFromHTML(d.data().value)));
              setValue(d.data().value);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [refreh]);

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  // upload functions

  const [selectedImage, setSelectedImage] = React.useState();
  const [imageUrl, setImageUrl] = React.useState();

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  // --------------------

  const handlePays = (e) => {
    setPays(e.target.value);
  };

  const handleType = (e) => {
    setType(e.target.value);
  };

  // loading
  const [loading, setLoading] = React.useState(false);

  // convert timestamp

  const handleTimestamp = (e) => {
    console.log('handle time stamp')
    const date = e.toDate();
    return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
  };

  const handleTimestampCustom = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.')[0].split('T')[1].split(':');
    return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
  };

  // fonction de mise à jour

  const handleToggle = () => {
    setOpen(!open);
  };

  const update = async () => {
    try {
      handleToggle();

      const statRef = doc(db, `evenement`, `${eventID}`);
      console.log('update')
      const tmpDateDebut = new Date(dateDebut);
      // Utiliser setHours pour remplacer l'heure et les minutes
      tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
      const tmpDateFin = new Date(dateFin);
      // Utiliser setHours pour remplacer l'heure et les minutes
      tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);
      if (tmpDateDebut > tmpDateFin) {
        setDateError(true)
        setDateErrorMsg('la date de debut doit être avant à la date de fin')
        setMsg({ content: 'une erreur est survenue', color: 'red' });
        setOpenSnack(true);
        handleClose();
        return
        // eslint-disable-next-line no-else-return
      } else {
        setDateError(false)
      }
      await updateDoc(statRef, {
        nomEvent: nameField,
        type: typeField,
        city: ville,
        info: infoLieu,
        lieu_event: lieu,
        localisation: locationField,
        localisationDes: locationField2,
        dateDebut: Timestamp.fromDate(tmpDateDebut),
        dateFin: Timestamp.fromDate(tmpDateFin),
        country: pays,
      });
      if (value !== description[0].data().valeur) {
        // const contentState = editorState.getCurrentContent();
        // const note = convertToHTML(contentState);
        const note = value;
        console.log('note 1 : ', note);
        // note = JSON.stringify(note)
        console.log(note);
        console.log('mise a jour la description');
        const refDesc = doc(db, `evenement/${eventID}/description`, `${description[0].id}`);
        await updateDoc(refDesc, {
          valeur: note,
        });
      }
      if (imageUrl !== imageTmp) {
        console.log('mise a jour de l image');
        let uri;
        if (selectedImage) {
          const storageRef = ref(storage, `/eventImages/${events[0].nomEvent}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
          await uploadBytes(storageRef, selectedImage).then((snapshot) => {
            getDownloadURL(snapshot.ref).then(async (downloadURL) => {
              console.log('download', downloadURL);
              setImageUrl(downloadURL);
              const tt = await updateDoc(statRef, {
                idVisuel: downloadURL,
              });
              setGeneralReload(!generalReload);
            });
          });
        }
        // const refDesc = doc(db, `evenement/${eventID}/description`, `${description[0].id}`);
        console.log(uri);
      }
      setMsg({ content: 'vos modifications ont été enregistrer avec success', color: 'green' });
      setOpenSnack(true);
    } catch (error) {
      console.log('erro');
      console.log(error.message);
      setMsg({ content: 'une erreur est survenue', color: 'red' });
      setOpenSnack(true);
    }
    handleClose();
  };
  // gestion activation seance
  const handleSeance = async (e) => {
    try {
      console.log(e.target.value);
      if (e.target.value === '1') {
        await activeSeance();
      } else {
        await desactiveSeance();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // activer le mode seance
  const activeSeance = async () => {
    try {
      handleToggle();
      const statRef = doc(db, `evenement`, `${eventID}`);

      await updateDoc(statRef, {
        seanceActive: 1,
      });
      setMsg({ content: 'Le mode séance est activé ', color: 'green' });
      setOpenSnack(true);
    } catch (error) {
      handleClose();
      console.log(error.message);
      setMsg({ content: 'une erreur est survenue', color: 'red' });
      setOpenSnack(true);
    }
    handleClose();
  };
  // activer le mode seance
  const desactiveSeance = async () => {
    try {
      handleToggle();
      const statRef = doc(db, `evenement`, `${eventID}`);

      await updateDoc(statRef, {
        seanceActive: 0,
      });
      setMsg({ content: 'Le mode séance est desactivé ', color: 'green' });
      setOpenSnack(true);
    } catch (error) {
      handleClose();
      console.log(error.message);
      setMsg({ content: 'une erreur est survenue', color: 'red' });
      setOpenSnack(true);
    }
    handleClose();
  };
  // google place auto
  const libraries = ['places'];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries,
  });
  const searchOptions = {
    componentRestrictions: { country: 'CIV' },
    fields: ['address_components', 'geometry', 'icon', 'name'],
    types: ['establishment'],
  };

  // place autocomplete
  const [address, setAddress] = useState('');

  const handleSelect = async (address, placeId, suggestion) => {
    console.log(suggestion.description);

    const tmp = suggestion.description.split(',');
    setLieu(tmp[0].trim());
    setVille(tmp[tmp.length - 2].trim());
    setPays(tmp[tmp.length - 1].trim());
    setAddress(suggestion.description);
    const results = await geocodeByAddress(address);
    const ll = await getLatLng(results[0]);
    setLocation2(suggestion.description);
    setLocation(ll);
  };

  // gestion date
  const addDebut = (e) => {
    const today = new Date();
    const tmp = new Date(e.target.value);
    const tmpF = new Date(fin);
    console.log(today);
    console.log(tmp);
    if (tmp <= today) {
      setDebutError(true);
      setDebutErrorMsg("La valeur de champ n'est pas valide");
    } else if (tmp >= tmpF) {
      setDebutErrorMsg("La date de debut doit être inferrieur à la date de fin de l'événément");
      setFinErrorMsg("La date de debut doit être inferrieur à la date de fin de l'événément");
      setDebutError(true);
      setFinError(true);
    } else {
      setFinError(false);
      setDebutError(false);
    }
    setDebut(e.target.value);
    // e.target.blur();
    endDateRef.current.focus();
  };

  const addFin = (e) => {
    const debutT = new Date(debut);
    const tmp = new Date(e);
    console.log(tmp);
    if (tmp <= debutT) {
      setFinError(true);
      setFinErrorMsg("La date de fin doit être supérieur à la date de debut de l'événément");
    } else {
      setFinError(false);
    }

    setFin(e);
  };
  const save = (data) => {
    console.log(data);
  };

  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const endDateTimeRef = React.useRef('');

  const handleStartDateTimeChange = (date) => {
    setStartDateTime(date);
    if (!endDateTime) {
      // Si endDateTime n'est pas encore défini, mettre automatiquement le focus sur le champ de date de fin
      // endDateTimeRef.current.focus();
    }
  };

  const handleEndDateTimeChange = (date) => {
    setEndDateTime(date);
  };
  const [typeLocation, setTypeLocation] = useState(1);

  const navigate = useNavigate();
  const endDateRef = useRef(null);

  const inputRef = useRef(null);

  // Fonction qui sélectionne le texte lors d'un clic ou focus
  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  return (
    <Page title="Dashboard">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      {events.map((event) => (
        <Container key={event.nomEvent} sx={{ marginTop: { xs: 3 } }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h2" gutterBottom>
              Infos event
              <br />
              <Typography variant="body2">Définissez les informations principales de votre événement</Typography>
            </Typography>
          </Stack>
          <Stack spacing={3}>
            <Box sx={{border: '2px solid gainsboro', borderRadius:'7px'}}>
              <Box sx={{ p: 3 }} dir="ltr" >
                <Grid paddingY={1} alignItems="center" container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography> Nom de l'événement</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {event.participant === 0 ? (
                      <TextField
                        value={nameField}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        fullWidth
                      />
                    ) : (
                      <TextField
                        disabled
                        value={nameField}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        fullWidth
                        helperText="Vous ne puvez plus modifier le nom car vous avez effectuer des ventes"
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid paddingY={1} alignItems="center" container spacing={3} >
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography>Type d'évenement</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                      {event.participant === 0 ? (
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          defaultValue={typeField}
                          value={typeField}
                          onChange={handleType}
                        >
                          <MenuItem value={'Concert'}>Concert</MenuItem>
                          <MenuItem value={'Festival'}>Festival</MenuItem>
                          <MenuItem value={'Atelier'}>Atelier</MenuItem>
                          <MenuItem value={'Compétition'}>Compétition</MenuItem>
                          <MenuItem value={'Conférence'}>Conférence</MenuItem>
                          <MenuItem value={'Cours'}>Cours</MenuItem>
                          <MenuItem value={'Défilé'}>Défilé</MenuItem>
                          <MenuItem value={'Dégustation'}>Dégustation</MenuItem>
                          <MenuItem value={'Exposition'}>Exposition</MenuItem>
                          <MenuItem value={'Formation'}>Formation</MenuItem>
                          <MenuItem value={'Forum'}>Forum</MenuItem>
                          <MenuItem value={'Randonnée'}>Randonnée</MenuItem>
                          <MenuItem value={'Soirée clubbing'}>Soirée clubbing</MenuItem>
                          <MenuItem value={'Spétacle'}>Spétacle</MenuItem>
                        </Select>
                      ) : (
                        <Select
                          disabled
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          defaultValue={typeField}
                          value={typeField}
                          onChange={handleType}
                          helperText="Vous ne puvez plus modifier le type car vous avez effectuer des ventes"
                        >
                          <MenuItem value={event.type}>{event.type}</MenuItem>
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{border: '2px solid gainsboro', borderRadius:'7px'}}>

              {event.seanceActive === 0 ? (
                <Box sx={{ p: 3 }} dir="ltr">
                  <Typography variant="h4">Date</Typography>
                  <Typography variant="body2">
                    Le fuseau horaire de l’évènement est automatiquement réglé en fonction de sa localisation.
                  </Typography>
                  {dateError &&
                    <Typography sx={{ color: 'red' }} variant="caption">
                      {dateErrorMsg}
                    </Typography>}
                  {/*
                  new version
                  */}
                  <Stack spacing={1} padding={3} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px', border: `${dateError ? '1px solid red' : null}` }}>
                    <Box>
                      <Typography>Début</Typography>
                    </Box>
                    <Stack spacing={1}>
                      <CustomDateTimePicker date={dateDebut} time={timeDebut} onDateChange={handleDateDebutChange} onTimeChange={handleTimeDebutChange} />
                    </Stack>
                    <Box paddingTop={2}>
                      <Typography>Fin</Typography>
                    </Box>
                    <Stack spacing={1}>
                      <CustomDateTimePicker date={dateFin} time={timeFin} minDate={dateDebut ? dateDebut : null} onDateChange={handleDateFinChange} onTimeChange={handleTimeFinChange} />
                    </Stack>
                  </Stack>
                </Box>
              ) : event.seanceActive === 1 ? (
                <Box sx={{ p: 3 }} dir="ltr">
                  <Typography variant="h4">Date</Typography>
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={10} md={10}>
                      <Stack justifyContent='center' alignItems='center' sx={{ marginTop: 2, border: '1px solid #f2f2f2', paddingY: 4, borderRadius: '7px' }}>
                        <Typography variant='body2'>
                          Le mode séances est actuellement activé. Vous pouvez gérer les seancés en cliquant sur <strong>gérer vos séances</strong>{' '}
                        </Typography>

                        <Button
                          onClick={() => {
                            navigate(`/${eventID}/dashboard/datelist`);
                          }}
                          variant="text"
                          sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            color: '#5E17EB',
                            backgroundColor: 'white',
                            borderRadius: '7px',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: '#5E17EB',
                              borderColor: 'white',
                            },
                          }}
                        >
                          gérer vos séances
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </Box>
            <Box sx={{border: '2px solid gainsboro', borderRadius:'7px'}}>
              <Box sx={{ p: 3 }} dir="ltr">
                <Typography variant="h4">Localisation</Typography>
                <Typography variant="body2">
                  Localisation de l'evenement, requise pour lister votre evenemnt dans la bonne ville
                </Typography>

                <Grid paddingY={3} alignItems="center" container spacing={3}>
                  <Grid item xs={12} sm={8} md={8}>
                    {isLoaded && (
                      <PlacesAutocomplete
                        value={locationField2}
                        onChange={setLocation2}
                        onSelect={handleSelect}
                        searchOptions={searchOptions}
                        googleCallbackName=""
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            {!lieuError ? (
                              <TextField
                                {...getInputProps({ placeholder: "Entrer le lieu de l'évenement" })}
                                fullWidth
                                inputRef={inputRef}
                                onFocus={handleFocus}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Iconify icon="iconoir:search" />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Iconify icon="typcn:delete" />
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            ) : (
                              <TextField
                                {...getInputProps({ placeholder: "Entrer le lieu de l'évenement" })}
                                error
                                helperText="Le lieu de l'évnement est importante. veuillez remplir ce champs"
                                fullWidth
                                inputRef={inputRef}
                                onFocus={handleFocus}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Iconify icon="iconoir:search" />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Iconify icon="iconoir:search" />
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                            <Card>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: 2 }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer', padding: 2 };
                                return (
                                  <Box
                                    key={suggestion.placeId}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <Typography>{suggestion.description}</Typography>
                                  </Box>
                                );
                              })}
                            </Card>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    )}
                  </Grid>
                </Grid>
                <Typography variant="body2">{locationField2}</Typography>
                <Box sx={{ height: 180, borderRadius: '7px' }}>
                  <Maps loading={isLoaded} centerC={locationField} height={180} />
                </Box>
              </Box>
            </Box>
            <Box sx={{border: '2px solid gainsboro', borderRadius:'7px'}}>
              <Box key="1" sx={{ p: 3 }} dir="ltr">
                <Typography variant="h4">Description</Typography>
                <Typography variant="body2">
                  Cette description apparaitra sur la page de l’évènement
                </Typography>
                <Grid paddingY={3} container>
                  <Grid item xs={12} md={10}>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      value={value}
                      onChange={handleChangeQuill}
                      placeholder="Saisissez une description pour votre evenement ici..."
                    />
                  </Grid>
                  {/* <div dangerouslySetInnerHTML={{ __html: value }}></div> */}
                </Grid>
              </Box>
            </Box>
            <Box sx={{border: '2px solid gainsboro', borderRadius:'7px'}}>
              <>
                {' '}
                <Box sx={{ p: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Visuel</Typography>
                      <Typography variant="body2">
                        L'image sera utilisée à travers l’app et le site web Choptonticket. Choisissez-en une qui corresponde bien à votre identité de marque.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      {imageUrl && (<Grid container sx={{
                        position: 'relative', // Important pour superposer le contenu sur l'image
                        bgcolor: 'rgba(245, 245, 245, 0.5)', // Couleur de fond avec opacité
                        direction: 'flex',
                        justifyContent: 'center',
                        borderRadius: { xs: '12px', sm: '0px' },
                        overflow: 'hidden', // Pour éviter que le flou dépasse les bordures
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundImage: `url(${imageUrl})`, // Remplace par l'URL de ton image
                          backgroundSize: 'cover', // Adapter l'image pour couvrir tout le container
                          backgroundPosition: 'center', // Centrer l'image
                          filter: 'blur(8px)', // Effet de flou
                          zIndex: -1, // Met l'image derrière le contenu
                        },
                      }}>
                        <Grid item xs={12} sm={9}>
                          <Paper elevation={3} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                            <img
                              src={imageUrl}
                              alt="Selected Image"
                              style={{
                                width: '100%',
                                minHeight: { xs: '200px', sm: '425px' },
                                maxHeight: { xs: '200px', sm: '425px' },
                                objectFit: 'cover',

                              }}
                            />

                          </Paper>
                        </Grid>
                      </Grid>)}
                      {!imageUrl && (

                        <Box p={2}>
                          <Button
                            fullWidth
                            disableElevation
                            size="medium"
                            sx={{
                              marginTop: 2,
                              textTransform: 'initial',
                              boxShadow: 'none',
                              border: '1px dashed gainsboro',
                              color: 'black',
                              backgroundColor: '#f2f2f2',
                              '&:hover': {
                                backgroundColor: '#f2f2f2',
                                color: '#5E17EB',
                                borderColor: '#5E17EB',
                              },
                            }}
                            variant="contained"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              id="select-image-button"
                              type="file"
                              onChange={(e) => setSelectedImage(e.target.files[0])}
                            />
                            <Stack
                              padding={2}
                              sx={{
                                justifyContent: 'space-around',
                                alignItems: 'center',

                              }}
                            >
                              {loading ? (
                                '... laoding'
                              ) : (
                                <>
                                  <Iconify icon="material-symbols:upload" sx={{ width: 30, height: 30, }} />
                                  <Typography variant='caption'>Cliquer ici pour charger votre fichier</Typography>
                                  <Typography variant='caption'>Seul les types de fichier .csv, .xsl sont acceptés</Typography>
                                </>
                              )}
                            </Stack>
                          </Button>
                        </Box>

                      )}
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', paddingY: 2 }}>
                        <Button
                          size="medium"
                          sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            Color: 'white',
                            backgroundColor: 'black',
                            borderRadius: '7px',
                            boxShadow: 'none',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: 'black',
                              borderColor: 'black',
                            },
                          }}
                          startIcon={<Iconify icon="material-symbols:download" />}
                          variant="contained"
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            id="select-image-download-button"
                            type="file"
                            onChange={(e) => setSelectedImage(e.target.files[0])}
                          />
                          Parcourir
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            </Box>
          </Stack>
          <Box sx={{ pt: 3 }} dir="ltr">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4}>
                <CustomButton
                  type='primaire'
                  buttonText='Mettre a jour l événement'
                  onClick={() => update()}
                />
              </Grid>
            </Grid>
          </Box>
          <Snackbar
            open={openSnack}
            autoHideDuration={5000}
            onClose={handleCloseSnack}
            message={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography paddingRight={1} variant='caption'>{msg.content}{' '}</Typography>
                <Iconify
                  sx={{ color: msg.color }}
                  icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
                />
              </Box>
            }
          />
        </Container>
      ))}
    </Page>
  );
}
