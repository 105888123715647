/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import React from 'react';

import { List } from 'antd';
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Iconify from '../Iconify';
import NumberTextField from '../NumberTextField';
import CustomButton from '../CustomButton';
import useResponsive from '../../hooks/useResponsive';

function AppPricing() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  // montant
  const [montant, setMontant] = React.useState([0]);
  const [nbBillet, setNbBillet] = React.useState([0]);
  const [commission, setCommission] = React.useState({ type: 1, c1: 6 });
  const [revenu, setRevenu] = React.useState(0);
  const [bCheck, setBcheck] = React.useState(false);
  const [nCheck, setNcheck] = React.useState(false);
  const [type, setType] = React.useState(1);
  const [choice, setChoice] = React.useState(1);

  const validateNumber = (input) => {
    // Expression régulière pour n'accepter que les chiffres
    const regex = /^[0-9]+$/;

    // Teste si l'entrée correspond à la regex
    return regex.test(input);
  };
  const handledMontant = (newValue, index) => {
    console.log(newValue);
    if (validateNumber(newValue)) {
      console.log(newValue);
      const values = [...montant]; // Copie du tableau de nouvelles valeurs
      values[index] = newValue;
      console.log(values);
      setMontant(values);
    }
    console.log(newValue);
    // handleRevenue();
  };
  const handledNb = (newValue, index) => {
    console.log(newValue);
    if (validateNumber(newValue)) {
      console.log(newValue);
      const values = [...nbBillet]; // Copie du tableau de nouvelles valeurs
      values[index] = newValue;
      console.log(values);
      setNbBillet(values);
    }
    console.log(newValue);
    // handleRevenue();
  };

  const addBillet = (value) => {
    console.log('ok');
    setNbBillet((nbBillet) => [...nbBillet, value]);
    setMontant((montant) => [...montant, value]);
  };

  const handleMontant = (event) => {
    setMontant(event.target.value);
    handleRevenue();
  };

  const handleRevenue = (event) => {
    if (event.target.id === 'prixVente') {
      setMontant(event.target.value);
    } else if (event.target.id === 'nbBillet') {
      setNbBillet(event.target.value);
    }
    if (montant !== 0) {
      console.log('montant: ', event.target.value);
      console.log('nb billet: ', event.target.value);
      const tmp = montant * 0.05;
      const tmp1 = montant - tmp;
      setRevenu(tmp1 * nbBillet);
    }
  };

  const estimationGain = () => {
    console.log('gain estimation');
    if (montant < 10000) {
      const tmp = (montant * 6) / 100 + 20;
      const tmp1 = montant - tmp;
      setRevenu(tmp1 * nbBillet);
    } else if (montant >= 10000) {
      const tmp = (montant * 5) / 100 + 20;
      const tmp1 = montant - tmp;
      setRevenu(tmp1 * nbBillet);
    }
  };
  const [disablePointerEvents, setDisablePointerEvents] = React.useState(false);

  const handlePassLiberte = () => {
    setType(1);
    setCommission({ type: 1, c1: 6 });
    setNcheck(false);
    setBcheck(false);
  };

  const handlePassExclu = () => {
    setType(2);
    setCommission({ type: 2, c1: 9 });
    setNcheck(false);
    setBcheck(false);
  };

  React.useEffect(() => {
    (async () => {
      try {
        console.log('ok');
        let total = 0;
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < montant.length; index++) {
          // calcul de la comission
          let valeurComission = 0;
          if (type === 1) {
            valeurComission = parseInt(montant[index]) * (commission.c1 / 100);
          } else if (type === 2) {
            valeurComission = parseInt(montant[index]) * (commission.c1 / 100);
          }
          // calcul du revenu
          total += (parseInt(montant[index]) - valeurComission) * parseInt(nbBillet[index]);
        }
        console.warn(total);
        setRevenu(total);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [montant, nbBillet, type, nCheck, bCheck]);

  const suppBillet = (index) => {
    setMontant((existItem) => {
      return [...existItem.slice(0, index), ...existItem.slice(index + 1)];
    });
    setNbBillet((existItem) => {
      return [...existItem.slice(0, index), ...existItem.slice(index + 1)];
    });
  };

  return (
    <div id="pricing" className="block pricingBlock ">
      <div className="container-fluid">
        <div>
          <Stack paddingTop={5} paddingBottom={8}>
            <Typography textAlign="center" variant="subtitle1">
              Tarif & Commission
            </Typography>
            <Typography textAlign="center" variant="h2">
              DES OFFRES ADAPTEES A CHACUN DE VOUS.
            </Typography>
          </Stack>

        </div>


        {/* New design-------------------------------------------------------------- */}
{
  smUp ?
  <Box paddingTop={5}>
          <Grid container columnSpacing={1}>
            <Grid item xs={4} display='flex' justifyContent='right' >
              <Box sx={{ border: '2px solid gainsboro', width: '100%', borderRadius: '7px' }}>
                <Box sx={{ padding: 2, bgcolor: '#141414', display: 'flex', justifyContent: 'center', borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}>
                  <Typography sx={{ color: 'white', }} variant='h3'>Pass Essentiel</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='subtitle1'>Tout ce qu'il faut savoir pour commencer à vendre des billets en quelques minutes.</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography align='center' variant='h5'>6% de commission sur chaque ticket vendu</Typography>
                </Box>
                <Stack direction='column' padding={2} spacing={1.5}>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>2 type de billet (ex : « Grand public »)</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Fonctionnalités essentielles de billetterie et d'inscription</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Listing sur Choptonticket et les sites partenaires</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Les prix des billets incluent le traitement des paiements</Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4} display='flex' justifyContent='center'>
              <Box sx={{ border: '2px solid gainsboro', width: '100%', borderRadius: '7px' }}>
                <Box sx={{ padding: 2, bgcolor: '#5E17EB', display: 'flex', justifyContent: 'center', borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}>
                  <Typography sx={{ color: 'white', }} variant='h3'>Pass Pro</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='subtitle1'>Tout ce qu'il faut savoir pour commencer à vendre des billets en quelques minutes.</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='h6'>9% de commission sur chaque ticket vendu</Typography>
                </Box>
                <Stack direction='column' padding={2} spacing={1.5}>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'><strong>Tout ce que propose Essentials, plus :</strong></Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Types de billets illimités</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Vendez des billets sur votre propre site</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Formulaires de règlement personnalisables</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Analyses détaillées des ventes</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Accès et autorisations d'équipe</Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4} display='flex' justifyContent='left'>
              <Box sx={{ border: '2px solid gainsboro', width: '100%', borderRadius: '7px' }}>
                <Box sx={{ padding: 2, bgcolor: '#141414', display: 'flex', justifyContent: 'center', borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}>
                  <Typography sx={{ color: 'white', }} variant='h3'>Patners</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='subtitle1'>Des forfaits personnalisés pour les pros de l'événementiel avec des événements de grande taille ou complexes .</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='h6'>Commission personnalisé</Typography>
                </Box>
                <Stack direction='column' padding={2} spacing={1.5}>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'><strong>Tout ce que propose le Pass Pro, plus :</strong></Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Une consultation avec notre équipe en charge des événements pour optimiser les événements et la stratégie tarifaire de votre entreprise.</Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
  : 
  <Stack spacing={2}>
          <Stack
            direction="column"
            sx={{ backgroundColor: '#5E17EB', border: '2px solid transparent', borderRadius: '10px' }}
            padding={0.5}
            spacing={1}
          >
            <Button
              size="large"
              fullWidth
              sx={
                choice === 0
                  ? {
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    backgroundColor: 'white',
                    color: '#141414',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#141414',
                      borderColor: '#141414',
                    },
                  }
                  : {
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: 'transparent',
                    borderRadius: '7px',
                  }
              }
              onClick={() => {
                setChoice(0);
              }}
              variant="contained"
            >
              Pass Essentiel
            </Button>
            <Button
              size="large"
              fullWidth
              sx={
                choice === 1
                  ? {
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    backgroundColor: 'white',
                    color: '#141414',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#141414',
                      borderColor: '#141414',
                    },
                  }
                  : {
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: 'transparent',
                    borderRadius: '7px',
                  }
              }
              onClick={() => {
                setChoice(1);
              }}
              variant="contained"
            >
              Pass Pro
            </Button>
            <Button
              size="large"
              fullWidth
              sx={
                choice === 2
                  ? {
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    backgroundColor: 'white',
                    color: '#141414',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#141414',
                      borderColor: '#141414',
                    },
                  }
                  : {
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: 'transparent',
                    borderRadius: '7px',
                  }
              }
              onClick={() => {
                setChoice(2);
              }}
              variant="contained"
            >
              Partners
            </Button>
          </Stack>
          <Grid container>
            {
              choice === 0 ?
                <Grid item xs={12} display='flex' justifyContent='right' >
                  <Box sx={{ border: '2px solid gainsboro', width: '100%', borderRadius: '7px' }}>
                    <Box sx={{ padding: 2, bgcolor: '#141414', display: 'flex', justifyContent: 'center', borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}>
                      <Typography sx={{ color: 'white', }} variant='h3'>Pass Essentiel</Typography>
                    </Box>
                    <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant='subtitle1'>Tout ce qu'il faut savoir pour commencer à vendre des billets en quelques minutes.</Typography>
                    </Box>
                    <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography align='center' variant='h5'>6% de commission sur chaque ticket vendu</Typography>
                    </Box>
                    <Stack direction='column' padding={2} spacing={1.5}>
                      <Stack direction='row' spacing={2}>
                        <Box>
                          <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                        </Box>
                        <Box>
                          <Typography variant='body1'>2 type de billet (ex : « Grand public »)</Typography>
                        </Box>
                      </Stack>
                      <Stack direction='row' spacing={2}>
                        <Box>
                          <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                        </Box>
                        <Box>
                          <Typography variant='body1'>Fonctionnalités essentielles de billetterie et d'inscription</Typography>
                        </Box>
                      </Stack>
                      <Stack direction='row' spacing={2}>
                        <Box>
                          <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                        </Box>
                        <Box>
                          <Typography variant='body1'>Listing sur Choptonticket et les sites partenaires</Typography>
                        </Box>
                      </Stack>
                      <Stack direction='row' spacing={2}>
                        <Box>
                          <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                        </Box>
                        <Box>
                          <Typography variant='body1'>Les prix des billets incluent le traitement des paiements</Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
                : choice === 1 ?
                  <Grid item xs={12} display='flex' justifyContent='center'>
                    <Box sx={{ border: '2px solid gainsboro', width: '100%', borderRadius: '7px' }}>
                      <Box sx={{ padding: 2, bgcolor: '#5E17EB', display: 'flex', justifyContent: 'center', borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}>
                        <Typography sx={{ color: 'white', }} variant='h3'>Pass Pro</Typography>
                      </Box>
                      <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant='subtitle1'>Tout ce qu'il faut savoir pour commencer à vendre des billets en quelques minutes.</Typography>
                      </Box>
                      <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant='h6'>9% de commission sur chaque ticket vendu</Typography>
                      </Box>
                      <Stack direction='column' padding={2} spacing={1.5}>
                        <Stack direction='row' spacing={2}>
                          <Box>
                            <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                          </Box>
                          <Box>
                            <Typography variant='body1'><strong>Tout ce que propose Essentials, plus :</strong></Typography>
                          </Box>
                        </Stack>
                        <Stack direction='row' spacing={2}>
                          <Box>
                            <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                          </Box>
                          <Box>
                            <Typography variant='body1'>Types de billets illimités</Typography>
                          </Box>
                        </Stack>
                        <Stack direction='row' spacing={2}>
                          <Box>
                            <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                          </Box>
                          <Box>
                            <Typography variant='body1'>Vendez des billets sur votre propre site</Typography>
                          </Box>
                        </Stack>
                        <Stack direction='row' spacing={2}>
                          <Box>
                            <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                          </Box>
                          <Box>
                            <Typography variant='body1'>Formulaires de règlement personnalisables</Typography>
                          </Box>
                        </Stack>
                        <Stack direction='row' spacing={2}>
                          <Box>
                            <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                          </Box>
                          <Box>
                            <Typography variant='body1'>Analyses détaillées des ventes</Typography>
                          </Box>
                        </Stack>
                        <Stack direction='row' spacing={2}>
                          <Box>
                            <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                          </Box>
                          <Box>
                            <Typography variant='body1'>Accès et autorisations d'équipe</Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                  : choice === 2 ?
                    <Grid item xs={12} display='flex' justifyContent='left'>
                      <Box sx={{ border: '2px solid gainsboro', width: '100%', borderRadius: '7px' }}>
                        <Box sx={{ padding: 2, bgcolor: '#141414', display: 'flex', justifyContent: 'center', borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}>
                          <Typography sx={{ color: 'white', }} variant='h3'>Patners</Typography>
                        </Box>
                        <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Typography variant='subtitle1'>Des forfaits personnalisés pour les pros de l'événementiel avec des événements de grande taille ou complexes .</Typography>
                        </Box>
                        <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Typography variant='h6'>Commission personnalisé</Typography>
                        </Box>
                        <Stack direction='column' padding={2} spacing={1.5}>
                          <Stack direction='row' spacing={2}>
                            <Box>
                              <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                            </Box>
                            <Box>
                              <Typography variant='body1'><strong>Tout ce que propose le Pass Pro, plus :</strong></Typography>
                            </Box>
                          </Stack>
                          <Stack direction='row' spacing={2}>
                            <Box>
                              <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                            </Box>
                            <Box>
                              <Typography variant='body1'>Une consultation avec notre équipe en charge des événements pour optimiser les événements et la stratégie tarifaire de votre entreprise.</Typography>
                            </Box>
                          </Stack>
                        </Stack>
                      </Box>
                    </Grid>
                    : null
            }



          </Grid>
        </Stack>

}
        




        
        <Box marginY={10} padding={2} sx={{ border: '2px solid gainsboro', borderRadius: '7px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant='h3'>Calculez vos gains potentiels</Typography>
              <Stack spacing={4} paddingTop={4}>
                <Stack
                  direction="row"
                  sx={{ backgroundColor: '#5E17EB', border: '2px solid transparent', borderRadius: '10px' }}
                  padding={0.5}
                  spacing={1}
                >
                  <Button
                    size="large"
                    fullWidth
                    sx={
                      type === 1
                        ? {
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          backgroundColor: 'white',
                          color: '#141414',
                          borderRadius: '7px',
                          '&:hover': {
                            backgroundColor: 'white',
                            color: '#141414',
                            borderColor: '#141414',
                          },
                        }
                        : {
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          color: 'white',
                          backgroundColor: 'transparent',
                          borderRadius: '7px',
                        }
                    }
                    onClick={() => {
                      handlePassLiberte();
                    }}
                    variant="contained"
                  >
                    Pass Essentiel
                  </Button>
                  <Button
                    size="large"
                    fullWidth
                    sx={
                      type === 2
                        ? {
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          backgroundColor: 'white',
                          color: '#141414',
                          borderRadius: '7px',
                          '&:hover': {
                            backgroundColor: 'white',
                            color: '#141414',
                            borderColor: '#141414',
                          },
                        }
                        : {
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          color: 'white',
                          backgroundColor: 'transparent',
                          borderRadius: '7px',
                        }
                    }
                    onClick={() => {
                      handlePassExclu();
                    }}
                    variant="contained"
                  >
                    Pass Pro
                  </Button>
                </Stack>
                {/* new */}
                {nbBillet.map((element, index) => (
                  <Stack key={index} spacing={1}>
                    {nbBillet.length > 1 && <Stack direction='row' justifyContent="space-between">
                      <Typography><stong>Billet {index + 1}</stong></Typography>
                      <IconButton sx={{ padding: 0 }} onClick={(e) => suppBillet(index)}>
                        <Iconify icon="typcn:delete" />
                      </IconButton>
                    </Stack>}
                    <Stack direction='row' alignItems='center' spacing={1} divider={<Iconify icon='akar-icons:cross' sx={{ width: 30, height: 30, }} />}>
                      <TextField
                        size="medium"
                        variant="outlined"
                        label="Prix du billet"
                        id="prixVente"
                        type="text"
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        fullWidth
                        onChange={(e) => handledMontant(e.target.value, index)}
                        value={montant[index]}
                      />
                      <TextField
                        size="medium"
                        variant="outlined"
                        label="Billets vendu"
                        id="prixVente"
                        type="text"
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        fullWidth
                        onChange={(e) => handledNb(e.target.value, index)}
                        value={element}
                      />
                    </Stack>
                  </Stack>
                ))}
                {/** new */}
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CustomButton buttonText='Ajouter un autre billet' type='primaire' onClick={() => addBillet(0)} />
                </Box>
              </Stack>

            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={5} justifyContent='center' display='flex' flexDirection='column'>
                <Typography align='center' variant='h3'>Estimation de votre paiement</Typography>
                <Typography align='center' variant='h2' sx={{ color: '#5E17EB' }}>{revenu === 0 ? 'Choptonticket est gratuit pour les evenements gratuits !' : `${revenu} CFA`}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CustomButton type='secondaire' buttonText='Créer un événement' />
                </Box>

              </Stack>

            </Grid>
          </Grid>

        </Box>
      </div>
    </div>
  );
}

export default AppPricing;
