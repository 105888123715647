import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Link, Typography } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import Searchbar from '../dashboard/Searchbar';
import AccountPopover from '../dashboard/AccountPopover';
import LanguagePopover from '../dashboard/LanguagePopover';
import NotificationsPopover from '../dashboard/NotificationsPopover';
import Logo from '../../components/Logo';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: 'white',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100%)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

HomeNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function HomeNavbar({ onOpenSidebar }) {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  return (
    <RootStyle>
      <ToolbarStyle>
        <Logo />
       
        {mdUp ? (
          <>
            <Box sx={{ flexGrow: 0.25 }} />
            <Stack direction="row" textAlign="center" spacing={{ xs: 0.5, sm: 3 }}>
              <Link
                href="/bo/"
                underline="hover"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  '&:hover': {
                    color: '#5E17EB',
                  },
                }}
              >
                <Stack direction="row" textAlign="center" spacing={0.5}>
                  <Iconify icon="mdi-light:calendar" sx={{ minHeight: 22, minWidth: 22 }} />
                  <Typography noWrap>Mes événements</Typography>
                </Stack>
              </Link>
              <Link
                href="/organisation/app"
                underline="hover"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  '&:hover': {
                    color: '#5E17EB',
                  },
                }}
              >
                <Stack direction="row" textAlign="center" spacing={0.5}>
                  <Iconify icon="uil:setting" sx={{ height: 22, width: 22 }} />
                  <Typography noWrap>Organisation</Typography>
                </Stack>
              </Link>
              <Link
                href="/finance"
                underline="hover"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  '&:hover': {
                    color: '#5E17EB',
                  },
                }}
              >
                <Stack direction="row" textAlign="center" spacing={0.5}>
                  <Iconify icon="carbon:finance" sx={{ height: 21, width: 21 }} />
                  <Typography noWrap>Finance</Typography>
                </Stack>
              </Link>

              <Link
                href="/marketing"
                underline="hover"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  '&:hover': {
                    color: '#5E17EB',
                  },
                }}
              >
                <Stack direction="row" textAlign="center" spacing={0.5}>
                  <Iconify icon="nimbus:marketing" sx={{ height: 22, width: 22 }} />
                  <Typography>Marketing</Typography>
                </Stack>
              </Link>
              <Link
                href="/marketing"
                underline="hover"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  '&:hover': {
                    color: '#5E17EB',
                  },
                }}
              >
                <Stack direction="row" textAlign="center" spacing={0.5}>
                  <Iconify icon="ion:analytics-outline" sx={{ height: 22, width: 22 }} />
                  <Typography>Analyse</Typography>
                </Stack>
              </Link>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>

              <Button
                sx={{
                  color: '#5E17EB',
                  backgroundColor: 'white',
                  border: '1.5px solid #5E17EB',
                  borderRadius: '7px',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#5E17EB',
                    border: '1.5px solid #5E17EB',
                  },
                }}
                variant="outlined"
              >
                Nous contacter
              </Button>
              <AccountPopover />
            </Stack>
          </>
        ) : <>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <Button
            sx={{
              color: '#5E17EB',
              backgroundColor: 'white',
              border: '1.5px solid #5E17EB',
              borderRadius: '7px',
              '&:hover': {
                backgroundColor: 'white',
                color: '#5E17EB',
                border: '1.5px solid #5E17EB',
              },
            }}
            variant="outlined"
          >
            Nous contacter
          </Button>
          <AccountPopover />
        </Stack>
      </>}
      </ToolbarStyle>
    </RootStyle>
  );
}
