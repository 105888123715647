/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Grid,
    CardActions,
    CardContent,
    Box,
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Chip,
    OutlinedInput,
    MenuItem,
    useTheme,
    Tooltip,
    IconButton,
    Backdrop,
    CircularProgress,
    Menu,
    Link,
    Skeleton,
} from '@mui/material';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query, where } from 'firebase/firestore';

import fr from 'date-fns/locale/fr';
import { format } from 'date-fns';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';

// mock
import USERLIST from '../../../_mock/user';

import { db } from '../../../firebase.config';
import CheckCard from '../../../components/CheckCard';
import { UserAuth } from '../../../context/AuthContext';
import { shortNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}
const options = ['Editer la campagne', 'Supprimer la campagne'];

// for modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function Dashboard() {
    const { eventID } = useParams();
    const { user } = UserAuth();
    const theme = useTheme();





    // for change value of globla quota
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [refreh, setRefresh] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        // setModif(false);
        setRefresh((prev) => !prev);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    // take operateurs from firebase
    const [contacts, setContact] = React.useState([]);
    const [event, setEvent] = React.useState();

    const getData = async () => {
        try {
            setLoading(true);
            setEvent();

            if (user) {
                const docRef0 = doc(db, 'organisateurs', user.uid);
                const docSnap = await getDoc(docRef0);

                if (docSnap.exists()) {
                    const orgId = docSnap.data().idOrg;
                    if (!orgId) {
                        throw new Error("orgId not found");
                    }
                    const q = query(
                        collection(db, 'evenement'),
                        where('orgId', '==', orgId),
                        orderBy('dateDebut', 'desc'),
                        limit(1)
                    );
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach(async (snap) => {
                        const event = snap;
                        setEvent(event);

                    });
                }
            }
        } catch (error) {
            console.log("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        (async () => {
            getData();
        })();
    }, [user]);

    // loading
    const [loading, setLoading] = React.useState(false);

    // GESTION DE LA MODIFICATION
    const [temp, setTemp] = React.useState([]);
    const [name, setName] = React.useState();
    const [id, setId] = React.useState();
    const [pass, setPass] = React.useState();

    // *****ticket
    const [ticketVerif, setTicket] = React.useState([]);
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
        };
    }

    const [personName, setPersonName] = React.useState([]);
    const handleChangeTicket = (event) => {
        const {
            target: { value },
        } = event;
        setTicket(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
        console.log(ticketVerif);
    };

    const [modif, setModif] = React.useState(false);
    const handleModif = (index) => {
        if (modif) {
            setModif(false);
        } else {
            const temp = contacts[index].data();
            console.log(index);
            console.log(temp.tickets);
            setName(temp.nameOp);
            setId(temp.idOp);
            setPass(temp.passOp);
            setTicket(temp.tickets);
            setModif(true);
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleTimestamp = (e) => {
        console.log('handle time stamp')
        const date = e.toDate();
        return date;
    };

    const today = new Date();
    const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes());

    


    return (
        <Page title="Campagne">
            
            <Box>
            
                <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container sx={{ marginTop: { xs: 3 }, }}>

                    <Typography variant="h2" gutterBottom>
                        Marketing
                    </Typography>
                    <Grid container spacing={2} paddingY={2}>
                        <Grid item xs={12} sm={4} md={3}>
                            <Card sx={{
                                padding: 2, height: '100%', width: '100%', color: (theme) => theme.palette.primary.darker,
                                border: '2px solid #F5EFFA'
                            }}>
                                <Stack spacing={2}>
                                    <Typography variant='body2'><strong>Contacts</strong></Typography>
                                    {
                                        loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h5'>{shortNumber(10)} CFA</Typography>
                                    }
                                </Stack>
                                
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <Card sx={{
                                padding: 2, height: '100%', width: '100%', color: (theme) => theme.palette.primary.darker,
                                bgcolor: (theme) => theme.palette.primary.lighter,
                            }}>
                                <Stack spacing={2}>
                                    <Typography variant='body2'><strong>Campagnes</strong></Typography>
                                    {
                                        loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h5'>{shortNumber(10)} CFA</Typography>
                                    }
                                </Stack>
                                
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <Card sx={{
                                padding: 2, height: '100%', width: '100%', color: (theme) => theme.palette.primary.darker,
                                bgcolor: (theme) => theme.palette.primary.lighter,
                            }}>
                                <Stack spacing={2}>
                                    <Typography variant='body2'><strong>Populations créées</strong></Typography>
                                    {
                                        loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h5'>{shortNumber(10)} CFA</Typography>
                                    }
                                </Stack>
                                
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <Card sx={{
                                padding: 2, height: '100%', width: '100%', color: (theme) => theme.palette.primary.darker,
                                bgcolor: (theme) => theme.palette.primary.lighter,
                            }}>
                                <Stack spacing={2}>
                                    <Typography variant='body2'><strong>Revenues nettes</strong></Typography>
                                    {
                                        loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h5'>{shortNumber(10)} CFA</Typography>
                                    }
                                </Stack>
                                
                            </Card>
                        </Grid>
                    </Grid>
                    {
                        event && <Box padding={3} sx={{ bgcolor: '#F5EFFA', borderRadius: '10px' }}>
                            <Typography variant='h4'>✨ Prochain event dans 3 jours !</Typography>

                            <Card sx={{ bgcolor: '#F5EFFA', borderRadius: '10px', border: '2px solid white', marginY: 1, }}>
                                <Grid container paddingY={2} paddingX={2} sx={{
                                    display: 'flex', alignItems: 'center', cursor: 'pointer', '&:hover': {
                                        // border: '1px solid #5E17EB',
                                        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                                    },
                                }} >
                                    <Grid item xs={12} sm={7}>
                                        <Stack direction='row' spacing={1} alignItems='center'>
                                            <Stack justifyContent='center' alignItems='center' sx={{ color: '#5E17EB' }}>
                                                <Typography variant='body2'><strong>{format(new Date(handleTimestamp(event?.data().dateDebut)), 'dd')}</strong></Typography>
                                                <Typography sx={{ textTransform: 'uppercase' }} variant='body2'>{format(new Date(handleTimestamp(event?.data().dateDebut)), 'MMM', { locale: fr })}</Typography>
                                            </Stack>
                                            <LazyLoadImage
                                                key={event?.id}
                                                src={`${event?.data().idVisuel}`}
                                                alt={`${event?.data().idVisuel}`}
                                                className="img-lazy"
                                                width={75}
                                                height={75}
                                                style={{ objectFit: 'cover' }}
                                                effect="blur" // opacity | black-and-white
                                            />
                                            <Stack>
                                                <Typography variant='body2'><strong>{event?.data().nomEvent}</strong></Typography>
                                                <Typography variant='caption'>{event.data().lieu_event}</Typography>
                                                <Stack direction='row' spacing={0.5} alignItems='center'>
                                                    <Typography variant='caption'>{format(new Date(handleTimestamp(event?.data().dateDebut)), 'EEE dd, MMMM yyyy', { locale: fr })} à {format(new Date(handleTimestamp(event?.data().dateDebut)), 'HH:mm', { locale: fr })}</Typography>

                                                    {
                                                        new Date(handleTimestamp(event?.data().dateFin)) < today1 ?
                                                            <Box
                                                                component="button"
                                                                padding={0.5}
                                                                color='#7E1F86'
                                                                sx={{
                                                                    borderRadius: '35px',
                                                                    fontSize: 10,
                                                                    backgroundColor: '#F5EFFA',
                                                                    border: '0.5px solid #B991DE',
                                                                }}
                                                            >
                                                                Passé
                                                            </Box>

                                                            : new Date(handleTimestamp(event?.data().dateFin)) === today1 ?
                                                                <Box
                                                                    component="button"
                                                                    padding={0.5}
                                                                    color='#7E1F86'
                                                                    sx={{
                                                                        borderRadius: '35px',
                                                                        fontSize: 10,
                                                                        backgroundColor: '#F5EFFA',
                                                                        border: '0.5px solid #B991DE',
                                                                    }}
                                                                >
                                                                    en cours
                                                                </Box>
                                                                : <Box
                                                                    component="button"
                                                                    padding={0.5}
                                                                    color='#7E1F86'
                                                                    sx={{
                                                                        borderRadius: '35px',
                                                                        fontSize: 10,
                                                                        backgroundColor: '#F5EFFA',
                                                                        border: '0.5px solid #B991DE',
                                                                    }}
                                                                >
                                                                    A venir
                                                                </Box>
                                                    }
                                                </Stack>
                                                <Stack direction='row' spacing={1}>
                                                    {
                                                        event?.data().seanceActive === 1 ?
                                                            <Box
                                                                padding={0.25}
                                                                width={90}
                                                                sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                                                            >
                                                                Plusieurs dates
                                                            </Box>
                                                            : null
                                                    }
                                                    {
                                                        event?.data().visibily === 0 ?
                                                            <Box
                                                                padding={0.25}
                                                                width={90}
                                                                sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                                                            >
                                                                <Stack direction='row' spacing={1} alignItems='center'>
                                                                    <Iconify icon='ic:outline-public' sx={{ height: 15, width: 15 }} />
                                                                    <Typography variant='caption'>Public</Typography>
                                                                </Stack>
                                                            </Box>
                                                            : event?.data().visibily === 1 ?
                                                                <Box
                                                                    padding={0.25}
                                                                    width={90}
                                                                    sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                                                                >
                                                                    <Stack direction='row' spacing={1} alignItems='center'>
                                                                        <Iconify icon='mdi:lock' sx={{ height: 15, width: 15 }} />
                                                                        <Typography variant='caption'>Privé</Typography>
                                                                    </Stack>
                                                                </Box>
                                                                :
                                                                <Box
                                                                    padding={0.25}
                                                                    width={90}
                                                                    sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                                                                >
                                                                    <Stack direction='row' spacing={1} alignItems='center'>
                                                                        <Iconify icon='ic:outline-public' sx={{ height: 15, width: 15 }} />
                                                                        <Typography variant='caption'>Public</Typography>
                                                                    </Stack>
                                                                </Box>

                                                    }

                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Grid>

                                </Grid>
                            </Card>
                        </Box>
                    }

                    <Grid container spacing={2} paddingTop={4}>
                        <Grid item xs={12}>
                            <Card sx={{ padding: 3, border: '1px solid gainsboro', borderRadius: '10px', }}>
                                <Stack spacing={5}>
                                    <Box>
                                        <Typography variant='h5'>Outils pour la promotion de votre evenement </Typography>
                                        <Typography variant='body2'>Utilisez ces outils lorsque vous faites la promotion de vos événements afin de vendre plus de billets</Typography>
                                    </Box>

                                    <Stack spacing={2}>

                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <Iconify icon="octicon:dot-24" sx={{ width: 20, heigth: 20 }} />
                                            <Link
                                                href="/marketing"
                                                underline="hover"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'black',
                                                    '&:hover': {
                                                        color: '#5E17EB',
                                                    },
                                                }}
                                            >
                                                <Stack>
                                                    <Typography variant='body2'>
                                                        <strong>Partagez sur tiktok</strong>
                                                    </Typography>
                                                    <Typography variant='body2'>
                                                        Utiliser Choptonticket pour publier votre event sur Tiktok en quelques clics
                                                    </Typography>
                                                </Stack>
                                            </Link>


                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <Iconify icon="octicon:dot-24" sx={{ width: 20, heigth: 20 }} />
                                            <Link
                                                href="/marketing"
                                                underline="hover"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'black',
                                                    '&:hover': {
                                                        color: '#5E17EB',
                                                    },
                                                }}
                                            >
                                                <Stack>
                                                    <Typography variant='body2'>
                                                        <strong>Event Facebook</strong>
                                                    </Typography>
                                                    <Typography variant='body2'>
                                                        Ajoutez votre event à facebook directement depuis Choptonticket
                                                    </Typography>
                                                </Stack>
                                            </Link>


                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <Iconify icon="octicon:dot-24" sx={{ width: 20, heigth: 20 }} />
                                            <Link
                                                href="/marketing"
                                                underline="hover"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'black',
                                                    '&:hover': {
                                                        color: '#5E17EB',
                                                    },
                                                }}
                                            >
                                                <Stack>
                                                    <Typography variant='body2'>
                                                        <strong>Campagne e-mail</strong>
                                                    </Typography>
                                                    <Typography variant='body2'>
                                                        Envoyez un e-mail personnalisé à vos abonnés à propos de votre event
                                                    </Typography>
                                                </Stack>
                                            </Link>


                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <Iconify icon="octicon:dot-24" sx={{ width: 20, heigth: 20 }} />
                                            <Link
                                                href="/marketing"
                                                underline="hover"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'black',
                                                    '&:hover': {
                                                        color: '#5E17EB',
                                                    },
                                                }}
                                            >
                                                <Stack>
                                                    <Typography variant='body2'>
                                                        <strong>Lancez des pub payante sur les reseaux</strong>
                                                    </Typography>
                                                    <Typography variant='body2'>
                                                        Faire connaitre votre event sur facebook depuis Choptonticket en quelques clics
                                                    </Typography>
                                                </Stack>
                                            </Link>


                                        </Stack>

                                    </Stack>
                                </Stack>
                            </Card>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

        </Page>
    );
}
